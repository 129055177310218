import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditRosterPermissionsPageRoutingModule } from './edit-roster-permissions-routing.module';

import { EditRosterPermissionsPage } from './edit-roster-permissions.page';
import { BhContentSearchComponentModule } from 'src/app/components/_core/bh-content-search/bh-content-search.component.module';
import { BhGridComponentModule } from 'src/app/components/_core/bh-grid/bh-grid.component.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { BhUserBadgeComponentModule } from 'src/app/components/_core/bh-user-badge/bh-user-badge.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    EditRosterPermissionsPageRoutingModule,
    BhContentSearchComponentModule,
    BhGridComponentModule,
    BhInputComponentModule,
    BhUserBadgeComponentModule,
    PipesModule
  ],
  declarations: [EditRosterPermissionsPage]
})
export class EditRosterPermissionsPageModule {}
