import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { Report, ReportCriteria } from 'src/app/models/report';
import { CallOut } from 'src/app/models/callout';

@Injectable({
  providedIn: 'root'
})
export class CallOutService {
  env = environment;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  getCallOutsAll(rosSeq, startDate, endDate) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/callout?startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "reports-service.getReportsAll(): " + url
        );
        return of(err);
      })
    );
  }

  createCallOut(callOut: CallOut) {
    const url = `${environment.apiUrl}/rosters/${callOut.rosSeq}/callout`;
    const body = callOut;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "reports-service.createReport(): " + url
        );
        return of(err);
      })
    );
  }

  updateCallOut(callOut: CallOut) {
    console.log('call out', callOut);
    
    const url = `${environment.apiUrl}/rosters/${callOut.rosSeq}/callout/${callOut.calSeq}`;
    const body = callOut;
    return this.http.put(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "call-out-service.updateCallOut(): " + url
        );
        return of(err);
      })
    );
  }


  prepareUserCriteria(report: Report, body: ReportCriteria) {
    const userParms = report.reportParms.filter(p => p.field === 'SRU_USR_ID');
    for (const usr of userParms) {
      if (usr.active) {
        body.criteria.users.push(usr.value);
      }
    }
  }




}
