import { RoleAssignment } from './../../models/role-assignment';
import { Component, Input, OnInit } from '@angular/core';
import { Employee } from 'src/app/models/employee';
import { Role } from 'src/app/models/role';
import { RoleGroup } from 'src/app/models/role-group';
import { SchRoleAssignmentUser } from 'src/app/models/sched-role-assign-user';
import { Shift } from 'src/app/models/shift';
import { UsersService } from 'src/app/services/users/users.service';
import * as moment from "moment";

@Component({
  selector: 'app-popover-view-employee',
  templateUrl: './popover-view-employee.page.html',
  styleUrls: ['./popover-view-employee.page.scss'],
})
export class PopoverViewEmployeePage implements OnInit {
  @Input() rolSeq: number;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  @Input() selectedShift: Shift;
  @Input() user: SchRoleAssignmentUser;
  @Input() roleGroup: RoleGroup;
  @Input() assignment: RoleAssignment;
  @Input() role: Role;
  employee: Employee;
  assignmentDetail: string;
  currentView: 'loading' | 'form' = 'loading';
  userBadgeSize = "medium"
  constructor(
    private userService: UsersService,
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    console.log('UserAssignment', this.user);
    this.loadUserAssignment();
  }

  async loadUserAssignment() {
    await this.getEmployeeInfo(this.user);
    const startTime = moment(this.user.startDateTime, 'MM/DD/YYYY h:mm a').format('h:mm a');
    const endTime = moment(this.user.endDateTime, 'MM/DD/YYYY h:mm a').format('h:mm a');
    const roleDetail = (this.assignment) ? this.assignment.name + ', ' + this.role.name : this.role.name;
    this.assignmentDetail = startTime + ' - ' + endTime + ' - ' + roleDetail;
    this.currentView = 'form';
  }

  async getEmployeeInfo(
    schedAssignedUser: SchRoleAssignmentUser
  ): Promise<boolean> {
    try {
      const res = await this.userService
        .getADUserById(schedAssignedUser.userId)
        .toPromise();
      if (res) {
        this.employee = (res.results && res.results.length > 0) ? res.results[0] : [];

      }
      return Promise.resolve(true);
    } catch { }
  }
}
