import { AlertController } from '@ionic/angular';
import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { Role } from "src/app/models/role";
import { RoleAssignment } from "src/app/models/role-assignment";
import { RoleGroup } from "src/app/models/role-group";
import { SchRoleAssignment } from "src/app/models/sched-role-assignment";
import { Shift } from "src/app/models/shift";
import { RoleAssignmentService } from "src/app/services/role-assignment/role-assignment.service";
import { SchedRoleAssignmentsService } from "src/app/services/sched-role-assignments/sched-role-assignments.service";
import { AuthService } from "src/app/services/_core/auth/auth.service";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';

@Component({
  selector: "app-popover-assignments",
  templateUrl: "./popover-assignments.page.html",
  styleUrls: ["./popover-assignments.page.scss"],
})
export class PopoverAssignmentsPage implements OnInit {
  @Input() rolSeq: number;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  @Input() shfSeq: number;
  @Input() role: Role;
  @Input() authUserPermission: string;
  assignmentInput: string = "";
  roleAssignments: RoleAssignment[] = [];
  inputValue: string;
  isDuplicate = false;
  currentView: "form" | "loading" = "form";
  searchTerm = "";
  isFiltering = false;
  initAssignments: RoleAssignment[];
  constructor(
    private alertCtrl: AlertController,
    private roleAssignmentService: RoleAssignmentService,
    private notificationService: NotificationsService,
    private schedRoleAssignmentsService: SchedRoleAssignmentsService,
    private authService: AuthService,
    private analytics: BhAnalyticsService
  ) { }

  async ngOnInit() { }

  async ionViewWillEnter() {
    try {
      this.currentView = "loading";
      await this.loadInitRoleAssignments();
      this.currentView = "form";
    } catch (err) {
      this.notificationService.handleError(
        err,
        "Error Loading Role Assignments"
      );
    }
  }

  ionViewWillLeave() {
    this.saveRoleAssignments();
  }

  async addNewAssignment() {
    console.log("new assignment", this.assignmentInput);
    try {
      const existingAsi = this.initAssignments.find(
        (ra) => ra.name.trim() === this.assignmentInput.trim()
      );
      console.log("does it exist?", existingAsi);
      if (!existingAsi) {
        this.currentView = "loading";
        const newRoleAssignment: RoleAssignment = {
          rolSeq: this.rolSeq,
          romSeq: 1,
          name: this.assignmentInput,
          active: 1,
          default: 1,
          isChecked: false,
        };
        const res = await this.roleAssignmentService
          .createRoleAssignment(this.rosSeq, this.rolSeq, newRoleAssignment)
          .toPromise();
        this.analytics.clickEvent("add-assignment", "added " + newRoleAssignment.name, this.rosSeq);
        console.log("created new RoleAssignment", res);
        this.assignmentInput = "";
        this.isFiltering = false;
        this.roleAssignments = this.initAssignments;
        newRoleAssignment.rasSeq = res.rasSeq;
        this.roleAssignments.push(newRoleAssignment);
        this.currentView = "form";
        console.log("check out inside roleAssignments", this.roleAssignments);
      } else {
        console.log("Duplicate Role Assignment");
        if (existingAsi.active === 0) {
          this.currentView = 'loading';
          existingAsi.active = 1;
          const res = await this.roleAssignmentService
            .updateRoleAssignment(this.rosSeq, existingAsi.rolSeq, existingAsi.rasSeq, existingAsi)
            .toPromise();
          this.analytics.clickEvent("update-assignment", "added " + existingAsi.name, this.rosSeq);
          this.assignmentInput = "";
          this.isFiltering = false;  
          this.roleAssignments = this.initAssignments;
          this.currentView = "form";
        } else {
          this.isDuplicate = true;
        }
        // this.assignmentInput = "";
      }
    } catch (err) {
      this.currentView = "form";
      this.notificationService.handleError(err, "addNewAssignment()");
    }
  }

  async loadInitRoleAssignments(): Promise<boolean> {
    try {
      const res = await this.roleAssignmentService
        .getAllRoleAssignments(this.rosSeq, this.rolSeq)
        .toPromise();
      if (res) {
        this.roleAssignments = res.assignments;
        this.initAssignments = this.roleAssignments;
        for (const ra of this.roleAssignments) {
          const match = this.role.schRoleAssignments.find(
            (sra) => sra.rasSeq === ra.rasSeq && ra.active === 1 && sra.active === 1
          );
          ra.isChecked = match !== undefined;
        }
      }
      return Promise.resolve(true);
    } catch (err) {
      //this.notificationService.handleError(err, "loadRoleAssignments()");
      throw err;
    }
  }

  // setRoleAssignments() {
  //   try {
  //     this.roleAssignments.forEach((ra) => {
  //       const matches = this.role.schRoleAssignments.filter(
  //         (sra) => sra.rasSeq === ra.rasSeq && sra.active === 1 && ra.active === 1
  //       );
  //       console.log("sra", this.role.schRoleAssignments);
  //       console.log("ra", ra);
  //       console.log("matches ra", matches);

  //       ra.isChecked = matches.length > 0;
  //       console.log("ra is checked", ra.isChecked);
  //     });
  //   } catch (err) {
  //     this.notificationService.handleError(err, "setRoleAssignments()");
  //   }
  // }

  async saveRoleAssignments() {
    try {
      for (const ra of this.initAssignments) {
        const matches = this.role.schRoleAssignments.filter(
          (sra) => sra.rasSeq === ra.rasSeq && sra.active === 1 && ra.active === 1
        );
        console.log("check ra", ra);
        console.log("matches", matches);
        if (ra.isChecked) {
          if (matches.length === 0) {
            const schedRoleAssignment: SchRoleAssignment = {
              rasSeq: ra.rasSeq,
              rosSeq: this.rosSeq,
              schSeq: this.schSeq,
              rgpSeq: this.role.rgpSeq,
              rolSeq: ra.rolSeq,
              shfSeq: this.shfSeq,
              schedDate: this.schedDate,
              name: ra.name,
              active: 1,
            };
            const res = await this.schedRoleAssignmentsService
              .createSchedRoleAssignment(
                this.rosSeq,
                this.schSeq,
                schedRoleAssignment
              )
              .toPromise();
              this.analytics.clickEvent("add-assignment", "added " + schedRoleAssignment.name, this.rosSeq);
            console.log("create new schedRoleAssignment", res);
            schedRoleAssignment.sraSeq = res.sraSeq;
            this.role.schRoleAssignments.push(schedRoleAssignment);
          } else {
            // SchRoleAssignment exists
            console.log('schRoleAssignment exists', ra);
          }
        } else {
          //assignment exists but was removed
          if (matches.length > 0) {
            matches.forEach(async (mat) => {
              mat.active = 0;
              const res = await this.schedRoleAssignmentsService
                .updateSchedRoleAssignment(
                  this.rosSeq,
                  this.schSeq,
                  mat.sraSeq,
                  mat
                )
                .toPromise();
              this.analytics.clickEvent("update-assignment", "added " + mat.name, this.rosSeq);
              console.log("updated matches", res);
            });
            this.role.schRoleAssignments = this.role.schRoleAssignments.filter(
              (sra) => sra.rasSeq !== ra.rasSeq
            );
          }
        }
        this.role.schRoleAssignments = this.role.schRoleAssignments.sort((a, b) =>
          a.name > b.name ? 1 : a.name === b.name ? 0 : -1
        );
      };
    } catch { }
  }

  filter(ev) {
    this.isDuplicate = false;
    if (ev) {
      this.isFiltering = true;
      const term = this.assignmentInput
        .replace(/\ /gi, "")
        .toUpperCase()
        .trim();
      this.roleAssignments = this.initAssignments.filter(
        (ras) => ras.name.replace(/\ /gi, "").toUpperCase().trim().indexOf(term) > -1 && ras.active === 1
      );
    } else {
      this.assignmentInput = "";
      this.isFiltering = false;
      this.roleAssignments = this.initAssignments;
    }
  }

  async deleteAssignment(assignment: RoleAssignment) {
    const alert = await this.alertCtrl.create({
      header: 'Delete this assignment?',
      message: `Deleting this assignment will make it unusable and can only be recovered by the support team. 
                  Prior schedules that use this assignment will retain it for historical purposes.`,
                  cssClass: 'wide-alert danger',
      buttons: [
        {
          text: 'No thanks',
          role: 'cancel'
        },
        {
          text: 'Delete',
          cssClass: 'font-color-danger',
          handler: async () => {
            try {
              this.currentView = 'loading';
              assignment.active = 0;
              const res = await this.roleAssignmentService
                .updateRoleAssignment(this.rosSeq, assignment.rolSeq, assignment.rasSeq, assignment)
                .toPromise();
              console.log('deleteAssignment.handler.UpdateRoleAssignment => res', res);
              this.analytics.clickEvent('delete-assignment', 'removed ' + assignment.name, this.rosSeq);
              this.loadInitRoleAssignments();
              this.currentView = 'form';
            } catch (err) {
              this.notificationService.handleError(err, 'deleteAssignment.handler');
            }
          }
        },
      ]
    });
    alert.present();
  }
}
