import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditRoleAssignmentPageRoutingModule } from './edit-role-assignment-routing.module';

import { EditRoleAssignmentPage } from './edit-role-assignment.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EditRoleAssignmentPageRoutingModule
  ],
  declarations: [EditRoleAssignmentPage]
})
export class EditRoleAssignmentPageModule {}
