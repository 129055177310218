import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditRoleGroupPageRoutingModule } from './edit-role-group-routing.module';

import { EditRoleGroupPage } from './edit-role-group.page';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    EditRoleGroupPageRoutingModule,
    BhInputComponentModule,
    PipesModule
  ],
  declarations: [EditRoleGroupPage]
})
export class EditRoleGroupPageModule {}
