import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MenuController, ModalController, NavController } from "@ionic/angular";
import { BhGridColumnDefinition } from "src/app/models/_core/bh-grid-column-definition";
import { BhAnalyticsService } from "src/app/services/_core/bhanalytics/bhanalytics.service";
import { RosterService } from "src/app/services/roster/roster.service";
import { ShiftsService } from "src/app/services/shifts/shifts.service";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";
import { StorageService } from "src/app/services/_core/storage/storage.service";
import { Subscription } from "rxjs";
import { Role } from "src/app/models/role";
import { RoleGroup } from "src/app/models/role-group";
import { Roster } from "src/app/models/roster";
import { SchRoleAssignmentUser } from "src/app/models/sched-role-assign-user";
import { SchRoleAssignment } from "src/app/models/sched-role-assignment";
import { Shift } from "src/app/models/shift";
import { RoleGroupsService } from "src/app/services/role-groups/role-groups.service";
import { RoleService } from "src/app/services/role/role.service";
import { FormBuilder } from "@angular/forms";
import { RoomService } from "src/app/services/room/room.service";
import { Room } from "src/app/models/room";
import { AddRoomPage } from "../add-room/add-room.page";
import { EditRoomPage } from "../edit-room/edit-room.page";
@Component({
  selector: "app-manage-rooms",
  templateUrl: "./manage-rooms.page.html",
  styleUrls: ["./manage-rooms.page.scss"],
})
export class ManageRoomsPage implements OnInit, OnDestroy {
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: number;
  @Input() selectedRoom: Room;
  
  columnDefinitions: BhGridColumnDefinition[] = [
    {
      fieldName: "name",
      columnLabel: "Room",
      ionIcon: "person-outline",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: true,
      showColumn: true,
    },
    {
      fieldName: "active",
      columnLabel: "Active",
      ionIcon: "",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: false,
      showColumn: true,
    },
    {
      fieldName: "addDate",
      columnLabel: "Created",
      ionIcon: "",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: false,
      showColumn: true,
    },
    {
      fieldName: "romSeq",
      columnLabel: "ID",
      ionIcon: "",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: false,
      showColumn: true,
    },
  ];

  subscriptions: Subscription[] = [];
  selectedShiftSubject: Shift;
  selectRosterSubject: Roster;
  selectedRoleGroup: RoleGroup;
  roleGroups: RoleGroup[] = [];
  roles: Role[] = [];
  rooms: Room[] = [];
  schRoleAssignments: SchRoleAssignment[];
  schRoleAssignUsers: SchRoleAssignmentUser[];
  searchTerm = '';
  isFiltering = false;
  initRooms: Room[];
  hasBeenChanged = false;
  constructor(
    private shiftService: ShiftsService,
    private roleGroupService: RoleGroupsService,
    private roleService: RoleService,
    private rosterService: RosterService,
    private roomService: RoomService,
    public notificationsService: NotificationsService,
    private menu: MenuController,
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private storageService: StorageService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    console.log("rosSeq", this.rosSeq);
    //this.loadTableData();
  }

  ionViewDidEnter() {
    this.loadTableData();
  }


  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
      sub = null;
    });
  }

  loadTableData() {
    if (this.subscriptions.length === 0) {
      this.subscriptions = [
        this.rosterService.rosterSubject.subscribe((val) => {
          this.selectRosterSubject = val;
        }),
      ];
    }
    this.loadAllRooms(this.rosSeq);
  }

  async loadAllRooms(rosSeq): Promise<boolean> {
    try {
      let res = await this.roomService.getAllRooms(rosSeq).toPromise();
      if (res) {
        this.rooms = res.rooms;
        console.log("all rooms", this.rooms);
        this.initRooms = this.rooms;
        return Promise.resolve(true);
      }
    } catch (err) {
      this.notificationsService.handleError(err, "getAllRooms()");
    }
  }

  async addRoom() {
    this.analytics.clickEvent("test-open-addRole-modal", "clicked");
    const modal = await this.modalCtrl.create({
      component: AddRoomPage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        rosSeq: this.rosSeq,
        schSeq: this.schSeq,
        schedDate: this.schedDate
      }
    });
    modal.present();
    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.isSaved) {
        this.loadAllRooms(this.rosSeq);
        this.hasBeenChanged = true;
      }
    });
  }

  getRoom(room) {
    console.log('room', room);
    this.selectedRoom = room;
    this.editRoom();
  }

  async editRoom() {
    this.analytics.clickEvent("manage-role-groups", "clicked");
    const modal = await this.modalCtrl.create({
      component: EditRoomPage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        room: this.selectedRoom,
        rosSeq: this.rosSeq,
        schSeq: this.schSeq,
        schedDate: this.schedDate,
        componentLabel: "Room",
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.isUpdated) {
        this.loadAllRooms(this.rosSeq);
        this.hasBeenChanged = true;
      }
    });
    modal.present();
  }

  dismiss() {
    this.analytics.clickEvent("feedback: dismiss", "");
    if(this.hasBeenChanged !== false) {
      this.modalCtrl.dismiss({hasBeenChanged: this.hasBeenChanged});
    } else {
        this.modalCtrl.dismiss();
    }
  }

  filter(ev) {
    if (ev) {
      this.isFiltering = true;
      const term = ev.toLowerCase().trim();
      this.rooms = this.rooms.filter(rm =>
        rm.name.toLowerCase().indexOf(term) > -1 ||
        rm.romSeq.toString().indexOf(term) > -1
      );
    } else {
      this.searchTerm = '';
      this.isFiltering = false;
      this.rooms = this.initRooms;
    }
  }
}
