import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddRoomPageRoutingModule } from './add-room-routing.module';

import { AddRoomPage } from './add-room.page';
import { BhGridComponentModule } from 'src/app/components/_core/bh-grid/bh-grid.component.module';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddRoomPageRoutingModule,
    BhGridComponentModule,
    BhInputComponentModule,
    ReactiveFormsModule
  ],
  declarations: [AddRoomPage]
})
export class AddRoomPageModule {}
