import { BhGridColumnDefinition, BhGridGroup } from './../../../models/_core/bh-grid-column-definition';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';

/**
 * ID: bh-grid
 * Name: BH Grid
 * Description: A responsive data grid that shows either mat-table or bh-content-card to present data depending on viewport
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-06-23 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented proper date sorting
 */
@Component({
  selector: 'bh-grid',
  templateUrl: './bh-grid.component.html',
  styleUrls: ['./bh-grid.component.scss'],
})
export class BhGridComponent implements OnChanges {
  @Input() columnDefinitions: BhGridColumnDefinition[] = [];
  @Output() selectEvent = new EventEmitter<any>();
  @Input() data: any[] = [];
  @Input() groupBy = '';
  @Input() sort1 = '';
  @Input() sort2 = '';
  parsedData: any[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [];
  @ViewChild(MatSort) sort: MatSort;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if ('columnDefinitions' in changes && this.columnDefinitions) {
      this.setColumnDefinitions();
    }

    if ('data' in changes && this.data) {
      let i = 0;
      this.parsedData = [];
      if (this.groupBy || this.sort1 || this.sort2) {
        this.data.sort((a, b) => {

          // Check for grouping
          if (this.groupBy in a && this.groupBy in b ) {
            if (this.groupBy === 'startDate') {
              const aMoment = moment(a[this.groupBy], 'MM/DD/YYYY');
              const bMoment = moment(b[this.groupBy], 'MM/DD/YYYY');
      
              if (aMoment < bMoment) {
                return -1;
              }
      
              if (aMoment > bMoment) {
                return 1;
              }  
      
            } else {
              if (a[this.groupBy].toLowerCase() < b[this.groupBy].toLowerCase()) {
                return -1;
              }
      
              if (a[this.groupBy].toLowerCase() > b[this.groupBy].toLowerCase()) {
                return 1;
              }  
            }
      
            return this.sortItems(a, b);

          } else {
            // No grouping 
            return this.sortItems(a, b);
          }
        });
      }

      for (const dataItem of this.data) {
        // Check for existing group, add if it doesn't exist
        if (this.groupBy && i === 0 && this.groupBy in dataItem) {
          const group: BhGridGroup = { name: dataItem[this.groupBy], isGroup: true };
          this.parsedData.push(group);
        }
        const parsedItem = { bhGridRowIndex: i };
        for (const colDef of this.columnDefinitions) {
          let dataElement = dataItem[colDef.fieldName]
          // Check if date and format for sorting
          if (colDef.isDate && colDef.dateFormat) {
            const dataElementMoment = moment(dataElement, colDef.dateFormat);
            // Check for successful date parse
            if (dataElementMoment.isValid()) {
              dataElement = dataElementMoment.format('YYYY-MM-DD HH:mm:ss');
            } else {
              colDef.isDate = false;
            }
          } else {
            colDef.isDate = false;
          }
          parsedItem[colDef.fieldName] = dataElement;
        }
        this.parsedData.push(parsedItem);
        if (this.groupBy) {
          // Look to next item to check for new group header
          const nextItem = this.data[i + 1];
          if (nextItem !== undefined && this.groupBy in parsedItem && this.groupBy in nextItem) {
            if (parsedItem[this.groupBy].toLowerCase() !== nextItem[this.groupBy].toLowerCase()) {
              const group: BhGridGroup = { name: nextItem[this.groupBy], isGroup: true };
              this.parsedData.push(group);
            }
          }
        }
        i += 1;
      }
      console.log('parsedData with groups: ', this.parsedData);
      this.setDataSource();
    }
  }

  sortItems(a, b): number {
    if (this.sort1 && this.sort1 in a && this.sort1 in b) {
      if (this.sort1 === 'startDate') {
        const aMoment = moment(a[this.sort1], 'MM/DD/YYYY');
        const bMoment = moment(b[this.sort1], 'MM/DD/YYYY');

        if (aMoment < bMoment) {
          return -1;
        }

        if (aMoment > bMoment) {
          return 1;
        }  

      } else {
        if (a[this.sort1].toLowerCase() < b[this.sort1].toLowerCase()) {
          return -1;
        }

        if (a[this.sort1].toLowerCase() > b[this.sort1].toLowerCase()) {
          return 1;
        }  
      }

      // Apply sort 2
      if (this.sort2 && this.sort2 in a && this.sort2 in b) {
        if (this.sort2 === 'startDate') {
          const aMoment = moment(a[this.sort2], 'MM/DD/YYYY');
          const bMoment = moment(b[this.sort2], 'MM/DD/YYYY');

          if (aMoment < bMoment) {
            return -1;
          }

          if (aMoment > bMoment) {
            return 1;
          }  

        } else {
          if (a[this.sort2].toLowerCase() < b[this.sort2].toLowerCase()) {
            return -1;
          }

          if (a[this.sort2].toLowerCase() > b[this.sort2].toLowerCase()) {
            return 1;
          }  
        }
      } else {
        return 0;
      }

      return 0;
    }
  }

  setColumnDefinitions() {
    this.displayedColumns = [];
    for (const cd of this.columnDefinitions) {
      if (cd.showColumn) {
        this.displayedColumns.push(cd.fieldName);
      }
    }
  }

  setDataSource() {
    setTimeout( () => {
      this.dataSource = new MatTableDataSource<any>(this.parsedData);
      if (!this.groupBy && !this.sort1 && !this.sort2) {
        this.dataSource.sort = this.sort;
      }
    }, 250)
  }

  selectRow(dataItem) {
    this.selectEvent.emit(dataItem);
  }

  isGroup(index: number, item: any): boolean {
    const isGroup = item.hasOwnProperty('name') && item.hasOwnProperty('isGroup');
    //console.log('isGroup? ', isGroup);
    return isGroup;
  }

  getDataItemIcon(dataItem) {
    if (this.columnDefinitions.length > 0) {
      const icon = this.columnDefinitions[0].ionIcon;
      return icon ? icon : null;
    } else {
      return null;
    }
  }

  checkDataItemBadge(dataItem) {
    if (this.columnDefinitions.length > 0) {
      const hasIconBadge = this.columnDefinitions[0].hasIconBadge;
      const iconBadgeArgField = this.columnDefinitions[0].iconBadgeArgField;
      const iconBadgeArgValue = this.columnDefinitions[0].iconBadgeArgValue;
      return hasIconBadge && dataItem[iconBadgeArgField] ? dataItem[iconBadgeArgField] === iconBadgeArgValue : null;
    } else {
      return null;
    }

  }

  getDataItemTitle(dataItem) {
    if (this.columnDefinitions.length > 0) {
      const key = this.columnDefinitions[0].fieldName;
      return dataItem[key] ? dataItem[key] : null;
    } else {
      return null;
    }
  }

  getOtherDataItemProperties(dataItem) {
    //console.log('dataItem', dataItem);
    
    if (this.columnDefinitions.length > 1) {
      let innerHtml = '<div class="font-size-small">';
      let index = 0;
      for (const cd of this.columnDefinitions) {
        if (Object.prototype.hasOwnProperty.call(dataItem, cd.fieldName) && index > 0) {
          //let value = dataItem[cd.fieldName];
          // const iconName = cd.ionIcon ? cd.ionIcon : null;
          // const iconColor = cd.ionIconColor ? cd.ionIconColor : null;
          // const icon = (cd.showIconArg === dataItem[cd.fieldName] || cd.alwaysShowIcon) ?
          //   '...<ion-icon name="' + iconName + '" color="' + iconColor + '"></ion-icon>...' : '';
          // value = icon && cd.showIconOnly ?
          //   icon : icon ? value + icon : value;
          //innerHtml += cd.columnLabel + ': <strong>' + value + '</strong><br>';
        }
        index += 1;
      }
      const key = this.columnDefinitions[1].fieldName;
      return index > 0 ? innerHtml + '</div>' : null;
    } else {
      return null;
    }
  }

  getDataItemIconForColumn(
    colDef: BhGridColumnDefinition,
    dataValue: any
  ): any {
    //console.log("colDef", colDef, "dataValue", dataValue, "ionIconConditions", colDef.ionIconConditions);
    if (colDef.ionIcon) {
      let ionIcon = null;
      let iconColor = null;
      if (colDef.useIconArg) {
        //console.log("colDef", colDef, "dataValue", dataValue, colDef.showIconArg === dataValue);
        if (colDef.showIconArg === dataValue) {
          ionIcon = colDef.ionIcon;
          iconColor = colDef.ionIconColor;
        }
      } else {
        ionIcon = colDef.ionIcon;
        iconColor = colDef.ionIconColor;
      }
      return { icon: ionIcon, color: iconColor };
    } else if (
      colDef.ionIconConditions &&
      colDef.ionIconConditions.length > 0
    ) {
      const iconConditionMatch = colDef.ionIconConditions.find(
        (i) => {
          //console.log('i', i.value, 'dataValue', dataValue);
          return Number(i.value) == Number(dataValue)}
      );
      //console.log('icon Condition Match', iconConditionMatch);
      
      return iconConditionMatch !== undefined
        ? { icon: iconConditionMatch.ionIcon, color: iconConditionMatch.color }
        : null;
    }
  }

}
