import { BhContentCardComponentModule } from './../bh-content-card/bh-content-card.component.module';
import { BhGridComponent } from './../bh-grid/bh-grid.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [
    BhGridComponent
    ],
  imports: [
    PipesModule,
    BhContentCardComponentModule,
    MatTableModule,
    MatSortModule,
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  exports: [
    BhGridComponent
  ]
})
export class BhGridComponentModule { }
