import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Route,
  Router,
} from "@angular/router";
import { AuthService } from "./_core/auth/auth.service";
import { first } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { NotificationsService } from "./_core/notifications/notifications.service";
import { AlertController, NavController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class EmployeeBoardGuardService {
  key: string;
  env = environment;

  constructor(
    private alertCtrl: AlertController,
    private authService: AuthService,
    private notifications: NotificationsService,
    private nav: NavController
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const authUser = this.authService.getAuthUser();
    if (authUser.userId && authUser.token) {
      return Promise.resolve(true);
    } else {
      try {
        let key = route.paramMap.get("rosSeq");
        //console.log("key", key);
        // const apiToken = this.env.deviceToken;
        let apiToken = route.queryParamMap.get('skey');

        // Store paging flag
        let paging = route.queryParamMap.get('paging');
        console.log('employee-board-guard: paging', paging);
        if (paging) {
          localStorage.setItem('paging', paging);
        }

        // Store details flag
        let details = route.queryParamMap.get('details');
        console.log('employee-board-guard: details', details);
        if (details) {
          localStorage.setItem('details', details);
        }

        // Store hidden metrics flag
        // let showHidden = route.queryParamMap.get('showHidden');
        // console.log('** show hidden Metrics **', showHidden);

        // console.log('employee-board-guard: showMetrics', showHidden);
        // if (showHidden) {
        //   localStorage.setItem('showHidden', showHidden);
        // }

        // Store hide resources flag
        let hideResources = route.queryParamMap.get('hideResources');
        console.log('** hide sources **', hideResources);

        console.log('employee-board-guard: hideResources', hideResources);
        if (hideResources) {
          localStorage.setItem('hideResources', hideResources);
        }

        // Check storage
        if (!key || !apiToken) {
          const storedKey = localStorage.getItem("deviceKey");
          const storedApiToken = localStorage.getItem("apiToken");
          if (storedKey && storedApiToken) {
            key = storedKey;
            apiToken = storedApiToken;
          }
        }
        if (key && apiToken) {
          const loginRes = await this.authService
            .loginDevice(key, apiToken)
            .toPromise();
          if (loginRes.token) {
            console.log('made it here', loginRes);
            const path = location.pathname + '?' +
              (paging ? 'paging=' + paging + '&' : '') +
              (details ? 'details=' + details + '&' : '') +
              (hideResources ? 'hideResources=' + hideResources + '&' : '');
            // (showHidden ? 'showHidden=' + showHidden + '&' : '') +
            this.nav.navigateRoot(path);
            return Promise.resolve(true);
          } else {
            // console.log('token not found');
            this.showAlert(
              "Unable to Display Board",
              "Bad device credentials. (Error 1)",
              "danger"
            );
            this.nav.navigateRoot('/tabs/roster/' + key);
            return Promise.resolve(false);
          }
        } else {
          // this.nav.navigateRoot('login');
          this.showAlert(
            "Unable to Display Board",
            "Bad device credentials. Sign in to continue. (Error 2)",
            "danger",
            true
          );

          return Promise.resolve(false);
        }
      } catch (error) {
        this.notifications.handleError(error, "canActivate()");
        this.nav.navigateRoot('login');
        return Promise.resolve(false);
      }
    }
  }

  async showAlert(header, message, alertType, goToLogin = false) {
    const loginButton = {
      text: 'Sign in',
      cssClass: 'primary',
      handler: () => {
        this.nav.navigateRoot('/login');
      }
    };

    const continueButton = {
      text: 'Got it',
      cssClass: 'primary'
    };

    const alert = await this.alertCtrl.create({
      header,
      message,
      cssClass: 'wide-alert ' + alertType,
      backdropDismiss: false,
      buttons: [
        (goToLogin) ? loginButton : continueButton
      ]
    });

    await alert.present();
  }

  // getKeyFromUrl(route: ActivatedRouteSnapshot): string {
  //   return this.key;
  // }
}
