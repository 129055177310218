import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { BhGridColumnDefinition } from 'src/app/models/_core/bh-grid-column-definition';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { RosterService } from 'src/app/services/roster/roster.service';
import { ShiftsService } from 'src/app/services/shifts/shifts.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { StorageService } from 'src/app/services/_core/storage/storage.service';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/models/role';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { SchRoleAssignmentUser } from 'src/app/models/sched-role-assign-user';
import { SchRoleAssignment } from 'src/app/models/sched-role-assignment';
import { Shift } from 'src/app/models/shift';
import { RoleGroupsService } from 'src/app/services/role-groups/role-groups.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectOption } from 'src/app/models/_core/select-option';
import * as moment from 'moment';
import { AddEditRoleGroupPage } from '../add-edit-role-group/add-edit-role-group.page';
import { EditRoleGroupPage } from '../edit-role-group/edit-role-group.page';


@Component({
  selector: 'app-manage-role-groups',
  templateUrl: './manage-role-groups.page.html',
  styleUrls: ['./manage-role-groups.page.scss'],
})
export class ManageRoleGroupsPage implements OnInit, OnDestroy{

  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: number;
  @Input() roster: Roster;
  shfSeq: number;
  //@Input() selectedShift: Shift;

  columnDefinitions: BhGridColumnDefinition[] = [
    {
      fieldName: 'name', columnLabel: 'Role Group', ionIcon: 'person-outline', ionIconColor: null, showIconArg: '',
      showIconOnly: false, alwaysShowIcon: true, showColumn: true
    },
    {
      fieldName: 'active', columnLabel: 'Active', ionIcon: '', ionIconColor: null, showIconArg: '',
      showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'addDate', columnLabel: 'Created', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'rgpSeq', columnLabel: 'ID', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
  ];

  shift: Shift = {
    shfSeq: 1,
  };

  validationMessages = {
    shift: [{ type: 'required', message: 'Active Status is required.' }],
  };

   // Submission State
 submitAttempted = false;

 // View only State
 viewOnly = true;

  //shiftTimes = ['12:00 am - 8:00 am', '8:00 am - 10:00']

  shiftSelection: SelectOption[] = [{}];

  form1: FormGroup = this.formBuilder.group({
    shift: [null, Validators.required]
  });

  subscriptions: Subscription[] = [];
  selectedShiftSubject: Shift;
  selectRosterSubject: Roster;
  selectedRoleGroup: RoleGroup;
  roleGroups: RoleGroup[] = [];
  roles: Role[] = [];
  shifts: Shift[] = [];
  schRoleAssignments: SchRoleAssignment[];
  schRoleAssignUsers: SchRoleAssignmentUser[];
  shiftTimes: string[] = [];
  shiftSeq: number[] = [];
  searchTerm = '';
  isFiltering = false;
  initRoleGroups: RoleGroup[];
  hasBeenChanged = false;
  constructor(
    private shiftService: ShiftsService,
    private roleGroupService: RoleGroupsService,
    private rosterService: RosterService,
    public notificationsService: NotificationsService,
    private menu: MenuController,
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() { 
    
    //console.log('selected Shift', this.selectedShift);
  }

  ionViewDidEnter() {
    this.loadTableData();
  }


  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
      sub = null;
    });
  }

  loadTableData() {
    if (this.subscriptions.length === 0) {
      this.subscriptions = [
        this.rosterService.rosterSubject.subscribe(val => {
          this.selectRosterSubject = val;
        }),
        this.form1.controls.shift.valueChanges.subscribe( val => {
          if(val) {
            this.shfSeq = val;
            this.loadRoleGroups(this.shfSeq);
          } else {
              this.roleGroups = [];
          }
        })
      ];
    }
    this.getAllShifts(this.rosSeq);
  }

  loadFormData() {
    if (this.shift) {
      
    } else {
      this.notificationsService.handleError('No shift found.', 'form: shiftForm');
    }
  }

  async getAllShifts(rosSeq) {
    try {
        let res = await this.shiftService.getAllShifts(rosSeq, 0).toPromise();
        if(res) {
            this.shifts = res.shifts;
            console.log('shifts', this.shifts);
            this.shifts.forEach( shift => {
              const startTime = moment(shift.startTime, 'hh:mm a').format('hh:mm a');
              const endTime = moment(shift.endTime,'hh:mm a').format('hh:mm a');
              const shiftStatus = this.getShiftStatus(shift);
              const shiftTime = startTime + ' - ' + endTime + shiftStatus;
              this.shiftSelection.push({label: shiftTime, value: shift.shfSeq});
              this.shiftSelection = this.shiftSelection.filter( ss => Object.keys(ss).length !== 0);
            });  
            console.log('shift Seq', this.shiftSeq);
        }
    } catch(err) {
      this.notificationsService.handleError(err, 'getAllShifts()');
    }
  }

  async loadRoleGroups(shfSeq) {
    try {
          let res = await this.roleGroupService.getAllRoleGroups(this.rosSeq, shfSeq).toPromise();
          if(res) {
                this.roleGroups = res.roleGroups;
                this.initRoleGroups = this.roleGroups;
              }   
        console.log('roleGroups', this.roleGroups);
    } catch(err) {
      this.notificationsService.handleError(err, 'getAllRoleGroups()');
    }
  }

  getShiftStatus(shift: Shift) {
    let currentMoment = moment();
    const postDateMoment = moment(shift.postDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss');
    const stopDateMoment = (shift.stopDate) ? moment(shift.stopDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss') : null;
    const isLive = postDateMoment <= currentMoment && (!stopDateMoment || stopDateMoment >= currentMoment);
    if(!shift.active) {
        return ' (inactive)';
    } else if(!isLive) {
        return ' (expired)';
    } else {
      return '';
    }
  }

  async AddNewRoleGroup(shift: Shift) {
    this.analytics.clickEvent("manage-role-groups", "clicked");
    const modal = await this.modalCtrl.create({
      component: AddEditRoleGroupPage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        rosSeq: this.rosSeq,
        shifts: shift,
        componentLabel: 'Role Groups',
        roster: this.roster
      }
    });
    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.isSaved) {
        if(this.shfSeq) {
          this.loadRoleGroups(this.shfSeq);
          this.hasBeenChanged = true;
        }
      }
    });
    modal.present();
  }

  getRoleGroup(roleGroup) {
    console.log('roleGroup', roleGroup);
    this.selectedRoleGroup = roleGroup;
    this.editRoleGroup();
  }

  async editRoleGroup() {
    this.analytics.clickEvent("manage-role-groups", "clicked");
    const modal = await this.modalCtrl.create({
      component: EditRoleGroupPage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        shfSeq: this.shfSeq,
        roleGroup: this.selectedRoleGroup,
        rosSeq: this.rosSeq,
        schSeq: this.schSeq,
        schedDate: this.schedDate,
        componentLabel: "Role Groups",
        roster: this.roster
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.isUpdated) {
        if(this.shfSeq) {
          this.loadRoleGroups(this.shfSeq);
          this.hasBeenChanged = true;
        }
      }
    });
    modal.present();
  }

  dismiss() {
    this.analytics.clickEvent('feedback: dismiss', '');
    if(this.hasBeenChanged !== false) {
      this.modalCtrl.dismiss({hasBeenChanged: this.hasBeenChanged});
    } else {
        this.modalCtrl.dismiss();
    }
  }

  filter(ev) {
    if (ev) {
      this.isFiltering = true;
      const term = ev.toLowerCase().trim();
      this.roleGroups = this.roleGroups.filter(rg =>
        rg.name.toLowerCase().indexOf(term) > -1 ||
        rg.rgpSeq.toString().indexOf(term) > -1
      );
    } else {
      this.searchTerm = '';
      this.isFiltering = false;
      this.roleGroups = this.roleGroups;
    }
  }

}
