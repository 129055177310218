import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmployeeBoardUpdateRoleGroupsPage } from './employee-board-update-role-groups.page';

const routes: Routes = [
  {
    path: '',
    component: EmployeeBoardUpdateRoleGroupsPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmployeeBoardUpdateRoleGroupsPageRoutingModule {}
