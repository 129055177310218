import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ManageUsersEditPageRoutingModule } from './manage-users-edit-routing.module';

import { ManageUsersEditPage } from './manage-users-edit.page';
import { BhSpinnerComponentModule } from 'src/app/components/_core/bh-spinner/bh-spinner.component.module';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { BhMessageBannerComponentModule } from 'src/app/components/_core/bh-message-banner/bh-message-banner.component.module';
import { BhContentEmptyComponentModule } from 'src/app/components/_core/bh-content-empty/bh-content-empty.component.module';

@NgModule({
  imports: [
    BhSpinnerComponentModule,
    BhInputComponentModule,
    BhMessageBannerComponentModule,
    BhContentEmptyComponentModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ManageUsersEditPageRoutingModule
  ],
  declarations: [ManageUsersEditPage]
})
export class ManageUsersEditPageModule {}
