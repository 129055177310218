import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Role } from 'src/app/models/role';
import { RoleAssignment } from 'src/app/models/role-assignment';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { Shift } from 'src/app/models/shift';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class RoleAssignmentService {

  env = environment;
  roster: Roster;
  shift: Shift;
  roleGroups: RoleGroup;
  roles: Role;
  roleAssignment: RoleAssignment;
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  getAllRoleAssignments(rosSeq, rolSeq) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/roles/${rolSeq}/assignments`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "role-assignment-service.getAllRoleAssignments(): " + url
        );
        return of(err);
      })
    );
  }

  getSingleRoleAssignment(rosSeq, rolSeq, rasSeq) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/roles/${rolSeq}/assignments/${rasSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "role-assignment-service.getSingleRoleAssignment(): " + url
        );
        return of(err);
      })
    );
  }

  createRoleAssignment(rosSeq, rolSeq, roleAssignment: RoleAssignment) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/roles/${rolSeq}/assignments/`;
    console.log("role-assignment: assignment created", roleAssignment);
    const body = roleAssignment;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "role-assignment-service.CreateRoleAssignment(): " + url
        );
        return of(err);
      })
    );
  }

  updateRoleAssignment(rosSeq, rolSeq, rasSeq, roleAssignment: RoleAssignment) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/roles/${rolSeq}/assignments/${rasSeq}`;
    console.log("role-assignment: assignment updated", roleAssignment);
    const body = roleAssignment;
    return this.http.put(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "role-assignment-service.UpdateRoleAssignment(): " + url
        );
        return of(err);
      })
    );
  }
}
