import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PopoverAssignmentsPageRoutingModule } from './popover-assignments-routing.module';

import { PopoverAssignmentsPage } from './popover-assignments.page';
import { BhSpinnerComponentModule } from 'src/app/components/_core/bh-spinner/bh-spinner.component.module';
import { BhContentSearchComponentModule } from 'src/app/components/_core/bh-content-search/bh-content-search.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BhContentSearchComponentModule,
    PopoverAssignmentsPageRoutingModule,
    BhSpinnerComponentModule
  ],
  declarations: [PopoverAssignmentsPage]
})
export class PopoverAssignmentsPageModule {}
