import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ReportsService } from './../../services/reports/reports.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SelectOption } from 'src/app/models/_core/select-option';
import { Report, ReportParameter } from 'src/app/models/report';
import { Roster } from 'src/app/models/roster';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { error } from '@angular/compiler/src/util';
import * as moment from 'moment';
import { RosterReportsRunPage } from '../roster-reports-run/roster-reports-run.page';
import { User } from 'src/app/models/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-roster-reports-edit',
  templateUrl: './roster-reports-edit.page.html',
  styleUrls: ['./roster-reports-edit.page.scss'],
})
export class RosterReportsEditPage implements OnInit, OnDestroy {
  @Input() roster: Roster;
  @Input() report: Report;
  @Input() editMode: 'new' | 'edit' = 'edit';
  @Input() authUserPermission: "READONLY" | "EDITOR" | "OWNER" = "READONLY";
  user: User;
  disablePropertiesForm = true;
  protectQueries = true;

  // Define form
  form1: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    comment: [null],
    active: [null, Validators.required],
    qryProtect: [null]
  });

  form2: FormGroup = this.formBuilder.group({
    fromDate: [null, Validators.required],
    thruDate: [null, Validators.required],
    comment: [null],
    groupBy: [null],
    sort1: [null],
    sort2: [null],
  });

  // Define validation messages
  validationMessages = {
    name: [{ type: "required", message: "Report name is required." }],
    fromDate: [{ type: "required", message: "From Date is required."}],
    thruDate: [{ type: "required", message: "Thru Date is required."}],
  };

  // Submission State
  submitAttempted = false;

  subs: Subscription[] = [];

  // Active Options
  activeOptions: SelectOption[] = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  groupOptions: SelectOption[] = [
    {
      label: 'Schedule Date',
      value: 'startDate',
    },
    {
      label: 'Team Member',
      value: 'name',
    },
    {
      label: 'User ID',
      value: 'userId',
    },
    {
      label: 'Shift',
      value: 'shiftPeriod',
    },
    {
      label: 'Role Group',
      value: 'rgpName',
    },
    {
      label: 'Role',
      value: 'rolName',
    },
    {
      label: 'Assignment',
      value: 'rasName',
    },
  ];


  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private authService: AuthService,
    private reportsService: ReportsService
  ) { }

  ngOnInit() {
    console.log('ngOnInit', this.roster, this.report);
    this.user = this.authService.getAuthUser();
    this.disablePropertiesForm = (this.authUserPermission !== 'OWNER' && this.user.role !== 'SYS_ADMIN');
    console.log('ngOnInit => user', this.user);
    this.subscribeToControls();
    this.loadReport();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
      sub = null;
    })
  }

  subscribeToControls() {
    this.subs.push(
      this.form1.controls.qryProtect.valueChanges.subscribe(val => {
        this.protectQueries = val;
      })
    )
  }

  async loadReport() {
    try {
      if (this.editMode === 'edit') {
        const res = await this.reportsService.getReportBySeq(this.report.rosSeq, this.report.repSeq).toPromise();
        if (res && res.reports) {
          this.report = res.reports[0];  
          if (this.report !== undefined) {
            this.form1.controls.name.setValue(this.report.name);
            this.form1.controls.comment.setValue(this.report.comment);
            this.form1.controls.active.setValue(this.report.active);
            this.form1.controls.qryProtect.setValue(this.report.qryProtect === 1);
            this.form2.controls.groupBy.setValue(this.report.groupBy);
            this.form2.controls.sort1.setValue(this.report.sort1);
            this.form2.controls.sort2.setValue(this.report.sort2);

            const nowMoment = moment();
            const currentMonth = nowMoment.format('M');
            const currentYear = nowMoment.format('YYYY');
            const startDateMoment = moment(currentMonth + '/1/' + currentYear, 'M/D/YYYY');
            const endDateMoment = (moment(startDateMoment.format('MM/DD/YYYY'), 'MM/DD/YYYY').add(1, 'months')).add(-1, 'days');
            this.form2.controls.fromDate.setValue(startDateMoment.toISOString());
            this.form2.controls.thruDate.setValue(endDateMoment.toISOString());
        
          }
        } else {
          throw error('Report record not found.');
        }
      } else {
        this.report = {};
        this.form1.controls.active.setValue(1);
        this.form2.controls.sort1.setValue('startDate');
        this.form2.controls.sort2.setValue('shiftPeriod');
      }
    } catch (err) {
      this.notificationService.handleError(err, 'rosterReportsEdit.loadReport');
      this.dismiss();
    }
  }

  setReportParams(params) {
    this.report.reportParms = params;
    console.log('setReportParams', params);
  }

  dismiss() {
    this.analytics.clickEvent("rosterReportsEdit: dismiss", "");
    this.modalCtrl.dismiss();
  }

  async save() {
    if (!this.disablePropertiesForm && this.form1.valid) {
      try {
        this.report.rosSeq = this.roster.rosSeq;
        this.report.name = this.form1.controls.name.value;
        this.report.comment = this.form1.controls.comment.value;
        this.report.active = this.form1.controls.active.value;
        this.report.qryProtect = this.form1.controls.qryProtect.value ? 1 : 0;
        this.report.groupBy = this.form2.controls.groupBy.value;
        this.report.sort1 = this.form2.controls.sort1.value;
        this.report.sort2 = this.form2.controls.sort2.value;

        if (this.editMode === 'new') {
          const res = await this.reportsService.createReport(this.report).toPromise();
          if (res) {
            this.report.repSeq = res.iRepSeq;
            this.editMode = 'edit';
            this.notificationService.showToast('Report successfully saved');
            this.loadReport();
          }
        } else {
          await this.reportsService.updateReport(this.report).toPromise();
          this.notificationService.showToast('Report successfully saved');
          // this.modalCtrl.dismiss({ isSaved: true });
        }
      } catch (err) {
        this.notificationService.handleError(err, "saveReport()");
      }
    } else {
      console.log('Form invalid', this.form1);
    }
  }

  async runReport() {
    if (this.form2.valid) {
      try {
        // await this.reportsService.updateReport(this.report).toPromise();
        const fromDate = moment(this.form2.controls.fromDate.value).format('MM/DD/YYYY').toString();
        const thruDate = moment(this.form2.controls.thruDate.value).format('MM/DD/YYYY').toString();
        this.analytics.clickEvent("open-run-report", "clicked");
        this.report.qryFromDate = fromDate;
        this.report.qryThruDate = thruDate;
        this.report.qryComment = this.form2.controls.comment.value;
        this.report.groupBy = this.form2.controls.groupBy.value;
        this.report.sort1 = this.form2.controls.sort1.value;
        this.report.sort2 = this.form2.controls.sort2.value;
        const modal = await this.modalCtrl.create({
          component: RosterReportsRunPage,
          cssClass: "custom-modal floating",
          backdropDismiss: false,
          componentProps: {
            roster: this.roster,
            report: this.report
          }
        });
        modal.present();
    
      } catch (err) {
        this.notificationService.handleError(err, "runReport()");
      }
    } else {
      console.log('Form invalid', this.form2);
    }
  }


}
