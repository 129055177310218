import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { Role } from "src/app/models/role";
import { RoleGroup } from "src/app/models/role-group";
import { Roster } from "src/app/models/roster";
import { Shift } from "src/app/models/shift";
import { SelectOption } from "src/app/models/_core/select-option";
import { RoleGroupsService } from "src/app/services/role-groups/role-groups.service";
import { RoleService } from "src/app/services/role/role.service";
import { ShiftsService } from "src/app/services/shifts/shifts.service";
import { AuthService } from "src/app/services/_core/auth/auth.service";
import { BhAnalyticsService } from "src/app/services/_core/bhanalytics/bhanalytics.service";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-manage-shifts-edit",
  templateUrl: "./manage-shifts-edit.page.html",
  styleUrls: ["./manage-shifts-edit.page.scss"],
})
export class ManageShiftsEditPage implements OnInit {

  env = environment;

  @Input() roster: Roster;
  @Input() componentLabel: string;
  @Input() shift: Shift;
  @Input() shifts: Shift[] = [];
  @Input() editMode: 'new' | 'edit' = 'edit';
  date: string;
  roleGroups: RoleGroup[] = [];
  shfSeq: number;
  subscriptions: Subscription[] = [];
  rosters: Roster;
  postDate: string;
  stopDate: string;
  // roster: Roster = {
  //   name: "BMC Emergency Department",
  // };

  // Define form
  form1: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    startTime: [null, Validators.required],
    endTime: [null, Validators.required],
    postDate: [null, Validators.required],
    stopDate: [null, Validators.required],
    active: [null, Validators.required],
    ebDisplayMin: [null, Validators.required],
    showReport: [null],
    
  });

  // Define validation messages
  validationMessages = {
    rosterName: [{ type: "optional" }],
    name: [{ type: "required", message: "A shift name is required." }],
    startTime: [{ type: "required", message: "Start Time is required." }],
    postDate: [{ type: "optional" }],
    stopDate: [{ type: "optional" }],
    active: [{ type: "optional" }],
    ebDisplayMin: [{ type: "optional" }],
    showReport: [{ type: "optional" }],
  };
  // Submission State
  submitAttempted = false;

  // View only State
  viewOnly = true;

  shiftSelection: SelectOption[] = [];

  setActive: SelectOption[] = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];
  reportSelections: SelectOption[] = [
    {
      label: "Do not display",
      value: 0
    },
    {
      label: this.env.chargeReportQuestionConfig.header,
      value: this.env.chargeReportQuestionConfig.frmSeq,
    },
  ];

  nowTimestamp = moment().format("M/D/YYYY");
  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private authService: AuthService,
    private shiftService: ShiftsService
  ) { }
  ngOnInit() { }

  ionViewWillEnter() {
    this.setShiftOptions();
    this.loadData();
  }

  setShiftOptions() {
    this.shiftSelection = [];
    const shifts: SelectOption[] = [];
    for (let h = 0; h < 24; h++) {
      const hourTime = h.toString().length === 1 ? '0' + h.toString() : h.toString();
      for (let m = 0; m < 60; m+=5) {
        const minuteTime = m.toString().length === 1 ? '0' + m.toString() : m.toString();
        const timeValue = `${hourTime}:${minuteTime}`;
        const momentTime = moment(timeValue, 'H:mm')
        const label = momentTime.format('h:mm a');
        shifts.push({ label, value: timeValue });
      }
    }
    this.shiftSelection = shifts;
  }

  async loadData() {
    console.log("current selected shift", this.shift);
    // await this.loadCurrentShifts();
    // this.getSingleShift(this.shift);
    try {
      if (this.editMode === 'edit') {
        const res = await this.shiftService.getSingleShift(this.roster.rosSeq, this.shift.shfSeq).toPromise();
        this.shift = res.shifts[0];  
        if (this.shift !== undefined) {
          this.form1.controls.name.setValue(this.shift.name);
          this.form1.controls.startTime.setValue(this.shift.startTime);
          this.form1.controls.endTime.setValue(this.shift.endTime);
          this.form1.controls.postDate.setValue(moment(this.shift.postDate).toISOString());
          console.log('stop date', this.shift.stopDate);
          this.form1.controls.stopDate.setValue(moment(this.shift.stopDate).toISOString());
          this.form1.controls.active.setValue(this.shift.active);
          this.form1.controls.ebDisplayMin.setValue(this.shift.ebDisplayMin);
          this.form1.controls.showReport.setValue(this.shift.showReport);
        }
      } else {
        this.shift = {};
      }
    } catch (err) {
      this.notificationService.handleError(err, 'loadForm.getSingleShift');
    }
  }

  async getSingleShift(shift: Shift) {
    const singleShift = this.shifts.find(shf => shf.shfSeq === shift.shfSeq);
    this.shift = singleShift;
    console.log('editShift.shift >> ', this.shifts, singleShift, this.shift);
  }

  async loadCurrentShifts(): Promise<boolean> {
    let currentMoment = moment();
    try {
      const res = await this.shiftService
        .getAllShifts(this.roster.rosSeq, 1)
        .toPromise();
      if (res) {
        this.shifts = res.shifts.filter((shift) => {
          const postDateMoment = moment(
            shift.postDate + " 00:00:00",
            "MM/DD/YYYY HH:mm:ss"
          );
          const stopDateMoment = shift.stopDate
            ? moment(shift.stopDate + " 00:00:00", "MM/DD/YYYY HH:mm:ss")
            : null;
          const startTimeMoment = moment(
            currentMoment.format("MM/DD/YYYY") + " " + shift.startTime + ":00",
            "MM/DD/YYYY HH:mm:ss"
          );
          const endTimeMoment = moment(
            currentMoment.format("MM/DD/YYYY") + " " + shift.endTime + ":59",
            "MM/DD/YYYY HH:mm:ss"
          );
          const isLive =
            postDateMoment <= currentMoment &&
            (!stopDateMoment || stopDateMoment >= currentMoment);
          return isLive;
        });
        this.shifts.forEach((shift) => {
          const startTime = moment(shift.startTime, "hh:mm a").format(
            "hh:mm a"
          );
          const endTime = moment(shift.endTime, "hh:mm a").format("hh:mm a");
          const shiftStatus = this.getShiftStatus(shift);
          const shiftTime = startTime + " - " + endTime + shiftStatus;
          this.shiftSelection.push({ label: shiftTime, value: shift.shfSeq });
          this.shiftSelection = this.shiftSelection.filter(
            (ss) => Object.keys(ss).length !== 0
          );
        });
        //console.log("all shifts without end dates", this.shifts);
        return Promise.resolve(true);
      }
    } catch (err) {
      this.notificationService.handleError(err, "loadShifts()");
    }
  }

  getShiftStatus(shift: Shift) {
    let currentMoment = moment();
    const postDateMoment = moment(
      shift.postDate + " 00:00:00",
      "MM/DD/YYYY HH:mm:ss"
    );
    const stopDateMoment = shift.stopDate
      ? moment(shift.stopDate + " 00:00:00", "MM/DD/YYYY HH:mm:ss")
      : null;
    const isLive =
      postDateMoment <= currentMoment &&
      (!stopDateMoment || stopDateMoment >= currentMoment);
    if (!shift.active) {
      return " (inactive)";
    } else if (!isLive) {
      return " (expired)";
    } else {
      return " (active)";
    }
  }

  dismiss() {
    this.analytics.clickEvent("feedback: dismiss", "");
    this.modalCtrl.dismiss();
  }

  async save() {
    if (this.form1.valid) {
      try {
        const postDate = moment(this.form1.controls.postDate.value).format('MM/DD/YYYY').toString();
        const stopDate = moment(this.form1.controls.stopDate.value).format('MM/DD/YYYY').toString();
        this.shift.rosSeq = this.roster.rosSeq;
        this.shift.name = this.form1.controls.name.value;
        this.shift.active = this.form1.controls.active.value;
        this.shift.startTime = this.form1.controls.startTime.value;
        this.shift.endTime = this.form1.controls.endTime.value;
        this.shift.showReport = this.form1.controls.showReport.value;
        //this.shift.postDate = this.form1.controls.postDate.value;
        //this.shift.stopDate = this.form1.controls.stopDate.value;
        this.shift.postDate = postDate;
        this.shift.stopDate = stopDate;
        this.shift.ebDisplayMin = this.form1.controls.ebDisplayMin.value;
        console.log('update shift', this.shift);
        //this.shift.startDateTime = startDate + ' ' + this.form1.controls.startTime.value;
        //this.shift.endDateTime = endDate + ' ' + this.form1.controls.endTime.value;
        if (this.editMode === 'edit') {
          await this.shiftService.createShift(this.roster.rosSeq, this.shift).toPromise();
        } else {
          await this.shiftService.updateShift(this.roster.rosSeq, this.shift.shfSeq, this.shift).toPromise();
        }
        this.modalCtrl.dismiss({ isSaved: true });
      } catch (err) {
        this.notificationService.handleError(err, "saveUserAssignment()");
      }
    } else {
      console.log('Form invalid', this.form1);
    }
  }

  getReportForms() {

  }

  saveAndExit() {
    this.modalCtrl.dismiss();
  }

  cancel() {
    this.analytics.clickEvent("feedback: cancel", "");
    this.modalCtrl.dismiss();
  }
}
