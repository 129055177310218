import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BhGridColumnDefinition } from 'src/app/models/_core/bh-grid-column-definition';
import { Report } from 'src/app/models/report';
import { Roster } from 'src/app/models/roster';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { RosterReportsEditPage } from '../roster-reports-edit/roster-reports-edit.page';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-roster-reports',
  templateUrl: './roster-reports.page.html',
  styleUrls: ['./roster-reports.page.scss'],
})
export class RosterReportsPage implements OnInit {
  @Input() roster: Roster;
  @Input() authUserPermission: "READONLY" | "EDITOR" | "OWNER" = "READONLY";
  reports: Report[] = [];
  initReports: Report[] = [];
  isFiltering = false;
  searchTerm = '';
  authUser: User;

  columnDefinitions: BhGridColumnDefinition[] = [
    {
      fieldName: 'name', columnLabel: 'Report', ionIcon: 'document-outline', ionIconColor: 'primary', showIconArg: '',
      showIconOnly: false, alwaysShowIcon: true, showColumn: true
    },
    {
      fieldName: 'comment', columnLabel: 'Description', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'active', columnLabel: 'Active', ionIcon: '', ionIconColor: null, showIconArg: '',
      showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'addBy', columnLabel: 'Created by', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'addDate', columnLabel: 'Created Date', ionIcon: '', ionIconColor: null, showIconArg: '',
      showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'repSeq', columnLabel: 'ID', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'rosSeq', columnLabel: 'RosSeq', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: false
    },
    {
      fieldName: 'reportParms', columnLabel: 'Params', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: false
    },

  ];

  constructor(
    private reportService: ReportsService,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loadReports(this.roster.rosSeq);
    this.authUser = this.authService.getAuthUser();
  }

  async loadReports(rosSeq): Promise<boolean> {
    try {
      let res = await this.reportService.getReportsAll(rosSeq, 0).toPromise();
      console.log('response', res);
      if (res) {
        this.reports = res.reports;
        this.initReports = Object.assign([], this.reports);
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notifications.handleError(err, 'loadReports()');
    }
  }

  async addReport() {
    this.analytics.clickEvent("open-add-report", "clicked");
    const modal = await this.modalCtrl.create({
      component: RosterReportsEditPage,
      cssClass: "wide-modal hide-from-print",
      backdropDismiss: false,
      componentProps: {
        roster: this.roster,
        editMode: 'new',
        authUserPermission: this.authUserPermission
      }
    });
    modal.onDidDismiss().then((data) => {
      this.loadReports(this.roster.rosSeq);
    });
    modal.present();
  }

  async editReport(report: Report) {
    this.analytics.clickEvent("edit-report", "clicked");
    const modal = await this.modalCtrl.create({
      component: RosterReportsEditPage,
      cssClass: "wide-modal hide-from-print",
      backdropDismiss: false,
      componentProps: {
        roster: this.roster,
        report,
        editMode: 'edit',
        authUserPermission: this.authUserPermission
      },
    });
    modal.onDidDismiss().then((data) => {
      this.loadReports(this.roster.rosSeq);
    });
    modal.present();
  }

  dismiss() {
    this.analytics.clickEvent('Reports: dismiss', '');
    this.modalCtrl.dismiss();
  }

  filter(ev) {
    if (ev) {
      this.isFiltering = true;
      const term = ev.toLowerCase().trim();
      this.reports = this.reports.filter(rep =>
        rep.name.toLowerCase().indexOf(term) > -1 ||
        rep.repSeq.toString().indexOf(term) > -1 ||
        rep.comment.toLowerCase().indexOf(term)
      );
    } else {
      this.searchTerm = '';
      this.isFiltering = false;
      this.reports = Object.assign([], this.initReports);
    }

  }


}
