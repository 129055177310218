import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/models/role';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { Shift } from 'src/app/models/shift';
import { SelectOption } from 'src/app/models/_core/select-option';
import { RoleGroupsService } from 'src/app/services/role-groups/role-groups.service';
import { RoleService } from 'src/app/services/role/role.service';
import { ShiftsService } from 'src/app/services/shifts/shifts.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';

@Component({
  selector: 'app-edit-role-group',
  templateUrl: './edit-role-group.page.html',
  styleUrls: ['./edit-role-group.page.scss'],
})
export class EditRoleGroupPage implements OnInit {
  @Input() rolSeq: number;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  //@Input() shfSeq: number;
  @Input() shift: Shift;
  @Input() roleGroup: RoleGroup;
  @Input() componentLabel: string;
  @Input() shfSeq: number;
  @Input() roster: Roster;
  //date: string;
  //shifts: Shift[] = [];
  //shift: Shift;
  //roleGroups: RoleGroup[] = [];
  //shfSeq: number;
  subscriptions: Subscription[] = [];

  // roster: Roster = {
  //   name: 'BMC Emergency Department'
  // };

  shiftObj: Shift = {
    name: '',
    startTime: '',
    endTime: '',
    postDate: '',
    stopDate: '',
    active: 1
  };

  roleGroupObj: RoleGroup = {
    active: 1
  }

  roleObj: Role = {
    name: '',
    rgpSeq: 1,
    active: 1
  }
  // Define form
  form1: FormGroup = this.formBuilder.group({
    roleGroup: [null, Validators.required],
    active: [null, Validators.required],
  });

  // Define validation messages
  validationMessages = {
    roleGroup: [{ type: "required", message: "A role group name is required." }],
    active: [{ type: 'optional' }]
  };
  // Submission State
  submitAttempted = false;

  // View only State
  viewOnly = true;

  // Options for Active Select
  RoleGroupSelection: SelectOption[] = [];
  shiftSelection: SelectOption[] = [{}];

  setActive: SelectOption[] = [
    {
      label: 'Yes',
      value: 1
    },
    {
      label: 'No',
      value: 0
    }
  ];

  nowTimestamp = moment().format('M/D/YYYY');
  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private roleService: RoleService,
    private roleGroupService: RoleGroupsService,
    private shiftService: ShiftsService
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.loadForm();
  }

  async loadForm() {
    await this.getCurrentShift();
    this.form1.controls.roleGroup.setValue(this.roleGroup.name);
    this.form1.controls.active.setValue(this.roleGroup.active);
  }

  async getCurrentShift(): Promise<boolean> {
    try {
      const res = await this.shiftService.getSingleShift(this.rosSeq, this.shfSeq).toPromise();
      if (res) {
        this.shift = res.shifts[0];
        console.log('current shifts', this.shift);
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, 'getCurrentShift()')
    }
  }

  dismiss() {
    this.analytics.clickEvent('feedback: dismiss', '');
    this.modalCtrl.dismiss();
  }

  async updateExistingRoleGroup() {
    if (this.form1.valid) {
      try {
        this.roleGroup.name = this.form1.controls.roleGroup.value;
        this.roleGroup.active = this.form1.controls.active.value;
        const res = await this.roleGroupService
          .updateRoleGroup(this.rosSeq, this.shift.shfSeq, this.roleGroup.rgpSeq, this.roleGroup)
          .toPromise();
        console.log("updated new role group", res);
        this.modalCtrl.dismiss({ isUpdated: true });
      } catch (err) {
        this.notificationService.handleError(err, "updateExistingRoleGroup()");
      }
    } else {
      console.log('Form invalid', this.form1);
    }
  }

  saveAndExit() {
    this.modalCtrl.dismiss();
  }

  cancel() {
    this.analytics.clickEvent('feedback: cancel', '');
    this.modalCtrl.dismiss();
  }

}
