import { AdUser } from "../../models/ad-user";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IonSelect, IonTextarea, ModalController } from "@ionic/angular";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { Role } from "src/app/models/role";
import { RoleAssignment } from "src/app/models/role-assignment";
import { RoleGroup } from "src/app/models/role-group";
import { Roster } from "src/app/models/roster";
import { DutyComment, SchRoleAssignmentUser } from "src/app/models/sched-role-assign-user";
import { SchRoleAssignment } from "src/app/models/sched-role-assignment";
import { Shift } from "src/app/models/shift";
import { SelectOption } from "src/app/models/_core/select-option";
import { RoleAssignmentService } from "src/app/services/role-assignment/role-assignment.service";
import { RoleGroupsService } from "src/app/services/role-groups/role-groups.service";
import { RoleService } from "src/app/services/role/role.service";
import { RosterService } from "src/app/services/roster/roster.service";
import { SchedRoleAssignUserService } from "src/app/services/sched-role-assign-user/sched-role-assign-user.service";
import { SchedRoleAssignmentsService } from "src/app/services/sched-role-assignments/sched-role-assignments.service";
import { ShiftsService } from "src/app/services/shifts/shifts.service";
import { UsersService } from "src/app/services/users/users.service";
import { BhAnalyticsService } from "src/app/services/_core/bhanalytics/bhanalytics.service";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";
@Component({
  selector: "app-edit-employee",
  templateUrl: "./assign-team-member-edit.page.html",
  styleUrls: ["./assign-team-member-edit.page.scss"],
})
export class AssignTeamMemberEditPage implements OnInit {
  @Input() rolSeq: number;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  @Input() shift: Shift;
  @Input() roleGroup: RoleGroup;
  @Input() role: Role;
  @Input() sru: SchRoleAssignmentUser;
  @Input() assignment: SchRoleAssignment;
  @Input() schedAssignment: SchRoleAssignment;
  @Input() authUserPermission;

  // adUser: AdUser = null;
  subscriptions: Subscription[] = [];
  adUser: AdUser;
  shifts: Shift[] = [];
  roleGroups: RoleGroup[] = [];
  roles: Role[] = [];
  roleAssignments: RoleAssignment[] = [];
  rgpSeq: number;
  shfSeq: number;
  currentView: "form" | "loading" = "loading";
  initAssignments: RoleAssignment[];
  initSchAssignments: SchRoleAssignment[] = [];
  initSchAssignedUsers: SchRoleAssignmentUser[] = [];
  schedAssignments: SchRoleAssignment[] = [];
  assignmentInput = "";
  dutyInput = "";
  showAddAssignment = false;
  isDuplicate = false;
  isFiltering = false;
  selectedAssignment: RoleAssignment = null;
  roleLabel = "";
  assignmentLabel = "";
  currUsersAssignments: SchRoleAssignment[] = [];
  remainingCharacters: number;
  initialCharacters: number = 50;
  dutyComments: DutyComment[] = [];
  dutyCommentSeqs: string[] = [];
  filteredDutyComments: DutyComment[] = [];
  initDutyComments: DutyComment[] = [];
  showAddDuty = false;
  showEndTime = false; 
  isOrientation: number;

  roster: Roster = {
    name: "BMC Emergency Department",
  };

  shiftObj: Shift = {
    name: "",
    startTime: "",
    endTime: "",
    postDate: "",
    stopDate: "",
    active: 1,
  };

  roleGroupObj: RoleGroup = {
    rgpSeq: 1,
    shfSeq: 1,
    name: "",
    active: 1,
  };

  roleObj: Role = {
    name: "",
    rgpSeq: 1,
    active: 1,
  };
  // Define form
  form1: FormGroup = this.formBuilder.group({
    rosterName: [null],
    shift: [null, Validators.required],
    roleGroup: [null, Validators.required],
    role: [null, Validators.required],
    assignment: [null],
    startTime: [null, Validators.required],
    endTime: [null, Validators.required],
    shiftEndDate: [null],
    comments: ['',
      [
        Validators.maxLength(49),
      ],
    ],
    saveDutyComment: [null]
  });

  // Define validation messages
  validationMessages = {
    rosterName: [{ type: "optional" }],
    role: [{ type: "required", message: "A role name is required." }],
    name: [{ type: "required", message: "A shift name is required." }],
    shift: [{ type: "required", message: "A shift name is required." }],
    roleGroup: [
      { type: "required", message: "A role group name is required." },
    ],
    startTime: [{ type: "required", message: "Start Time is required." }],
    comments: [
      { type: "maxlength", message: "Reached character limit for comment." },
    ],
    saveDutyComment: [{ type: "optional" }]
  };
  // Submission State
  submitAttempted = false;

  // View only State
  viewOnly = true;

  get comments(): AbstractControl {
    return this.form1.get('comments');
  }

  errorMessages = {
    comments: [
      { type: 'maxlength', message: 'Reached character limit for comment.' }
    ]
  };


  // Options for Active Select
  roleGroupSelection: SelectOption[] = [{}];
  roleSelection: SelectOption[] = [{}];
  shiftSelection: SelectOption[] = [{}];
  assignmentSelection: SelectOption[] = [{}];
  setActive: SelectOption[] = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  assignmentTimeSelection: SelectOption[] = [{}];

  endShiftTimeSelection: SelectOption[] = [{}];

  nowTimestamp = moment().format("M/D/YYYY");

  @ViewChild('textbox', { static: false}) textAreaRef: ElementRef<HTMLTextAreaElement>;
  @ViewChild('dutySelect', { static: false }) dutySelect: IonSelect;
  @ViewChild('dutySelection', { static: false }) dutySelection: ElementRef;

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private roleService: RoleService,
    private userService: UsersService,
    private shiftService: ShiftsService,
    private rosterService: RosterService,
    private roleGroupService: RoleGroupsService,
    private schedAssignmentsService: SchedRoleAssignmentsService,
    private roleAssignmentsService: RoleAssignmentService,
    private schedAssignUserService: SchedRoleAssignUserService
  ) {}

  ngOnInit() {}

  async ionViewWillEnter() {
    console.log("assignment", this.assignment);
    console.log("user", this.sru);
    this.roleLabel = this.role.name;
    this.initSchAssignments = Object.assign([], this.role.schRoleAssignments);
    console.log("initSchAssignments", this.initSchAssignments);
    this.initSchAssignedUsers = Object.assign([], this.role.schRoleAssignUsers);

    console.log("initSchAssignedUsers", this.initSchAssignedUsers);
    this.assignmentLabel = this.role.name;
    await this.loadRoleAssignments();
    await this.getAllShifts(this.rosSeq);
    await this.getRoleGroup(this.shift.shfSeq);
    await this.getRoles(this.roleGroup.rgpSeq);
    await this.getEmployeeInfo(this.sru);
    await this.convertShiftTimes(this.shift);
    await this.loadDutyComments();
    this.getCurrUsersAssignments();
    this.subscribeToControls();
    this.currentView = "form";
    // this.loadForm();
    //console.log('shifts', this.shift);
    this.setShiftOptions();
    console.log('sru', this.sru.shiftEndDate);
    //console.log('employee', this.employee);
    //console.log('adUser', this.employee.adUser);
    // this.loadShifts();
    // this.loadRoleGroups();
  }

  ionViewDidLeave() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
      s = null;
    });
  }

  async loadForm(asi: RoleAssignment) {
    //await this.getAssignments(this.role.rolSeq);
    // const startTime = moment(asi.sruStartDateTime, "MM/DD/YYYY hh:mm a")
    //   .format("h:mm a");
    // const endTime = moment(asi.sruEndDateTime, "MM/DD/YYYY hh:mm a")
    //   .format("h:mm a");
    const startTime = asi.sruStartDateTime
      ? moment(asi.sruStartDateTime, "MM/DD/YYYY hh:mm a").format("h:mm a")
      : moment(
          this.schedDate + " " + this.shift.startTime,
          "MM/DD/YYYY HH:mm"
        ).format("h:mm a");
    const endTime = asi.sruEndDateTime
      ? moment(asi.sruEndDateTime, "MM/DD/YYYY hh:mm a").format("h:mm a")
      : moment(
          this.schedDate + " " + this.shift.endTime,
          "MM/DD/YYYY HH:mm"
        ).format("h:mm a");

    console.log("loadForm: startTime: ", startTime);
    console.log("loadForm: endTime: ", endTime);

    this.form1.controls.shift.setValue(this.shift.shfSeq);
    this.form1.controls.roleGroup.setValue(this.roleGroup.rgpSeq);
    this.form1.controls.role.setValue(this.role.rolSeq);
    this.form1.controls.assignment.setValue(asi.rasSeq);
    this.form1.controls.startTime.setValue(startTime);
    this.form1.controls.endTime.setValue(endTime);
    this.form1.controls.shiftEndDate.setValue(this.sru.shiftEndDate);
    this.form1.controls.comments.setValue(asi.sruComment);
  }

  subscribeToControls() {
    if (this.subscriptions.length === 0) {
    this.remainingCharacters = this.initialCharacters;
    this.subscriptions.push(
      this.form1.controls.startTime.valueChanges.subscribe((val) => {
        if (val) {
          // Calculate new start time
          const startDate = moment(
            this.sru.startDateTime,
            "MM/DD/YYYY hh:mm a"
          ).format("MM/DD/YYYY");
          const startDateTime = startDate + " " + val;
          this.selectedAssignment.sruStartDateTime = startDateTime;
        }
      }),
      this.form1.controls.endTime.valueChanges.subscribe((val) => {
        if (val) {
          // Calculate new end time
          const endDate = moment(
            this.sru.endDateTime,
            "MM/DD/YYYY hh:mm a"
          ).format("MM/DD/YYYY");
          const endDateTime = endDate + " " + val;
          this.selectedAssignment.sruEndDateTime = endDateTime;
        }
      }),
      this.form1.controls.comments.valueChanges.subscribe((val) => {
        console.log('val on load', val);
        this.selectedAssignment.sruComment = val;
      }),
      this.form1.controls.shiftEndDate.valueChanges.subscribe((val) => {
        console.log('val of shift end time', val);
      }),
      this.form1.controls.comments.valueChanges.subscribe((val) => {
        console.log('val comments', val);
        this.remainingCharacters = this.initialCharacters - val.length;
      })
    );
    }
  }

  onChangeOrientee(event) {
    if(event.detail.checked) {
      this.isOrientation = 1;
    } else {
      this.isOrientation = 0;
    }
  }

  showEndTimeController() {
      this.showEndTime != this.showEndTime;
  }

  setShowAddDuty(show) {
    this.showAddDuty = show;
  }

  // setShiftOptions() {
  //   this.endShiftTimeSelection = [];
  //   const endTime = moment(this.shift.endTime, "hh:mm a").format("hh:mm a");
  //   const shiftTime = endTime;
  //   this.endShiftTimeSelection.push({ label: shiftTime, value: this.shift.shfSeq });
  //   this.endShiftTimeSelection = this.endShiftTimeSelection.filter(
  //     (ss) => Object.keys(ss).length !== 0
  //   );
  // }

  async loadDutyComments(): Promise<boolean> {
    try {
      const res = await this.schedAssignUserService
        .getAllDutyComments(this.rosSeq)
        .toPromise();
      if (res) {
        this.dutyComments = res.dutyComment;
        this.dutyComments = this.dutyComments.filter( dc => dc.active === 1);
        this.initDutyComments = Object.assign([], this.dutyComments);
        console.log("duty Comments", this.dutyComments);
        console.log("init sch", this.initSchAssignedUsers);
        // for (const dc of this.dutyComments) {
        //   if (dc.dcSeq) {
        //     this.dutyCommentSeqs.push(dc.dcSeq.toString()).toString;
        //   }
        // }
        console.log("duty comment Seq", this.dutyCommentSeqs);
        //let filteredDutyCommentSeq: string[] = [];
        //let filteredDutyComments: DutyComments[] = [];
        //let match: DutyComments;
        // for (const sru of this.initSchAssignedUsers) {
        //   filteredDutyCommentSeq = !Array.isArray(sru.dutyComments)
        //     ? this.dutyCommentSeqs.filter((dutyC) =>
        //         sru.dutyComments.toString().includes(dutyC)
        //       )
        //     : this.dutyCommentSeqs.filter((dutyC) =>
        //         sru.dutyComments.find((sru) => sru.dcSeq.toString() === dutyC)
        //       );
        // }

        // for (const sra of this.initSchAssignments) {
        //   for (const sru of sra.schRoleAssignUsers) {
        //     filteredDutyCommentSeq = !Array.isArray(sru.dutyComments)
        //       ? this.dutyCommentSeqs.filter((dutyC) =>
        //           sru.dutyComments.toString().includes(dutyC)
        //         )
        //       : this.dutyCommentSeqs.filter((dutyC) =>
        //           sru.dutyComments.find((sru) => sru.dcSeq.toString() === dutyC)
        //         );
        //   }
        // }

        // console.log("filteredDCSeq", filteredDutyCommentSeq);
        // filteredDutyComments = this.dutyComments.filter((dc) =>
        //   filteredDutyCommentSeq.find(
        //     (fdcSeq) => fdcSeq === dc.dcSeq.toString()
        //   )
        // );

        // for (const dc of this.dutyComments) {
        //   dc.isChecked = false;
        //   match = filteredDutyComments.find((fdc) => fdc.dcSeq === dc.dcSeq);
        //   if (match !== undefined) {
        //     dc.isChecked = true;
        //   }
        // }
        //let sruComments = this.initSchAssignedUsers[this.initSchAssignedUsers.length - 1].dutyComments !== undefined ? JSON.parse(JSON.parse(JSON.stringify(this.initSchAssignedUsers[this.initSchAssignedUsers.length - 1].dutyComments))) : ''

        //let sruComments = (this.initSchAssignedUsers[this.initSchAssignedUsers.length - 1].dutyComments);
        //console.log('sruComments', sruComments);

        //  for (const dc of this.dutyComments) {
        //       dc.isChecked = false;

        //       const match = (sruComments !== undefined && Array.isArray(sruComments)) ? sruComments.find(
        //         (dutyComment) =>
        //           (
        //             dutyComment.dcSeq === dc.dcSeq &&
        //             dutyComment.active === 1
        //           )) : null;

        //       if(match !== undefined) {
        //           dc.isChecked = true;
        //       }
        //   }
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, "loadRecentRoleUsers");
    }
  }

  onChange(value) {
    this.textAreaRef.nativeElement.value = this.textAreaRef.nativeElement.value.concat(' ', value);
    //this.textAreaRef.nativeElement.value = value;
    //console.log('text area', this.textAreaRef.nativeElement.value);
    //this.textAreaRef.nativeElement.value = this.textAreaRef.nativeElement.value.replace(/' '(?=[^\s])/g, ", ");
    this.form1.controls.comments.setValue(this.textAreaRef.nativeElement.value);
    this.dutySelection.nativeElement.value = '';
  }

  getCurrUsersAssignments() {
    for (const asi of this.initSchAssignments) {
      if(asi.schRoleAssignUsers.length > 0) {
        for (const usr of asi.schRoleAssignUsers) {
          if (usr.userId === this.sru.userId) {
            this.currUsersAssignments.push(asi);
          }
        }
      }
    }

    // Set default assignment
    const defaultAssignment = this.roleAssignments.find(
      (ra) => ra.rasSeq === this.sru.rasSeq
    );
    if (defaultAssignment) {
      this.selectAssignment(defaultAssignment);
    }
  }

  async loadRoleAssignments(): Promise<boolean> {
    try {
      const res = await this.roleAssignmentsService
        .getAllRoleAssignments(this.rosSeq, this.role.rolSeq)
        .toPromise();
      if (res && res.assignments) {
        this.roleAssignments = res.assignments;
        for (const ra of this.roleAssignments) {
          ra.isChecked = false;
          const match = this.role.schRoleAssignments.find(
            (sra) =>
              (sra.rasSeq === ra.rasSeq &&
                ra.active === 1 &&
                sra.active === 1) ||
              (this.schedAssignment !== undefined &&
                this.schedAssignment &&
                this.schedAssignment.rasSeq === ra.rasSeq &&
                ra.active === 1)
          );
          ra.isChecked = false;
          // Get user assignment
          if (match !== undefined) {
            const sru = match.schRoleAssignUsers.find(
              (u) => u.userId === this.sru.userId && u.active === 1
            );
            if (sru !== undefined) {
              ra.isChecked = true;
              ra.sruComment = sru.comment;
              ra.sruStartDateTime = sru.startDateTime;
              ra.sruEndDateTime = sru.endDateTime;
              ra.sruSeq = sru.sruSeq;
              
            }
          }
        }
        console.log(
          "loadRoleAssignments: role assignments",
          this.roleAssignments
        );
      }
      // Check for user root role
      const rootSru = this.role.schRoleAssignUsers.find(
        (u) => u.userId === this.sru.userId && u.active === 1
      );
      console.log("loadRoleAssignments: **** rootSru", rootSru);

      // Inject root role assignment
      const rootRoleAssignment: RoleAssignment = {
        rasSeq: 0,
        name: `${this.role.name} (root)`,
        active: 1,
        isChecked: rootSru !== undefined,
        rolSeq: this.role.rolSeq,
        romSeq: 0,
        default: 0,
        sruSeq: rootSru !== undefined ? rootSru.sruSeq : 0,
        sruComment: rootSru !== undefined ? rootSru.comment : "",
        sruStartDateTime: rootSru !== undefined ? rootSru.startDateTime : "",
        sruEndDateTime: rootSru !== undefined ? rootSru.endDateTime : "",
      };
      this.roleAssignments.unshift(rootRoleAssignment);
      this.initAssignments = Object.assign([], this.roleAssignments);
      return Promise.resolve(true);
    } catch (err) {
      //this.notificationService.handleError(err, "loadRoleAssignments()");
      throw err;
    }
  }

  filter(ev) {
    console.log("filter: initAssignments", this.initAssignments);
    this.isDuplicate = false;
    if (ev) {
      this.isFiltering = true;
      const term = this.assignmentInput
        .replace(/\ /gi, "")
        .toUpperCase()
        .trim();
      this.roleAssignments = this.initAssignments.filter(
        (ras) =>
          ras.name.replace(/\ /gi, "").toUpperCase().trim().indexOf(term) >
            -1 && ras.active === 1
      );
    } else {
      this.assignmentInput = "";
      this.isFiltering = false;
      this.roleAssignments = Object.assign([], this.initAssignments);
    }
  }

  filterDuty(ev) {
    console.log(
      "filter: initAssignments",
      this.initDutyComments,
      "duty comment",
      this.dutyComments
    );
    this.isDuplicate = false;
    if (ev) {
      this.isFiltering = true;
      const term = this.dutyInput.replace(/\ /gi, "").toUpperCase().trim();
      this.dutyComments = this.initDutyComments.filter(
        (dc) =>
          dc.dutyComment
            .replace(/\ /gi, "")
            .toUpperCase()
            .trim()
            .indexOf(term) > -1
      );
    } else {
      this.assignmentInput = "";
      this.isFiltering = false;
      this.dutyComments = Object.assign([], this.initDutyComments);
    }
  }

  setShowAddAssignment(show) {
    this.showAddAssignment = show;
  }

  assignNow() {
    this.selectedAssignment.isChecked = true;
  }

  async addNewAssignment() {
    console.log("new assignment", this.assignmentInput);
    try {
      const existingAsi = this.initAssignments.find(
        (ra) => ra.name.trim() === this.assignmentInput.trim()
      );
      console.log("does it exist?", existingAsi);
      if (!existingAsi) {
        this.currentView = "loading";
        const newRoleAssignment: RoleAssignment = {
          rolSeq: this.rolSeq,
          romSeq: 1,
          name: this.assignmentInput,
          active: 1,
          default: 1,
          isChecked: false,
        };
        const res = await this.roleAssignmentsService
          .createRoleAssignment(this.rosSeq, this.rolSeq, newRoleAssignment)
          .toPromise();
        this.analytics.clickEvent("add-assignment", "added " + newRoleAssignment.name, this.rosSeq);
        console.log("created new RoleAssignment", res);
        this.assignmentInput = "";
        this.isFiltering = false;
        this.roleAssignments = this.initAssignments;
        newRoleAssignment.rasSeq = res.rasSeq;
        this.roleAssignments.push(newRoleAssignment);
        this.currentView = "form";
        // this.loadRoleAssignments();
        console.log("check out inside roleAssignments", this.roleAssignments);
      } else {
        console.log("Duplicate Role Assignment");
        if (existingAsi.active === 0) {
          this.currentView = "loading";
          existingAsi.active = 1;
          const res = await this.roleAssignmentsService
            .updateRoleAssignment(
              this.rosSeq,
              existingAsi.rolSeq,
              existingAsi.rasSeq,
              existingAsi
            )
            .toPromise();
          this.analytics.clickEvent("update-assignment", "added " + existingAsi.name, this.rosSeq);
          this.assignmentInput = "";
          this.isFiltering = false;
          this.roleAssignments = this.initAssignments;
          this.currentView = "form";
        } else {
          this.isDuplicate = true;
        }
        // this.assignmentInput = "";
      }
    } catch (err) {
      this.currentView = "form";
      this.notificationService.handleError(err, "addNewAssignment()");
    }
  }

  selectAssignment(assignment: RoleAssignment) {
    console.log("selectAssignment assignment", assignment);
    this.selectedAssignment = assignment;
    this.assignmentLabel = assignment.name;
    console.log("selectAssignment: role", this.role.name);
    this.loadForm(this.selectedAssignment);
  }

  async getEmployeeInfo(sru: SchRoleAssignmentUser): Promise<boolean> {
    try {
      console.log("getEmployeeInfo: sru: ", sru);
      const res = await this.userService.getADUserById(sru.userId).toPromise();
      console.log("getEmployeeInfo: getADUserById => res: ", res);
      if (res) {
        this.adUser =
          res.results && res.results.length > 0
            ? res.results[0]
            : { userId: sru.userId, displayName: sru.name };
      }
      console.log("getEmployeeInfo: adUser", this.adUser);
      return Promise.resolve(true);
    } catch {}
  }

  getShiftStatus(shift: Shift) {
    let currentMoment = moment();
    const postDateMoment = moment(
      shift.postDate + " 00:00:00",
      "MM/DD/YYYY HH:mm:ss"
    );
    const stopDateMoment = shift.stopDate
      ? moment(shift.stopDate + " 00:00:00", "MM/DD/YYYY HH:mm:ss")
      : null;
    const isLive =
      postDateMoment <= currentMoment &&
      (!stopDateMoment || stopDateMoment >= currentMoment);
    if (!shift.active) {
      return " (inactive)";
    } else if (!isLive) {
      return " (expired)";
    } else {
      return "";
    }
  }

  async setShiftOptions() {
    this.endShiftTimeSelection = [];
    const shifts: SelectOption[] = [];
    try {
      //let res = await this.shiftService.getAllShifts(this.rosSeq, 1).toPromise();
      if (this.shifts.length > 0) {
        this.shifts.sort((a, b) => {
          return a.startTime > b.startTime
            ? 1
            : a.startTime === b.startTime
            ? 0
            : -1;
        });

        this.shifts.forEach((shift) => {
          const startTime = moment(shift.startTime, "hh:mm a").format(
            "hh:mm a"
          );
          const endTime = moment(shift.endTime, "h:mm a").format("h:mm a");
          
          const shiftTime = endTime;
          this.endShiftTimeSelection.push({ label: shiftTime, value: shift.endTime });
          this.endShiftTimeSelection = this.endShiftTimeSelection.filter(
            (ss) => Object.keys(ss).length !== 0
          );
        });
        return Promise.resolve(true);
      }
    } catch (err) {
      this.notificationService.handleError(err, "getAllShifts()");
    }
    this.endShiftTimeSelection = this.shifts;
  }


  async getAllShifts(rosSeq): Promise<boolean> {
    try {
      let res = await this.shiftService.getAllShifts(rosSeq, 1).toPromise();
      if (res) {
        this.shifts = res.shifts;
        console.log("shifts", this.shifts);
        this.shifts.forEach((shift) => {
          const startTime = moment(shift.startTime, "hh:mm a").format(
            "hh:mm a"
          );
          const endTime = moment(shift.endTime, "hh:mm a").format("hh:mm a");
          const shiftStatus = this.getShiftStatus(shift);
          const shiftTime = startTime + " - " + endTime + shiftStatus;
          this.shiftSelection.push({ label: shiftTime, value: shift.shfSeq });
          this.shiftSelection = this.shiftSelection.filter(
            (ss) => Object.keys(ss).length !== 0
          );
        });
        //console.log('shift Seq', this.shiftSeq);
        return Promise.resolve(true);
      }
    } catch (err) {
      this.notificationService.handleError(err, "getAllShifts()");
    }
  }

  convertShiftTimes(shift: Shift): Promise<boolean> {
    let startTime = parseInt(shift.startTime);
    let endTime = parseInt(shift.endTime);
    let shiftTimes = [];
    let shiftTimeLabels = [];
    console.log("times before", startTime, endTime);
    const quarterHours = ["00", "15", "30", "45"];
    for (var i = startTime; i < endTime; i++) {
      for (var j = 0; j < 4; j++) {
        let time = i + ":" + quarterHours[j];
        shiftTimes.push(time);
      }
    }
    shiftTimes.unshift(shift.startTime);
    shiftTimes.push(shift.endTime);
    // shiftTimes.shift();

    // shiftTimes.forEach((st) => {
    //   shiftTimeLabels.push(moment(st, "h:mm a").format("h:mm a"));
    // });

    for (const st of shiftTimes) {
      shiftTimeLabels.push(moment(st, "h:mm a").format("h:mm a"));
    }

    for (const stl of shiftTimeLabels) {
      this.assignmentTimeSelection.push({ label: stl, value: stl });
      this.assignmentTimeSelection = this.assignmentTimeSelection.filter(
        (as) => Object.keys(as).length !== 0
      );
    }

    console.log("assignment time selection", this.assignmentTimeSelection);
    return Promise.resolve(true);
  }

  async getRoleGroup(shfSeq: number): Promise<boolean> {
    try {
      let res = await this.roleGroupService
        .getAllRoleGroups(this.rosSeq, shfSeq)
        .toPromise();
      if (res) {
        this.roleGroups = res.roleGroups;
        this.roleGroups.forEach((rg) => {
          this.roleGroupSelection.push({ label: rg.name, value: rg.rgpSeq });
          this.roleGroupSelection = this.roleGroupSelection.filter(
            (rgs) => Object.keys(rgs).length !== 0
          );
        });
      }
      console.log("roleGroups", this.roleGroups);
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, "getAllRoleGroups()");
      throw err;
    }
  }

  async getRoles(rgpSeq: number): Promise<boolean> {
    try {
      let res = await this.roleService
        .getAllRoles(this.rosSeq, this.shift.shfSeq, rgpSeq)
        .toPromise();
      if (res) {
        this.roles = res.roles;
        this.roles.forEach((r) => {
          this.roleSelection.push({ label: r.name, value: r.rolSeq });
          this.roleSelection = this.roleSelection.filter(
            (rs) => Object.keys(rs).length !== 0
          );
        });
      }
      console.log("roles", this.roles);
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, "getAllRoleGroups()");
    }
  }

  async getAssignments(roleSeq: number): Promise<boolean> {
    try {
      let res = await this.roleAssignmentsService
        .getAllRoleAssignments(this.rosSeq, roleSeq)
        .toPromise();
      if (res) {
        this.roleAssignments = res.assignments;
        console.log("role assignments", this.roleAssignments);
        this.assignmentSelection.push({ label: "(Root)", value: "0" });
        this.roleAssignments.forEach((ras) => {
          if (ras.active !== 0) {
            this.assignmentSelection.push({
              label: ras.name,
              value: ras.rasSeq,
            });
            this.assignmentSelection = this.assignmentSelection.filter(
              (ras) => Object.keys(ras).length !== 0
            );
          }
        });
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, "getAssignments()");
    }
  }

  async deleteDutyComment(duty: DutyComment) {
    try {
      duty.active = 0;
      const res = await this.schedAssignUserService
        .updateDutyComment(this.rosSeq, duty.dcSeq, duty)
        .toPromise();
      if (res) {
        console.log("duty comment deleted", res);
        this.dutyComments = res.dutyComment;
        this.initDutyComments = Object.assign([], this.dutyComments);
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, "loadRecentRoleUsers");
    }
  }

  dismiss() {
    this.analytics.clickEvent("feedback: dismiss", "");
    this.modalCtrl.dismiss();
  }

  async save() {
    const comment = this.form1.controls.comments.value;
    const orientee = this.isOrientation;
    console.log('comment', comment);
    
    const shiftEndDate = this.form1.controls.shiftEndDate.value;
    console.log('shift end time', shiftEndDate);
    
    this.notificationService.startLoading("Applying changes");
    try {
      const res = await this.schedAssignUserService.saveUserAssignments(
        this.rosSeq,
        this.schSeq,
        this.schedDate,
        this.shift,
        this.roleGroup,
        this.role,
        this.adUser,
        this.roleAssignments,
        this.initAssignments,
        this.schedAssignments,
        this.initSchAssignments,
        this.currentView,
        //this.dutyComments,
        //this.initDutyComments,
        shiftEndDate,
        comment,
        orientee
      );

      console.log("saved: Received result", res);
      
      if (res && res.dismiss) {
        this.analytics.clickEvent("add-assigned-user", "added " + this.adUser.fullName + ' to assignment ' + res.role.name + (res.schedAssignment !== null ? '/' + res.schedAssignment.name : ''), this.rosSeq);
        this.modalCtrl.dismiss(res);
      }
      this.notificationService.stopLoading();
    } catch (err) {
      this.notificationService.stopLoading();
      this.notificationService.handleError(err, "save2()");
    }
  }

  updateEmployeeInfo() {
    this.modalCtrl.dismiss();
  }

  async removeUserAssignment() {
    this.analytics.clickEvent("editEmployee: cancel", "");
    try {
      this.currentView = "loading";
      this.sru.active = 0;
      await this.schedAssignUserService
        .updateSchAssignmentUser(
          this.rosSeq,
          this.schSeq,
          this.sru.sruSeq,
          this.sru
        )
        .toPromise();
      this.modalCtrl.dismiss();
    } catch (err) {
      this.currentView = "form";
      this.notificationService.handleError(err, "removeUserAssignment()");
    }
    this.modalCtrl.dismiss();
  }
}
