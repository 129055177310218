import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EmployeeBoardUpdateRoleGroupsPageRoutingModule } from './employee-board-update-role-groups-routing.module';

import { EmployeeBoardUpdateRoleGroupsPage } from './employee-board-update-role-groups.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EmployeeBoardUpdateRoleGroupsPageRoutingModule
  ],
  declarations: [EmployeeBoardUpdateRoleGroupsPage]
})
export class EmployeeBoardUpdateRoleGroupsPageModule {}
