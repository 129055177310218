import { SummaryResolver } from "@angular/compiler";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { IonSelect, ModalController, PopoverController } from "@ionic/angular";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { AdUser } from "src/app/models/ad-user";
import { Role } from "src/app/models/role";
import { RoleAssignment } from "src/app/models/role-assignment";
import { RoleGroup } from "src/app/models/role-group";
import { DutyComment, SchRoleAssignmentUser } from "src/app/models/sched-role-assign-user";
import { SchRoleAssignment } from "src/app/models/sched-role-assignment";
import { Schedule } from "src/app/models/schedule";
import { Shift } from "src/app/models/shift";
import { SelectOption } from "src/app/models/_core/select-option";
import { RoleAssignmentService } from "src/app/services/role-assignment/role-assignment.service";
import { SchedRoleAssignUserService } from "src/app/services/sched-role-assign-user/sched-role-assign-user.service";
import { SchedRoleAssignmentsService } from "src/app/services/sched-role-assignments/sched-role-assignments.service";
import { ScheduleService } from "src/app/services/schedule/schedule.service";
import { ShiftsService } from "src/app/services/shifts/shifts.service";
import { UsersService } from "src/app/services/users/users.service";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";
import { CallOut } from "src/app/models/callout";
import { CallOutService } from "src/app/services/callouts/callout.service";
import { AnalyticsData, BhAnalyticsService } from "src/app/services/_core/bhanalytics/bhanalytics.service";

@Component({
  selector: "app-assign-team-member-new",
  templateUrl: "./assign-team-member-new.page.html",
  styleUrls: ["./assign-team-member-new.page.scss"],
})
export class AssignTeamMemberNewPage implements OnInit {
  @Input() rolSeq: number;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  @Input() schedule: Schedule;
  @Input() shfSeq: number;
  @Input() shift: Shift;
  @Input() role: Role;
  @Input() roleGroup: RoleGroup;
  @Input() schedAssignment: SchRoleAssignment;
  @Input() authUserPermission;
  assignmentInput = "";
  dutyInput = "";
  textInput = "";
  searchWord: string = "";
  employees: AdUser[] = [];
  shifts: Shift[] = [];
  recentEmployees: SchRoleAssignmentUser[] = [];
  selectedEmployee: AdUser;
  currentView: "welcome" | "searching" | "loading" | "content" = "welcome";
  roleAssignments: RoleAssignment[] = [];
  schedRoleAssignedUser: SchRoleAssignmentUser;
  initAssignments: RoleAssignment[];
  initSchAssignments: SchRoleAssignment[] = [];
  initSchAssignedUsers: SchRoleAssignmentUser[] = [];
  schedAssignments: SchRoleAssignment[] = [];
  roleAlreadyAssigned: Role;
  rolesAlreadyAssigned: Role[] = [];
  rgOfAlreadyAssigned: RoleGroup;
  rgsOfAlreadyAssigned: RoleGroup[] = [];
  roleAssignmentAlreadyAssigned: SchRoleAssignment;
  rolesAssignmentAlreadyAssigned: SchRoleAssignment[] = [];
  roleAssignmentAlreadyAssignedUser: SchRoleAssignmentUser;
  rolesAssignmentAlreadyAssignedUsers: SchRoleAssignmentUser[] = [];
  assignmentName: string[];
  // Add Assignment variables
  showAddAssignment = false;
  showAddDuty = false;
  isDuplicate = false;
  isFiltering = false;
  onCallOutList = false;
  comment = '';
  showRecentBackButton = false;
  subscriptions: Subscription[] = [];
  remainingCharacters: number;
  initialCharacters: number = 50;
  dutyComments: DutyComment[] = [];
  initDutyComments: DutyComment[] = [];
  callOuts: CallOut[] = [];
  selectedDuty: string;
  isOrientee?: number;

  @ViewChild('textbox', { static: false}) textAreaRef: ElementRef<HTMLTextAreaElement>;
  @ViewChild('dutySelect', { static: false }) dutySelect: IonSelect;
  @ViewChild('dutySelection', { static: false }) dutySelection: ElementRef;
  // notCreatingRoot = false;

  // Define form
  form1: FormGroup = this.formBuilder.group({
    comments: ['',
      [
        Validators.maxLength(49),
      ],
    ],
    shiftEndDate: [null],
    addComment: [null],
    saveDutyComment: [null]
  });

  endShiftTimeSelection: SelectOption[] = [{}];

  constructor(
    private userService: UsersService,
    private schedAssignUserService: SchedRoleAssignUserService,
    private notificationService: NotificationsService,
    private popoverController: PopoverController,
    private roleAssignmentService: RoleAssignmentService,
    private schedRoleAssignmentsService: SchedRoleAssignmentsService,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private scheduleService: ScheduleService,
    private shiftService: ShiftsService,
    private callOutService: CallOutService,
    private analytics: BhAnalyticsService
  ) { }

  async ngOnInit() { }

  async ionViewWillEnter() {
    console.log('shift', this.shift);
    console.log('shift seq', this.shfSeq);
    console.log("assigned users list", this.role.schRoleAssignUsers);
    console.log("scheduled assignments", this.role.schRoleAssignments);
    this.initSchAssignments = Object.assign([], this.role.schRoleAssignments);
    this.initSchAssignedUsers = Object.assign([], this.role.schRoleAssignUsers);
    console.log("ngOnInit", this.initAssignments);
    //await this.loadRecentDraftSchedule();
    await this.getCallOuts(this.rosSeq, this.schedDate, this.schedDate);
    await this.loadRecentRoleUsers(this.role);
    await this.loadRoleAssignments();
    await this.loadDutyComments();
    await this.setShiftOptions();
    //this.form1.controls.comments.setValue('');
    if (this.subscriptions.length === 0) {
      this.remainingCharacters = this.initialCharacters; 
      this.subscriptions = [
        this.form1.controls.comments.valueChanges.subscribe((val) => {
          this.remainingCharacters = this.initialCharacters - val.length;
        })
      ];
    }
    
  }

  get comments(): AbstractControl {
    return this.form1.get('comments');
  }

  errorMessages = {
    comments: [
      { type: 'maxlength', message: 'Reached character limit for comment.' }
    ]
  };

  async loadDutyComments(): Promise<boolean> {
    try {
      const res = await this.schedAssignUserService.getAllDutyComments(this.rosSeq).toPromise();
      if (res) {
        this.dutyComments = res.dutyComment;
        this.dutyComments = this.dutyComments.filter( dc => dc.active === 1);
        this.initDutyComments = Object.assign([], this.dutyComments);
        console.log('duty COmments', this.dutyComments, 'initDuty', this.initDutyComments);
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, 'loadRecentRoleUsers');
    }
  }

  async deleteDutyComment(duty: DutyComment) {
    try {
      duty.active = 0;
      const res = await this.schedAssignUserService.updateDutyComment(this.rosSeq, duty.dcSeq, duty).toPromise();
      if (res) {
        console.log('duty comment deleted', res);
        this.dutyComments = res.dutyComment;
        this.initDutyComments = Object.assign([], this.dutyComments);
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, 'loadRecentRoleUsers');
    }
  }

  onChange(value) {
    this.textAreaRef.nativeElement.value = this.textAreaRef.nativeElement.value.concat(' ', value);
    //this.textAreaRef.nativeElement.value = value;
    console.log('text area', this.textAreaRef.nativeElement.value);
    this.textAreaRef.nativeElement.value = this.textAreaRef.nativeElement.value;
    this.form1.controls.comments.setValue(this.textAreaRef.nativeElement.value);
    this.dutySelection.nativeElement.value = '';
  }

  editEntry() {
    console.log('openned');
  }

  async setShiftOptions() {
    this.endShiftTimeSelection = [];
    const shifts: SelectOption[] = [];
    // for (let h = 0; h < 24; h++) {
    //   const hourTime = h.toString().length === 1 ? '0' + h.toString() : h.toString();
    //   for (let m = 0; m < 60; m+=5) {
    //     const minuteTime = m.toString().length === 1 ? '0' + m.toString() : m.toString();
    //     const timeValue = `${hourTime}:${minuteTime}`;
    //     const momentTime = moment(timeValue, 'H:mm')
    //     const label = momentTime.format('h:mm a');
    //     shifts.push({ label, value: timeValue });
    //   }
    // }
    try {
      let res = await this.shiftService.getAllShifts(this.rosSeq, 1).toPromise();
      if (res) {
        this.shifts = res.shifts;
        //console.log("shifts", this.shifts);

        this.shifts.sort((a, b) => {
          return a.startTime > b.startTime
            ? 1
            : a.startTime === b.startTime
            ? 0
            : -1;
        });

        this.shifts.forEach((shift) => {
          const startTime = moment(shift.startTime, "hh:mm a").format(
            "hh:mm a"
          );
          const endTime = moment(shift.endTime, "h:mm a").format("h:mm a");
          
          const shiftTime = endTime;
          this.endShiftTimeSelection.push({ label: shiftTime, value: shift.endTime });
          this.endShiftTimeSelection = this.endShiftTimeSelection.filter(
            (ss) => Object.keys(ss).length !== 0
          );
        });
        return Promise.resolve(true);
      }
    } catch (err) {
      this.notificationService.handleError(err, "getAllShifts()");
    }
    this.endShiftTimeSelection = this.shifts;
  }

  async loadRecentRoleUsers(role: Role): Promise<boolean> {
    try {
      const res = await this.schedAssignUserService.getRecentRoleUserHistory(this.rosSeq, role).toPromise();
      if (res && res.recentUsers) {
        res.recentUsers.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        this.recentEmployees = res.recentUsers;
        //console.log('recent employees', this.recentEmployees);
        //console.log('call outs', this.callOuts);
        const scheduleCallOut = (this.callOuts.filter(c => c.shiftSeq === this.shfSeq && 
          this.recentEmployees.find(emp => emp.userId.toLowerCase() === c.userId.toLowerCase())).length > 0);
        this.recentEmployees = this.recentEmployees.map(re => 
          this.callOuts.some(c => c.userId.toLowerCase() === re.userId.toLowerCase()) && scheduleCallOut ? {
          ...re,
          callOut: true
        } : re);
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, 'loadRecentRoleUsers');
    }
  }

  onChangeOrientee(event) {
    if(event.detail.checked) {
      this.isOrientee = 1;
    } else {
      this.isOrientee = 0;
    }
    //console.log('event orientee', event.detail.checked, 'checked', this.isOrientee);
  }

  async loadRoleAssignments(): Promise<boolean> {
    try {
      const res = await this.roleAssignmentService
        .getAllRoleAssignments(this.rosSeq, this.rolSeq)
        .toPromise();
      if (res) {
        this.roleAssignments = res.assignments;
        this.initAssignments = Object.assign([], this.roleAssignments);
        for (const ra of this.roleAssignments) {
          ra.isChecked = false;
          const match = this.role.schRoleAssignments.find(
            (sra) =>
              (
                sra.rasSeq === ra.rasSeq &&
                ra.active === 1 &&
                sra.active === 1
              ) ||
              (
                this.schedAssignment !== undefined &&
                this.schedAssignment &&
                this.schedAssignment.rasSeq === ra.rasSeq &&
                ra.active === 1
              )
          );
          // IDEA: remove this
          ra.isChecked = match !== undefined;
        }
        console.log("role assignments", this.roleAssignments);

        // Inject root role assignment
        const rootRoleAssignment: RoleAssignment = {
          rasSeq: 0,
          name: `${this.role.name} (root)`,
          active: 1,
          isChecked: false,
          rolSeq: this.role.rolSeq,
          romSeq: 0,
          default: 1
        };
        this.roleAssignments.unshift(rootRoleAssignment)
        this.initAssignments = Object.assign([], this.roleAssignments);

      }
      return Promise.resolve(true);
    } catch (err) {
      //this.notificationService.handleError(err, "loadRoleAssignments()");
      throw err;
    }
  }
  
  async searchEmployees(ev, selectUserId = null) {
    console.log('search event', ev);
    
    try {
      this.employees = [];
      this.selectedEmployee = null;
      if (ev) {
        this.currentView = "searching";
        const res = await this.userService.getADUserByName(ev).toPromise();
        if (res) {
          this.employees = res.results;
          const shiftarrays = [];
          if(this.employees && this.employees.length > 0) {
            const scheduleCallOut = (this.callOuts.filter(c => c.shiftSeq === this.shfSeq && 
              this.employees.find(emp => emp.userId.toLowerCase() === c.userId.toLowerCase())).length > 0)
             //&& this.callOuts.filter( c => this.employees.find(emp => emp.userId.toLowerCase() === c.userId.toLowerCase())).length > 0;
            console.log('schedule call out', scheduleCallOut, 'callOuts', this.callOuts);
            this.employees = this.employees.map(u =>
              this.callOuts.some(c => c.userId.toLowerCase() === u.userId.toLowerCase()) && scheduleCallOut ? {
              ...u,
              callOut: true
            } : u);
            this.sortName(this.employees);
            //console.log("employees", this.employees);
            if (selectUserId) {
              const selectedEmp = this.employees.find(e => e.userId === selectUserId);
              this.selectEmployee(selectedEmp);
            }
          }
          //this.onCallOutList = this.employees.filter( e => e.userId === this.call)
        }
        this.currentView = "content";
      } else {
        this.currentView = "welcome";
      }
    } catch (err) {
      console.error("Search error: ", err);
    }
  }

  sortName(employees: AdUser[]) {
    employees.sort((a, b) => {
      const sortFirst = a.firstName.localeCompare(b.firstName);
      return sortFirst !== 0 ? sortFirst : a.lastName.localeCompare(b.lastName);
    });
  }

  async selectEmployee(adUser: AdUser): Promise<boolean> {
    this.currentView = "searching";
    this.selectedEmployee = adUser;
    console.log('role =>', this.role);
    console.log('role assignments =>', this.roleAssignments);
    this.initAssignments = this.roleAssignments;
    try {
      for (const ra of this.roleAssignments) {
        ra.isChecked = false;
        let schedRoleAssignment: SchRoleAssignment = this.role.schRoleAssignUsers ? this.role.schRoleAssignments.find(sra => sra.rasSeq === ra.rasSeq) : undefined;
        if (ra.rasSeq === 0) {
          // Check for root assignment
          console.log('selectEmployee: role.schRoleAssignments => ', this.role);
          schedRoleAssignment = this.role.schRoleAssignUsers ?
            this.role.schRoleAssignUsers.find(sru =>  sru.active === 1 && sru.userId.toLowerCase() === adUser.userId.toLowerCase()) : undefined;
          ra.isChecked = schedRoleAssignment !== undefined;
        } else {
          // Check for existing sub assignment
          console.log('selectEmployee (2): schedRoleAssignment.schRoleAssignUsers => ', schedRoleAssignment);
          const existingSru = schedRoleAssignment && schedRoleAssignment.schRoleAssignUsers ?
            schedRoleAssignment.schRoleAssignUsers.find((sru) => sru.active === 1 && sru.userId.toLowerCase() === adUser.userId.toLowerCase()) : undefined;
          ra.isChecked = existingSru !== undefined;
        }
        // console.log('scheduled Role Assignments', schedRoleAssignment);
        // if (schedRoleAssignment !== undefined) {
        //   const match = schedRoleAssignment.schRoleAssignUsers && schedRoleAssignment.schRoleAssignUsers.find(
        //     (sru) =>
        //       sru.active === 1 && sru.userId.toLowerCase() === adUser.userId.toLowerCase()
        //   );
        //   ra.isChecked = match !== undefined;
        // }
      }
      this.verifyExistingRoles(adUser);
      //console.log("role assignments", this.roleAssignments);
      //console.log("init assignments", this.initAssignments);

      //   this.currentView = "content";
      // }
      this.currentView = "content";
      return Promise.resolve(true);
    } catch (err) {
      //this.notificationService.handleError(err, "loadRoleAssignments()");
      throw err;
    }
  }

  async verifyExistingRoles(employee: AdUser): Promise<boolean> {
    console.log('employee', employee);
    this.rgOfAlreadyAssigned = null;
    this.roleAlreadyAssigned = null;
    this.roleAssignmentAlreadyAssigned = null;
    this.assignmentName = [];
    const assignmentName = [];
    this.rgsOfAlreadyAssigned = [];
    this.rolesAlreadyAssigned = [];
    this.rolesAssignmentAlreadyAssigned = [];
    this.rolesAssignmentAlreadyAssignedUsers = [];

    if (this.shift && this.shift.roleGroups) {
      for (const rg of this.shift.roleGroups) {
        let hasUserAssignments = null;
        let hasUserSubAssignments = null;
        for (const r of rg.roles) {
          if (
            Array.isArray(r.schRoleAssignments) &&
            r.schRoleAssignments.length > 0
          ) {
            for (const sra of r.schRoleAssignments) {
              hasUserSubAssignments =
                Array.isArray(sra.schRoleAssignUsers) &&
                  sra.schRoleAssignUsers.filter(
                    (u) => u.userId.toLowerCase() === employee.userId.toLowerCase()
                  ).length > 0
                  ? 1
                  : 0;
                  if(hasUserSubAssignments === 1) {
                  r.hasUserSubAssignments = hasUserSubAssignments;

                  this.rgsOfAlreadyAssigned = this.shift.roleGroups.filter(rg => rg.rgpSeq === r.rgpSeq);
                  console.log('role group of already assigned', this.rgsOfAlreadyAssigned);

                  this.rolesAlreadyAssigned = rg.roles.filter(r => r.rolSeq === sra.rolSeq);
                  console.log('role of already assigned', this.rolesAlreadyAssigned);

                  this.rolesAssignmentAlreadyAssigned = r.schRoleAssignments.filter(s => s.sraSeq === sra.sraSeq);
                  console.log('sub role already assigned', this.rolesAssignmentAlreadyAssigned);

                  this.rolesAssignmentAlreadyAssignedUsers = sra.schRoleAssignUsers.filter(sru => sru.userId.toLowerCase() === employee.userId.toLowerCase() && sru.active === 1);
                  console.log('sru sub assignment', this.rolesAssignmentAlreadyAssignedUsers);

                  assignmentName.push(this.getAssignedName(rg.rgpSeq, r.rolSeq, sra.sraSeq));

                  this.assignmentName = assignmentName.filter(assign => assign !== '');
                  //this.roleAssignmentAlreadyAssigned; 
                  }
                }
                console.log('assignment Name', this.assignmentName);
          } else {
            hasUserAssignments =
              Array.isArray(r.schRoleAssignUsers) &&
                r.schRoleAssignUsers.filter(
                  (u) => u.userId.toLowerCase() === employee.userId.toLowerCase()
                ).length > 0
                ? 1
                : 0;
              }
              r.hasUserAssignments = hasUserAssignments;
              console.log('root role already assigned', r.hasUserAssignments);
          if (hasUserAssignments === 1) {

            this.rgsOfAlreadyAssigned = this.shift.roleGroups.filter(rg => rg.rgpSeq === r.rgpSeq);
            console.log('role group of already assigned', this.rgsOfAlreadyAssigned);

            this.rolesAlreadyAssigned = rg.roles.filter(rol => rol.rolSeq === r.rolSeq);
            console.log('role of already assigned', this.rolesAlreadyAssigned);

            // this.rolesAssignmentAlreadyAssigned = r.schRoleAssignments.filter(s => s.sraSeq === sra.sraSeq);
            // console.log('sub role already assigned', this.rolesAssignmentAlreadyAssigned);

            this.rolesAssignmentAlreadyAssignedUsers = r.schRoleAssignUsers.filter(sru => sru.userId.toLowerCase() === employee.userId.toLowerCase() && sru.active === 1);
            console.log('sru root assignment', this.rolesAssignmentAlreadyAssignedUsers);

           

           
            assignmentName.push( this.getAssignedName(rg.rgpSeq, r.rolSeq));
            this.assignmentName = assignmentName.filter(assign => assign !== '');

            //this.assignmentName.findIndex(name => name.valueOf)
            console.log('assignment Name', this.assignmentName);
            // this.roleAlreadyAssigned = r;
            // console.log('role Already assigned', this.roleAlreadyAssigned);
            // this.schedRoleAssignedUser = this.roleAlreadyAssigned.schRoleAssignUsers.filter(sru => sru.userId.toLowerCase() === employee.userId.toLowerCase())[0];    
            // console.log('sched Root assign user', this.schedRoleAssignedUser);
            // this.roleAlreadyAssigned = rg.roles.filter(rol => rol.rolSeq === this.roleAlreadyAssigned.rolSeq)[0];
            // this.rgOfAlreadyAssigned = this.shift.roleGroups.filter(rg => rg.rgpSeq === r.rgpSeq)[0];
          }
        }
      }
    }
    return Promise.resolve(true);
  }

  getAssignedName(rgpSeq, rolSeq, sraSeq?): string {
    const role = this.rolesAlreadyAssigned.filter((r) => r.rolSeq === rolSeq)[0];
    const roleGroup = this.rgsOfAlreadyAssigned.filter((rg) => rg.rgpSeq === rgpSeq)[0];
    const schedAssignment = this.rolesAssignmentAlreadyAssigned.filter(
      (sra) => sra.sraSeq === sraSeq
    )[0];
    const schedAssignedUser = this.rolesAssignmentAlreadyAssignedUsers.filter(
      (sru) => sru.sraSeq !== 0 ? sru.sraSeq === sraSeq : sru.rolSeq === rolSeq
    )[0];
    return (
      (roleGroup && schedAssignedUser?.active === 1 ? roleGroup.name + " > " : '') +
      (role && schedAssignedUser?.active === 1 && schedAssignedUser?.sraSeq === 0 ? role.name
      : this.rolesAssignmentAlreadyAssignedUsers.length > 0 && schedAssignedUser?.sraSeq !== 0 ? (role.name + " > ") : '') +
      (schedAssignment && schedAssignedUser?.active === 1 ? schedAssignment?.name + " > " : '').replace(/>\s*$/, '')
    );
  }

  async save(adUser: AdUser) {
    console.log('duty comments', this.dutyComments);
    const comment = this.form1.controls.comments.value;
    const orientee = this.isOrientee;
    console.log('comment', comment);
    console.log('orientee', orientee);
    
    const shiftEndDate = this.form1.controls.shiftEndDate.value;
    try {
      
      this.notificationService.startLoading('Applying changes');
      const res = await this.schedAssignUserService.saveUserAssignments(
        this.rosSeq,
        this.schSeq,
        this.schedDate,
        this.shift,
        this.roleGroup,
        this.role,
        adUser,
        this.roleAssignments,
        this.initAssignments,
        this.schedAssignments,
        this.initSchAssignments,
        this.currentView,
        //this.dutyComments,
        //this.initDutyComments,
        shiftEndDate,
        comment,
        orientee
      );

      console.log('saved: Received result', res);

      if(res.schedAssignments && res.schedAssignments.length > 0) {
        res.schedAssignment = res.schedAssignments[0]
      }
      //console.log('saved: role result', res.role.name);
      
      if (res && res.dismiss) {
        this.analytics.clickEvent("add-assigned-user", "added " + adUser.fullName + ' to assignment ' + res.role.name + (res.schedAssignment !== null ? '/' + res.schedAssignment.name : ''), this.rosSeq);
        console.log('Dismissing modal');
        this.modalCtrl.dismiss(res);
      }

      this.notificationService.stopLoading();
    } catch (err) {
      this.notificationService.stopLoading();
      this.notificationService.handleError(err, 'save2()');
    }
  }

  setShowAddAssignment(show) {
    this.showAddAssignment = show;
  }
  setShowAddDuty(show) {
    this.showAddDuty = show;
  }

  toggleAssignment(assignment: RoleAssignment) {
    assignment.isChecked = !assignment.isChecked;
  }
  toggleDuty(dutyComment: DutyComment) {
    console.log('duty Coment', dutyComment.isChecked);
    dutyComment.isChecked = !dutyComment.isChecked;
  }

  async addNewAssignment() {
    console.log("new assignment", this.assignmentInput);
    try {
      const existingAsi = this.initAssignments.find(
        (ra) => ra.name.trim() === this.assignmentInput.trim()
      );
      console.log("does it exist?", existingAsi);
      if (!existingAsi) {
        this.currentView = "loading";
        const newRoleAssignment: RoleAssignment = {
          rolSeq: this.rolSeq,
          romSeq: 1,
          name: this.assignmentInput,
          active: 1,
          default: 1,
          isChecked: false,
        };
        const res = await this.roleAssignmentService
          .createRoleAssignment(this.rosSeq, this.rolSeq, newRoleAssignment)
          .toPromise();
        this.analytics.clickEvent("add-assignment", "added " + newRoleAssignment.name, this.rosSeq);
        console.log("created new RoleAssignment", res);
        this.assignmentInput = "";
        this.isFiltering = false;
        this.roleAssignments = this.initAssignments;
        newRoleAssignment.rasSeq = res.rasSeq;
        this.roleAssignments.push(newRoleAssignment);
        this.currentView = "content";
        // this.loadRoleAssignments();
        console.log("check out inside roleAssignments", this.roleAssignments);
      } else {
        console.log("Duplicate Role Assignment");
        if (existingAsi.active === 0) {
          this.currentView = "loading";
          existingAsi.active = 1;
          const res = await this.roleAssignmentService
            .updateRoleAssignment(
              this.rosSeq,
              existingAsi.rolSeq,
              existingAsi.rasSeq,
              existingAsi
            )
            .toPromise();
          this.assignmentInput = "";
          this.isFiltering = false;
          this.roleAssignments = this.initAssignments;
          this.currentView = "content";
        } else {
          this.isDuplicate = true;
        }
        // this.assignmentInput = "";
      }
    } catch (err) {
      this.currentView = "content";
      this.notificationService.handleError(err, "addNewAssignment()");
    }
  }

  async addNewDuty() {
    console.log("new duty input", this.dutyInput);
    try {
      const existingDuty = this.initDutyComments.find(
        (d) => d.dutyComment.trim() === this.dutyInput.trim()
      );
      console.log("does it exist?", existingDuty);
      if (!existingDuty) {
        this.currentView = "loading";
        const newDuty: DutyComment = {
          active: 1,
          dutyComment: this.dutyInput,
          rosSeq: this.rosSeq,
          isChecked: false
        };
        const res = await this.schedAssignUserService
          .createDutyComment(this.rosSeq, newDuty)
          .toPromise();
        console.log("created new duty", res);
        this.dutyInput = "";
        this.isFiltering = false;
        this.dutyComments = this.initDutyComments;
        newDuty.dcSeq = res.dcSeq;
        this.dutyComments.push(newDuty);
        this.currentView = "content";
        console.log("check out inside duty comments", this.dutyComments);
      } else {
        console.log("Duplicate Role Assignment");
        if (existingDuty.active === 0) {
          this.currentView = "loading";
          existingDuty.active = 1;
          const res = await this.schedAssignUserService
            .updateDutyComment(
              this.rosSeq,
              existingDuty.dcSeq,
              existingDuty
            )
            .toPromise();
          this.dutyInput = "";
          this.isFiltering = false;
          this.dutyComments = this.initDutyComments;
          this.currentView = "content";
        } else {
          this.isDuplicate = true;
        }
        // this.assignmentInput = "";
      }
    } catch (err) {
      this.currentView = "content";
      this.notificationService.handleError(err, "addNewDuty()");
    }
  }


  filter(ev) {
    console.log("filter: initAssignments", this.initAssignments);
    this.isDuplicate = false;
    if (ev) {
      this.isFiltering = true;
      const term = this.assignmentInput
        .replace(/\ /gi, "")
        .toUpperCase()
        .trim();
      this.roleAssignments = this.initAssignments.filter(
        (ras) =>
          ras.name.replace(/\ /gi, "").toUpperCase().trim().indexOf(term) >
          -1 && ras.active === 1
      );
    } else {
      this.assignmentInput = "";
      this.isFiltering = false;
      this.roleAssignments = Object.assign([], this.initAssignments);
    }
  }


  filterDuty(ev) {
    console.log("filter: initDuty", this.initDutyComments);
    this.isDuplicate = false;
    if (ev) {
      this.isFiltering = true;
      const term = this.dutyInput
        .replace(/\ /gi, "")
        .toUpperCase()
        .trim();
      this.dutyComments = this.initDutyComments.filter(
        (dc) =>
          dc.dutyComment.replace(/\ /gi, "").toUpperCase().trim().indexOf(term) >
          -1 && dc.active === 1
      );
    } else {
      this.assignmentInput = "";
      this.isFiltering = false;
      this.dutyComments = Object.assign([], this.initDutyComments);
    }
  }

  async saveRoleAssignments() {
    try {
      for (const ra of this.initAssignments) {
        const matches = this.role.schRoleAssignments.filter(
          (sra) =>
            sra.rasSeq === ra.rasSeq && sra.active === 1 && ra.active === 1
        );
        console.log("check ra", ra);
        console.log("matches", matches);
        if (ra.isChecked) {
          if (matches.length === 0) {
            const schedRoleAssignment: SchRoleAssignment = {
              rasSeq: ra.rasSeq,
              rosSeq: this.rosSeq,
              schSeq: this.schSeq,
              rgpSeq: this.role.rgpSeq,
              rolSeq: ra.rolSeq,
              shfSeq: this.shfSeq,
              schedDate: this.schedDate,
              name: ra.name,
              active: 1,
            };
            const res = await this.schedRoleAssignmentsService
              .createSchedRoleAssignment(
                this.rosSeq,
                this.schSeq,
                schedRoleAssignment
              )
              .toPromise();
            console.log("create new schedRoleAssignment", res);
            schedRoleAssignment.sraSeq = res.sraSeq;
            this.role.schRoleAssignments.push(schedRoleAssignment);
          } else {
            // SchRoleAssignment exists
            console.log("schRoleAssignment exists", ra);
          }
        } else {
          //assignment exists but was removed
          if (matches.length > 0) {
            matches.forEach(async (mat) => {
              mat.active = 0;
              const res = await this.schedRoleAssignmentsService
                .updateSchedRoleAssignment(
                  this.rosSeq,
                  this.schSeq,
                  mat.sraSeq,
                  mat
                )
                .toPromise();
              console.log("updated matches", res);
            });
            this.role.schRoleAssignments = this.role.schRoleAssignments.filter(
              (sra) => sra.rasSeq !== ra.rasSeq
            );
          }
        }
        this.role.schRoleAssignments = this.role.schRoleAssignments.sort(
          (a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1)
        );
      }
    } catch { }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  async selectRecentEmployee(recEmployee: SchRoleAssignmentUser) {
    try {
      // Get record from Active Directory
      const res = await this.userService.getADUserById(recEmployee.userId).toPromise();
      // Pass to selectEmployee
      if (res && res.results && res.results.length > 0) {
        const adUser = res.results[0];
        this.searchEmployees(adUser.userId);
        this.selectEmployee(adUser);
        this.showRecentBackButton = true;
        this.currentView = 'content';
      } else {
        this.notificationService.showAlert('Unable to assign user',
          'This team member is no longer available in Active Directory search. Please submit an I&T Help Ticket if you\'d like to add this person.',
          'information');
      }

    } catch (err) {
      this.notificationService.handleError(err, 'selectRecentEmployee');
    }
  }

  backToRecentlyAssigned() {
    this.currentView = 'welcome';
    this.showRecentBackButton = false;
  }

  async getCallOuts(rosSeq, fromSchedDate, toSchedDate): Promise<boolean> {
    try {
      let res = await this.callOutService.getCallOutsAll(rosSeq, fromSchedDate, toSchedDate).toPromise();
      if (res) {
        this.callOuts = res.callouts;
        this.callOuts = res.callouts.map((c) => ({
          ...c,
          shiftSeq: c.shfSeq
        }));
        console.log('callouts', this.callOuts);
        
      }

      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, 'getCallOuts()');
    }
  }
}