import { UserState } from './../../../models/_core/user-state';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { User } from 'src/app/models/user';
import { NavController, AlertController, ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { Injectable, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, observable, interval, BehaviorSubject, Subscription } from 'rxjs';
import { map, catchError, mapTo, first } from 'rxjs/operators';
import { NotificationsService } from '../notifications/notifications.service';
import { StorageService } from '../storage/storage.service';
import { ConstantsService } from '../constants/constants.service';
import * as moment from 'moment';
import { AuthState } from 'src/app/models/_core/auth-state';

/**
 * ID: bh-auth-service
 * Name: BH Auth Service
 * Description: Service used for managing authentication and user state
 * Version: 3
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented userState
 * 2021-07-27 - MW - v3: Improved open modal + alert handling; improved UX
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  env = environment;
  authUser: BehaviorSubject<User> = new BehaviorSubject({});
  userState: UserState = null;
  API_URL: any;
  timeoutWarningMs = 60000;
  timeoutLogoutMs = 120000;
  inactivitySubject = new BehaviorSubject<number>(0);
  inactivityTimer = null;
  targetUrl = '/tabs/home';
  deviceAuthFailed = false;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private storageService: StorageService,
    private nav: NavController,
    private alertCtrl: AlertController,
    private constants: ConstantsService,
    private modalCtrl: ModalController,
    private helpers: HelperUtilitiesService
  ) {
    this.getUserStateFromStorage();
  }

  /**
   * Gets Auth User object
   * Recommend subscribing to authUser directly
   */
  getAuthUser(): User {
    return this.authUser.getValue();
  }

  /**
   * Updates Auth User object with provided object
   * @param authUser User object to replace existing value
   */
  setAuthUser(authUser: User) {
    this.authUser.next(authUser);
  }

  /***
   * Gets the user's state from storage
   */
  async getUserStateFromStorage() {
    this.userState = await this.storageService.getData('userState');
  }

  /***
   * Save the user's state to local storage
   */
  async saveUserStateToStorage() {
    if (!this.env.storeToken && this.userState.authUser.token) {
      this.userState.authUser.token = null;
    }
    this.storageService.saveData('userState', this.userState);
  }

  /**
   * Starts inactivity timer.
   * Should be called after successfully logging in
   */
  public startInactivityTimer() {
    if (this.env.requireTimeout) {
      this.timeoutLogoutMs = this.env.timeoutThreshold;
      this.timeoutWarningMs = this.timeoutLogoutMs - 30000;
      this.inactivityTimer = setInterval(() => {
        let time = this.inactivitySubject.getValue();
        time += 1000;
        // console.log('Inactivity: ', time)
        this.inactivitySubject.next(time);
        this.checkForTimeout();
      }, 1000);
    }
  }

  /**
   * Check for session timeout, display appropriate alert if timing out.
   */
  public async checkForTimeout() {
    const time = this.inactivitySubject.getValue();
    if (time === this.timeoutWarningMs) {
      const alert = await this.alertCtrl.create({
        header: 'Still there?',
        message: 'You will be signed out soon due to inactivity.',
        cssClass: 'wide-alert warning',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay signed in',
            cssClass: 'primary',
            handler: (val) => {
              this.bumpInactivityTimer();
            }
          },
          {
            text: 'Sign out',
            handler: async (val) => {
              await this.dismissAllModalsAndAlerts();
              this.logout(false, true);
            }
          }
        ]
      });
      await alert.present();
    } else if (time === this.timeoutLogoutMs) {
      await this.dismissAllModalsAndAlerts();
      this.logout(true, true);
    }
  }

  /**
   * Dismisses all open alerts and modals
   */
  async dismissAllModalsAndAlerts(): Promise<boolean> {
    // Dismiss alerts
    for (let i = 0; i < 25; i++) {
      const alert = await this.alertCtrl.getTop();
      if (alert) {
        await alert.dismiss();
      } else {
        break;
      }
    }

    // Dismiss modals
    for (let i = 0; i < 25; i++) {
      const modal = await this.modalCtrl.getTop();
      if (modal) {
        await modal.dismiss();
      } else {
        break;
      }
    }

    return Promise.resolve(true);

  }

  /**
   * Bumps activity timer, preventing auto-timeout
   */
  public bumpInactivityTimer() {
    this.inactivitySubject.next(0);
  }

  /***
   * Validate User is still active in AD
   */
  validateUser(): Observable<any> {
    // Prepare request
    const url = environment.apiUrl + `/validateAD`;
    // Send request
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(err => {
        // user notifications
        return of(err);
      })
    );
  }

  /**
   * Logs user into application
   * @param userId User ID
   * @param password  Password
   * @returns User Login Payload
   */
  login(userId, password): Observable<any> {
    const url = `${this.env.apiUrl}/login`;
    const body = {
      userId,
      password,
      source: 'p'
    };
    return this.http.post(url, body).pipe(
      map((data: any) => {
        this.handleLoginResponse(data);
        return data;
      }),
      catchError(error => {
        return of(error);
      })
    );
  }

  loginDevice(key, apiToken) {
    const url = `${this.env.apiUrl}/devices/auth`;
    const body = {
      key,
      apiToken
    };
    const options = { headers: { 'BH-STAY-ON-PAGE-WITH-ERROR': 'true' }};
    return this.http.post(url, body, options).pipe(
      map((data: any) => {
        this.handleDeviceLogin(data, apiToken);
        return data;
      }),
      catchError(error => {
        return of(error);
      })
    );
  }

  loginLocation(key, apiToken, locQuery) {
    const url = `${this.env.apiUrl}/devices/auth?locQuery=${locQuery}`;
    const body = {
      key,
      apiToken
    };
    const options = { headers: { 'BH-STAY-ON-PAGE-WITH-ERROR': 'true' }};
    return this.http.post(url, body, options).pipe(
      map((data: any) => {
        this.handleDeviceLogin(data, apiToken);
        return data;
      }),
      catchError(error => {
        localStorage.setItem("deviceKey", '');
        localStorage.setItem("apiToken", '');
          return of(error);
      })
    );
  }

  handleDeviceLogin(data, apiToken) {
    if (data.x_status && data.x_status === 'S') {
      const authUser: User = data;
      authUser.userId = data.key;
      authUser.role = 'DEVICE';
      authUser.locQuery = data.locQuery;
      authUser.apiToken = apiToken;
      localStorage.setItem("deviceKey", data.key);
      localStorage.setItem("apiToken", apiToken);
      this.setAuthUser(authUser);
      
      //authUser.firstName = this.helpers.getFirstName(data.fullName);
      // this.userState.sessionAppVersion = this.env.appVersion + '-' + this.env.env;
      // this.userState.userId = authUser.userId;
      // this.userState.environment = this.env;
      // this.userState.lastLoggedIn = moment().format('M/D/YYYY HH:mm');
      // this.userState.authState = AuthState.LOGGED_IN;
      // if (this.env.storeToken) {
      //   this.userState.authUser = authUser;
      // }
    }
     else {
        const authUser: User = {
          deviceAuthFailed: true
        }
     }
    return;
  }

  handleLocLogin(data) {
    if (data.x_status && data.x_status === 'S') {
      const authUser: User = data;
      authUser.userId = data.key;
      authUser.locQuery = data.locQuery;
      this.setAuthUser(authUser);
    }
     else {
        const authUser: User = {
          deviceAuthFailed: true
        }
     }
    return;
  }
  
  /**
   * Process user response data, determining login status
   * @param data Login Response Data
   */
  async handleLoginResponse(data: any) {
    if (data.x_status && data.x_status === 'S') {
      this.startInactivityTimer();
      const authUser: User = data;
      authUser.userId = authUser.userId.toLowerCase();
      //authUser.firstName = this.helpers.getFirstName(data.fullName);
      this.setAuthUser(authUser);
      this.userState.sessionAppVersion = this.env.appVersion + '-' + this.env.env;
      this.userState.userId = authUser.userId;
      this.userState.environment = this.env;
      this.userState.lastLoggedIn = moment().format('M/D/YYYY HH:mm');
      this.userState.authState = AuthState.LOGGED_IN;
      if (this.env.storeToken) {
        this.userState.authUser = authUser;
      }
      this.saveUserStateToStorage();
    }
    return;
  }

  /**
   * Logs user out
   * @param isExpired Determines if session expired
   * @param redirectToLogin Designates redirection to login page
   */
  logout(isExpired = false, redirectToLogin = true) {
    this.authUser.next(null);
    this.inactivitySubject.next(0);
    clearInterval(this.inactivityTimer);
    this.inactivityTimer = null;
    this.alertCtrl.getTop().then(alert => {
      if (alert) {
        alert.dismiss();
      }
    });

    if (isExpired) {
      this.userState.authState = AuthState.EXPIRED;
      this.notifications.showAlert('Session expired', 'You were signed out due to inactivity.', 'danger');
    } else {
      this.userState.authState = AuthState.LOGGED_OUT;
    }

    this.storageService.removeData('userState');

    if (redirectToLogin) {
      this.nav.navigateRoot('login');
    }
  }


}
