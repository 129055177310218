import { BhMessageBannerComponentModule } from 'src/app/components/_core/bh-message-banner/bh-message-banner.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PublishPageRoutingModule } from './publish-routing.module';

import { PublishPage } from './publish.page';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { FormatTimePipe } from 'src/app/pipes/format-time.pipe';
import { FilterUniquePipe } from 'src/app/pipes/filter-unique';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PublishPageRoutingModule,
    BhMessageBannerComponentModule,
    PipesModule
  ],
  declarations: [PublishPage],
  providers: [FormatTimePipe, FilterUniquePipe]
})
export class PublishPageModule {}
