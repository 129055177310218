import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from '@ionic/angular';
import { Employee } from 'src/app/models/employee';
import { Room } from 'src/app/models/room';
import { Roster } from 'src/app/models/roster';
import { UserAccess } from 'src/app/models/user-access';
import { SelectOption } from 'src/app/models/_core/select-option';
import { RoomService } from 'src/app/services/room/room.service';
import { RosterService } from 'src/app/services/roster/roster.service';
import { UsersService } from 'src/app/services/users/users.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';

@Component({
  selector: 'app-edit-individual-permission',
  templateUrl: './edit-individual-permission.page.html',
  styleUrls: ['./edit-individual-permission.page.scss'],
})
export class EditIndividualPermissionPage implements OnInit {


  @Input() rolSeq: number;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  @Input() shfSeq: number;
  @Input() rgpSeq: number;
  @Input() user: UserAccess;
  @Input() employee: Employee;
  @Input() componentLabel: string;
  roster: Roster;
  accessSelection: SelectOption[] = [
    {
     label: 'Read-Only',
     value: 'READONLY'
    },
    {
      label: 'Editor',
      value: 'EDITOR'
    },
    {
      label: 'Owner',
      value: 'OWNER'
    }
];
  
setActive: SelectOption[] = [
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
];
 // Define form
 form1: FormGroup = this.formBuilder.group({
  access: [null, Validators.required],
  active: [null, Validators.required]
});

// Define validation messages
validationMessages = {
  access: [{ type: "required", message: "A room name is required." }],
  active: [{ type: "required", message: "Active selection must be made." }]
};
 // Submission State
 submitAttempted = false;

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private rosterService: RosterService,
    private notificationService: NotificationsService,
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.loadForm();
  }

  async loadForm() {
    await this.getCurrentRoster();
    console.log('selectedUser', this.user);
    this.form1.controls.access.setValue(this.user.access);
    this.form1.controls.active.setValue(this.user.active);
  }

  async getCurrentRoster(): Promise<boolean> {
    try {
     const res = await this.rosterService.getRosterBySeq(this.rosSeq).toPromise();
   if(res) {   
      this.roster = res;
      console.log('current roster', this.roster);
   }
   return Promise.resolve(true);
 } catch (err) {
  this.notificationService.handleError(err, 'getCurrentRoster()')
}
}

  async updateUser() {
    if (this.form1.valid) {
    try {
      this.user.access = this.form1.controls.access.value;
      this.user.active = this.form1.controls.active.value;
      const res = await this.usersService.updateUserAccess(this.rosSeq, this.user).toPromise();
      console.log("updated user", res);
      this.modalCtrl.dismiss({ isUpdated: true });
    } catch (err) {
      this.notificationService.handleError(err, "updateUser()");
    }
  } else {
    console.log('Form invalid', this.form1);
    }
  }

  async removeUser() {
    try {
      this.user.active = 0;
      const res = await this.usersService.updateUserAccess(this.rosSeq, this.user).toPromise();
      console.log("removed user", res);
      this.modalCtrl.dismiss({ isUpdated: true });
    } catch (err) {
      this.notificationService.handleError(err, "removeUser()");
    }
  }

  dismiss() {
    this.analytics.clickEvent('feedback: dismiss', '');
    this.modalCtrl.dismiss();
  }

  cancel() {
    this.analytics.clickEvent('feedback: cancel', '');
    this.modalCtrl.dismiss();
  }

}
