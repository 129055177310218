import { Pipe, PipeTransform } from '@angular/core';
import { HelperUtilitiesService } from '../services/_core/helper-utilities/helper-utilities.service';


@Pipe({
  name: 'inputTypeLabel'
})
export class InputTypeLabelPipe implements PipeTransform {
  constructor(
) { }

  transform(value: any): any {
    switch (value) {
      case 'TEXT':
        return 'Textbox';

      case 'RADIO':
        return 'Radio Checklist';

      case 'TEXTAREA':
        return 'Multi-line Textbox';

      case 'CHECKLIST':
        return 'Checklist';

      case 'CHECK':
        return 'Checkbox';

      case 'DATE':
        return 'Date Picker';
    }
  }

}
