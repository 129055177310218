import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { SelectOption } from "src/app/models/_core/select-option";
import { Roster } from "src/app/models/roster";
import { Schedule } from "src/app/models/schedule";
import { Shift } from "src/app/models/shift";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";
import { ScheduleService } from "src/app/services/schedule/schedule.service";
import { ShiftsService } from "src/app/services/shifts/shifts.service";

@Component({
  selector: "app-employee-board-update-shift",
  templateUrl: "./employee-board-update-shift.page.html",
  styleUrls: ["./employee-board-update-shift.page.scss"],
})
export class EmployeeBoardUpdateShiftPage implements OnInit {
  @Input() roster: Roster;
  @Input() shift: Shift;
  @Input() schedule: Schedule;
  shifts: Shift[] = [];

  form1: FormGroup = this.formBuilder.group({
    fromShift: [null],
    delayTimer: [null],
  });

  setDelayTime: SelectOption[] = [
    {
      label: "5 Minutes",
      value: 5,
    },
    {
      label: "10 Minutes",
      value: 10,
    },
    {
      label: "15 Minutes",
      value: 15,
    },
    {
      label: "20 Minutes",
      value: 20,
    },
    {
      label: "25 Minutes",
      value: 25,
    },
    {
      label: "30 Minutes",
      value: 30,
    },
  ];

  // Define validation messages
  validationMessages = {
    //printOption: [{ type: "required", message: "Choose an option to continue." }],
  };
  // Submission State
  submitAttempted = false;

  subs: Subscription[] = [];
  shiftSelection: SelectOption[] = [{}];
  shiftSelection2: SelectOption[] = [{}];
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private shiftService: ShiftsService,
    private scheduleService: ScheduleService
  ) {}

  ngOnInit() {
    this.loadForm();
    console.log("roster");
  }

  async loadForm() {
    await this.getAllShifts(this.roster.rosSeq);
    // const schedDayMoment = moment(this.schedDate, 'MM/DD/YYYY');
    // this.form1.controls.fromDate.setValue(schedDayMoment);
    // this.form1.controls.fromShift.setValue(this.selectedShift.shfSeq);
    // this.form1.controls.toDate.setValue(schedDayMoment);
  }

  async getAllShifts(rosSeq): Promise<boolean> {
    try {
      let res = await this.shiftService.getAllShifts(rosSeq, 1).toPromise();
      if (res) {
        this.shifts = res.shifts;
        console.log("shifts", this.shifts);
        this.shifts.sort((a, b) => {
          if (a.shfSeq === b.shfSeq) {
            return (
              moment(a.startTime, "MM/DD/YYYY HH:mm:ss").valueOf() -
              moment(b.startTime, "MM/DD/YYYY HH:mm:ss").valueOf()
            );
          }
          return a.shfSeq > b.shfSeq ? 1 : a.shfSeq === b.shfSeq ? 0 : -1;
        });
        this.shifts.forEach((shift) => {
          const startTime = moment(shift.startTime, "hh:mm a").format(
            "hh:mm a"
          );
          const endTime = moment(shift.endTime, "hh:mm a").format("hh:mm a");
          const shiftStatus = this.getShiftStatus(shift);
          const shiftTime = startTime + " - " + endTime + shiftStatus;
          this.shiftSelection.push({ label: shiftTime, value: shift.shfSeq });
          this.shiftSelection = this.shiftSelection.filter(
            (ss) => Object.keys(ss).length !== 0
          );
          this.shiftSelection2 = Object.assign([], this.shiftSelection);
        });
        //console.log('shift Seq', this.shiftSeq);
        return Promise.resolve(true);
      }
    } catch (err) {
      this.notificationService.handleError(err, "getAllShifts()");
    }
  }

  getShiftStatus(shift: Shift) {
    let currentMoment = moment();
    const postDateMoment = moment(
      shift.postDate + " 00:00:00",
      "MM/DD/YYYY HH:mm:ss"
    );
    const stopDateMoment = shift.stopDate
      ? moment(shift.stopDate + " 00:00:00", "MM/DD/YYYY HH:mm:ss")
      : null;
    const isLive =
      postDateMoment <= currentMoment &&
      (!stopDateMoment || stopDateMoment >= currentMoment);
    if (!shift.active) {
      return " (inactive)";
    } else if (!isLive) {
      return " (expired)";
    } else {
      return "";
    }
  }

  saveAndContinue() {
    this.submitAttempted = true;
    if (this.form1.valid) {
      const fromShift = this.form1.controls.fromShift.value;
      const delayTimer = this.form1.controls.delayTimer.value;
      this.modalCtrl.dismiss({
        isSaved: true,
        shift: fromShift,
        delayTimer: delayTimer
      });
    }
  }
  
  cancel() {
    this.modalCtrl.dismiss();
  }
}
