import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BhGridColumnDefinition } from 'src/app/models/_core/bh-grid-column-definition';
import { Report } from 'src/app/models/report';
import { Roster } from 'src/app/models/roster';
import { SchRoleAssignmentReportRecord, SchRoleAssignmentUser } from 'src/app/models/sched-role-assign-user';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ReportsService } from 'src/app/services/reports/reports.service';
import * as moment from 'moment';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';

@Component({
  selector: 'app-roster-reports-run',
  templateUrl: './roster-reports-run.page.html',
  styleUrls: ['./roster-reports-run.page.scss'],
})
export class RosterReportsRunPage implements OnInit {
  @Input() roster: Roster;
  @Input() report: Report;
  crAssignments: SchRoleAssignmentReportRecord[] = [];
  currentView: 'loading' | 'content' = 'loading';
  fromDate = '';
  thruDate = '';
  groupBy = '';
  sort1 = 'startDate';
  sort2 = 'shiftPeriod';
  shiftCriteria = '(Any Shift)';
  roleGroupCriteria = '(Any Role Group)';
  roleCriteria = '(Any Role)';
  assignmentCriteria = '(Any Assignment)';
  userCriteria = '(Any User)';
  commentCriteria = '(Any Comment)';

  columnDefinitions: BhGridColumnDefinition[] = [
    {
      fieldName: 'name', columnLabel: 'Team Member', ionIcon: 'person-outline', ionIconColor: 'primary', showIconArg: '',
      showIconOnly: false, alwaysShowIcon: true, showColumn: true
    },
    {
      fieldName: 'userId', columnLabel: 'User ID', ionIcon: '', ionIconColor: '',
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'startDate', columnLabel: 'Sched Date', ionIcon: '', ionIconColor: '',
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'shiftPeriod', columnLabel: 'Shift', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'rgpName', columnLabel: 'Role Group', ionIcon: '', ionIconColor: null, 
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'rolName', columnLabel: 'Role', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'rasName', columnLabel: 'Assignment', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'comment', columnLabel: 'Comment', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
  ];


  constructor(
    private modalCtrl: ModalController,
    private notifications: NotificationsService,
    private reportService: ReportsService,
    private analytics: BhAnalyticsService,
    private helpers: HelperUtilitiesService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async loadData() {
    try {
      this.listCriteria();
      let res = await this.reportService.runReport(this.report).toPromise();
      if (res && res.crAssignment) {
        this.crAssignments = res.crAssignment;
        for (const cra of this.crAssignments) {
          const startMoment = moment(cra.startDateTime, 'MM/DD/YYYY hh:mm a');
          cra.startDate = startMoment.format('MM/DD/YYYY');
          cra.shiftPeriod = cra.shfStartTime + "-" + cra.shfEndTime;
          cra.rasName = cra.rasName ? cra.rasName : '-';
          cra.comment = cra.comment ? cra.comment : '-';
        }
        this.currentView = 'content';
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notifications.handleError(err, 'loadData()');
    }
  }

  listCriteria() {
    this.fromDate = this.report.qryFromDate;
    this.thruDate = this.report.qryThruDate;
    this.groupBy = this.report.groupBy;
    this.sort1 = this.report.sort1;
    this.sort2 = this.report.sort2;

    const shiftParms = this.report.reportParms.filter(p => p.field === 'SRU_SHF_SEQ' && p.active === 1);
    this.shiftCriteria = shiftParms.length > 0 ? '' : '(Any Shift)';
    for (const sh of shiftParms) {
      if (sh.active) {
        this.shiftCriteria += sh.label + " | ";
      }
    }

    if (shiftParms.length > 0) {
      this.shiftCriteria = this.shiftCriteria.substring(0, this.shiftCriteria.length - 2);
    }

    const roleGroupParms = this.report.reportParms.filter(p => p.field === 'SRU_RGP_SEQ' && p.active === 1);
    this.roleGroupCriteria = roleGroupParms.length > 0 ? '' : '(Any Role Group)';
    for (const rg of roleGroupParms) {
      if (rg.active) {
        this.roleGroupCriteria += rg.label + " | ";
      }
    }

    if (roleGroupParms.length > 0) {
      this.roleGroupCriteria = this.roleGroupCriteria.substring(0, this.roleGroupCriteria.length - 2);
    }

    const roleParms = this.report.reportParms.filter(p => p.field === 'SRU_ROL_SEQ' && p.active === 1);
    this.roleCriteria = roleParms.length > 0 ? '' : '(Any Role)';
    for (const r of roleParms) {
      if (r.active) {
        this.roleCriteria += r.label + " | ";
      }
    }

    if (roleParms.length > 0) {
      this.roleCriteria = this.roleCriteria.substring(0, this.roleCriteria.length - 2);
    }


    const assignmentParms = this.report.reportParms.filter(p => p.field === 'SRU_SRA_SEQ' && p.active === 1);
    this.assignmentCriteria = assignmentParms.length > 0 ? '' : '(Any Assignment)';
    for (const asi of assignmentParms) {
      if (asi.active) {
        this.assignmentCriteria += asi.label + " | ";
      }
    }

    if (assignmentParms.length > 0) {
      this.assignmentCriteria = this.assignmentCriteria.substring(0, this.assignmentCriteria.length - 2);
    }

    const userParms = this.report.reportParms.filter(p => p.field === 'SRU_USR_ID' && p.active === 1);
    this.userCriteria = userParms.length > 0 ? '' : '(Any User)';
    for (const usr of userParms) {
      if (usr.active) {
        this.userCriteria += usr.label + " | ";
      }
    }

    if (userParms.length > 0) {
      this.userCriteria = this.userCriteria.substring(0, this.userCriteria.length - 2);
    }

    const dcParms = this.report.reportParms.filter(p => p.field === 'SRU_COMMENT' && p.active === 1);
    this.commentCriteria = dcParms.length > 0 ? '' : '(Any Comment)';
    for (const dc of dcParms) {
      if (dc.active) {
        this.commentCriteria += dc.label + " | ";
      }
    }
    
    if (this.report.qryComment) {
      if (dcParms.length === 0) {
        this.commentCriteria = '';
      }
      this.commentCriteria += this.report.qryComment;
    }

  }

  print() {
    window.print();
  }

  export() {
    this.analytics.clickEvent('manage-cases: export', '');
    let textFile = null;
    let text = '';
    let rowNumber = 0;
    const fieldsToExport = {
      name: 'Name',
      userId: 'User ID',
      startDate: 'Sched Date',
      shiftPeriod: 'Shift',
      rgpName: 'Role Group',
      rolName: 'Role',
      rasName: 'Assignment',
      comment: 'Comment'
    };

    for (const cr of this.crAssignments) {
      // Create Header Row
      if (rowNumber === 0) {
        for (const key in fieldsToExport) {
          if (Object.prototype.hasOwnProperty.call(cr, key)) {
            text += '"' + fieldsToExport[key] + '",'
          }
        }

        text += '\n';
      }

      // Generate Detail Row
      for (const key in fieldsToExport) {
        if (Object.prototype.hasOwnProperty.call(cr, key)) {
          if (cr[key]) {
            const value = cr[key].toString();
            text += '"' + value.replace(/(\r\n|\n|\r)/gm, ' ').replace(/(\t)/gm, ' ').replace(/(")/gm, '\'') + '",';
          } else {
            text += '"",';
          }
        }
      }

      text += '\n';
      rowNumber += 1;

    }

    text += 'Total Records: ' + rowNumber;
    const data = new Blob([text], { type: 'text/csv' });

    // If we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks.
    if (textFile !== null) {
      window.URL.revokeObjectURL(textFile);
    }

    textFile = window.URL.createObjectURL(data);
    const aElem = document.createElement('a');
    aElem.href = textFile;
    aElem.download = 'careroster-export.csv';
    aElem.click();
    this.notifications.showAlert('Export Complete', 'Check your Downloads folder for a file named "careroster-export.csv".', 'success');
  }

}
