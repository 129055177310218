import { UserState } from "./../../../models/_core/user-state";
import { UsersService } from "../../users/users.service";
import { HelperUtilitiesService } from "src/app/services/_core/helper-utilities/helper-utilities.service";
import { AuthState } from "src/app/models/_core/auth-state";
import { VerlockerService } from "../verlocker/verlocker.service";
import { NavController } from "@ionic/angular";
import { User } from "src/app/models/user";
import { StorageService } from "../storage/storage.service";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/_core/auth/auth.service";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  UrlTree,
} from "@angular/router";
import { BhAnalyticsService } from "../bhanalytics/bhanalytics.service";
import { first } from "rxjs/operators";
import * as moment from "moment";

/**
 * ID: bh-login-route-guard-service
 * Name: BH Login Route Guard Service
 * Description: Service used to protect user-restricted pages and routes
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented userState
 */
@Injectable({
  providedIn: "root",
})
export class LoginRouteGuardService implements CanActivate {
  env = environment;
  private authState: AuthState;
  private authUser: User;

  constructor(
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService,
    private analytics: BhAnalyticsService,
    private navCtrl: NavController,
    private verlocker: VerlockerService,
    private helpers: HelperUtilitiesService,
    private usersService: UsersService
  ) { }

  /**
   * Checks if user is logged in and if not, reroutes to login
   * @param route Activated Route Snapshot
   */
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.authUser = this.authService.getAuthUser();
    const validSession = await this.validateSession();
    const hasAccess = await this.validateAccess(route);
    const allowNavigate =
      this.authUser &&
      this.authUser.userId !== null &&
      validSession &&
      hasAccess;
    if (!allowNavigate) {
      this.authService.targetUrl = window.location.pathname;
      console.log(
        "loginRouteGuard: navigating to login",
        this.authService.targetUrl
      );
      return this.navCtrl.navigateRoot("/login");
    }
    return allowNavigate;
  }

  async validateSession(): Promise<boolean> {
    // Check app version
    this.verlocker.setCheckVersion(true);
    // Check token and existing user
    if (!this.authUser || !this.authUser.userId) {
      // Check if token storing is allowed
      if (this.env.storeToken) {
        try {
          const userState: UserState = await this.storageService.getData(
            "userState"
          );
          const userData = userState.authUser;
          // Check for outdated session
          if (
            userState.authState === AuthState.LOGGED_IN &&
            userState.sessionAppVersion &&
            userState.sessionAppVersion ===
            this.env.appVersion + "-" + this.env.env
          ) {
            // Check for userData and token
            if (
              userData
              // userData.token
            ) {
              // this.authUser.token = userData.token;
              this.authService.setAuthUser(this.authUser);
              this.authService.userState = userState;
              this.authService.userState.sessionRefreshed =
                moment().format("M/D/YYYY HH:mm");
              try {
                // Check if user is still active in user table
                const userRes = await this.usersService
                  .getById(userData.userId)
                  .toPromise();
                // Check if user is still AD valid. if not go to login
                if (userRes) {
                  this.authUser = userData;
                  try {
                    // TODO: commented out for development only. 
                    const validUser = this.authUser;
                    // : User = await this.authService
                    // .validateUser()
                    // .toPromise();
                    if (!validUser.userId) {
                      this.analytics.customEvent(
                        "auto-login",
                        "Invalid login",
                        validUser.userId
                      );
                      // console.log('loginRouteGuard: user invalid');
                      return Promise.resolve(false);
                    } else {
                      this.analytics.analyticsData.userid = validUser.userId;
                      // Setting authenticated to false because user used stored token
                      //this.authUser.authenticated = false;
                      this.authService.setAuthUser(this.authUser);
                      this.analytics.customEvent(
                        "auto-login",
                        "Valid login",
                        validUser.userId
                      );
                      return Promise.resolve(true);
                    }
                  } catch (err) {
                    console.log("loginRouteGuard: Validate User Error", err);
                    return Promise.resolve(false);
                  }
                } else {
                  console.log("loginRouteGuard: No user record found");
                  return Promise.resolve(false);
                }
              } catch (err) {
                console.log("loginRouteGuard: User Service Error", err);
                return Promise.resolve(false);
              }
            } else {
              // Check if has user/token, if not go to login
              console.log("loginRouteGuard: No token, go to login");
              return Promise.resolve(false);
            }
          } else {
            console.log("loginRouteGuard: Outdated session, go to login");
            return Promise.resolve(false);
          }
        } catch (err) {
          // Check if has user/token, if not go to login
          console.log("loginRouteGuard: userData error", err);
          return Promise.resolve(false);
        }
      } else {
        console.log("loginRouteGuard: No stored tokens allowed, login");
        // return Promise.resolve(true);
        // return Promise.resolve(false);
      }
    } else {
      // if (!this.profile || this.profile.profileStatus !== 'Loaded') {
      //   this.authUser.authenticated = false;
      // }
      // Do nothing; all set
      // console.log('loginRouteGuard: OK to go to page');
      return Promise.resolve(true);
    }
  }

  async validateAccess(route: ActivatedRouteSnapshot): Promise<boolean> {
    const authUser = this.authService.getAuthUser();
    const roles = route.data.roles;
    let hasAccess = false;
    if (roles !== undefined && roles && roles.length > 0) {
      roles.forEach((role) => {
        if (authUser.role === role) {
          hasAccess = true;
        }
      });
    } else {
      hasAccess = true;
    }
    return Promise.resolve(hasAccess);
  }
}
