import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/models/role';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { Shift } from 'src/app/models/shift';
import { SelectOption } from 'src/app/models/_core/select-option';
import { RoleGroupsService } from 'src/app/services/role-groups/role-groups.service';
import { RoleService } from 'src/app/services/role/role.service';
import { RosterService } from 'src/app/services/roster/roster.service';
import { ShiftsService } from 'src/app/services/shifts/shifts.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
@Component({
  selector: 'app-edit-roster',
  templateUrl: './edit-roster.page.html',
  styleUrls: ['./edit-roster.page.scss'],
})
export class EditRosterPropertiesPage implements OnInit {
  @Input() rolSeq: number;
  @Input() roster: Roster;
  @Input() schSeq: number;
  @Input() schedDate: string;

  subscriptions: Subscription[] = [];

  roleObj: Role = {
    name: '',
    rgpSeq: 1,
    active: 1
  }
  // Define form
  form1: FormGroup = this.formBuilder.group({
    rosterName: [null, Validators.required],
    shift: [null, Validators.required],
    roleGroup: [null, Validators.required],
    roleName: [null, Validators.required],
    startTime: [null, Validators.required],
    endTime: [null, Validators.required],
    postDate: [null, Validators.required],
    stopDate: [null, Validators.required],
    active: [null, Validators.required],
    datePicker: [null, Validators.required],
  });

  // Define validation messages
  validationMessages = {
    rosterName: [{ type: 'required', message: "A roster name is required." }],
    active: [{ type: 'required' }]
  };
  // Submission State
  submitAttempted = false;

  // View only State
  viewOnly = true;

  // Options for Active Select

  setActive: SelectOption[] = [
    {
      label: 'Yes',
      value: 1
    },
    {
      label: 'No',
      value: 0
    }
  ];

  nowTimestamp = moment().format('M/D/YYYY');
  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private rosterService: RosterService
  ) { }

  ngOnInit() { }

  ionViewWillEnter() {
    console.log('ionViewDidEnter: rosSeq', this.roster.rosSeq);
    this.loadRoster();
  }

  async loadRoster() {
    try {
      const res = await this.rosterService.getRosterBySeq(this.roster.rosSeq).toPromise();
      if (res) {
        console.log('current roster', res);
        this.roster = res;
        this.form1.controls.rosterName.setValue(this.roster.name);
        this.form1.controls.active.setValue(this.roster.active);    
        //console.log('roster', this.roster);

        return Promise.resolve(true);
      }
    } catch (err) {
      this.notificationService.handleError(err, 'loadCurrentRoster()')
    }
  }

  dismiss() {
    this.analytics.clickEvent('feedback: dismiss', '');
    this.modalCtrl.dismiss();
  }

  async updateExistingRoster() {
    const rosterFormName = this.form1.controls.rosterName.value;
    const activeFormValue = this.form1.controls.active.value;
    try {
      console.log('Current roster', this.roster);
      this.notificationService.startLoading();
      const updateRoster: Roster = {
        name: rosterFormName,
        active: activeFormValue,
        rosSeq: this.roster.rosSeq
      }
      const res = await this.rosterService
        .updateRoster(updateRoster.rosSeq, updateRoster)
        .toPromise();
      this.notificationService.stopLoading();
      this.notificationService.showAlert(
        "Roster Saved",
        "This roster has been successfully updated.",
        "success"
      );
      console.log("updated roster", res);
      this.modalCtrl.dismiss({ isUpdated: true });
    } catch (err) {
      this.notificationService.handleError(err, "updateExistingRoster()");
    }
  }

  saveAndExit() {
    this.modalCtrl.dismiss();
  }

  cancel() {
    this.analytics.clickEvent('feedback: cancel', '');
    this.modalCtrl.dismiss();
  }

}
