import { BhContentEmptyComponentModule } from 'src/app/components/_core/bh-content-empty/bh-content-empty.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AssignTeamMemberNewPageRoutingModule } from './assign-team-member-new-routing.module';

import { AssignTeamMemberNewPage } from './assign-team-member-new.page';
import { BhContentSearchComponentModule } from 'src/app/components/_core/bh-content-search/bh-content-search.component.module';
import { BhUserBadgeComponentModule } from 'src/app/components/_core/bh-user-badge/bh-user-badge.component.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { BhSpinnerComponentModule } from 'src/app/components/_core/bh-spinner/bh-spinner.component.module';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    AssignTeamMemberNewPageRoutingModule,
    BhContentSearchComponentModule,
    BhUserBadgeComponentModule,
    PipesModule,
    BhSpinnerComponentModule,
    BhContentEmptyComponentModule,
    BhInputComponentModule
  ],
  declarations: [AssignTeamMemberNewPage]
})
export class AssignTeamMemberNewPageModule {}
