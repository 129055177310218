import { Pipe, PipeTransform } from '@angular/core';
import { HelperUtilitiesService } from '../services/_core/helper-utilities/helper-utilities.service';
import * as moment from 'moment';
/**
 * ID: bh-format-pipe
 * Name: Format Date Pipe
 * Type: Pipe
 * Description: Formats date string using helper utilities' formatDate
 * Implementation:
 *    1.) Import pipes.module.ts (PipesModule) into page or component's module.ts
 *    2.) In HTML template, display desired variable with pipe reference:  {{ eventDateIso | formatDate: 'YYYY-MM-DD HH:mm:ss' }}
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-13 - MW - v1: Initial dev
 */


@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {
  constructor(
    // public helpers: HelperUtilitiesService
) { }

transform(time: string, parseFormat: string): any {

    const timeMoment = moment(time, parseFormat);

    return timeMoment.format('h:mm a');

    // let hour = (time.split(' '))[0]
    // console.log('hour', hour);
    // let min = (time.split(' '))[1]
    // console.log('min', min);
    // let part = hour >= 12 ? 'pm' : 'am';

    // if(parseInt(hour) == 0) {
    //     hour = 12;
    //     min = (min+'').length == 1 ? `0${min}` : min;
    //     hour = hour > 12 ? hour - 12 : hour;
    //     hour = (hour+'').length == 1 ? `0${hour}` : hour;
    // }
    
    // return `${hour}:${min} ${part}`

  }

}
