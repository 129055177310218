import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { BhGridColumnDefinition } from 'src/app/models/_core/bh-grid-column-definition';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { RosterService } from 'src/app/services/roster/roster.service';
import { ShiftsService } from 'src/app/services/shifts/shifts.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { StorageService } from 'src/app/services/_core/storage/storage.service';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/models/role';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { SchRoleAssignmentUser } from 'src/app/models/sched-role-assign-user';
import { SchRoleAssignment } from 'src/app/models/sched-role-assignment';
import { Shift } from 'src/app/models/shift';
import { RoleGroupsService } from 'src/app/services/role-groups/role-groups.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { SelectOption } from 'src/app/models/_core/select-option';
import { AddRolePage } from '../add-role/add-role.page';
import { RoleService } from 'src/app/services/role/role.service';
import { EditRolePage } from '../edit-role/edit-role.page';
@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.page.html',
  styleUrls: ['./manage-roles.page.scss'],
})
export class ManageRolesPage implements OnInit, OnDestroy{
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: number;
  @Input() selectedShift: Shift;
  @Input() roleGroup: RoleGroup;
  @Input() roster: Roster;

  columnDefinitions: BhGridColumnDefinition[] = [
    {
      fieldName: 'name', columnLabel: 'Role', ionIcon: 'id-card-outline', ionIconColor: null, showIconArg: '',
      showIconOnly: false, alwaysShowIcon: true, showColumn: true
    },
    {
      fieldName: 'active', columnLabel: 'Active', ionIcon: '', ionIconColor: null, showIconArg: '',
      showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'addDate', columnLabel: 'Created', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'rolSeq', columnLabel: 'ID', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'staffingType', columnLabel: 'Staffing', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
  ];
  subscriptions: Subscription[] = [];
  selectedShiftSubject: Shift;
  selectRosterSubject: Roster;
  selectedRoleGroup: RoleGroup;
  selectedRole: Role;
  schRoleAssignments: SchRoleAssignment[];
  schRoleAssignUsers: SchRoleAssignmentUser[];
  roleGroups: RoleGroup[] = [];
  roles: Role[] = [];
  shifts: Shift[] = [];
  shiftTimes: string[] = [];
  shfSeq: number;
  rgpSeq: number;
  shiftSelection: SelectOption[] = [{}];
  roleGroupSelection: SelectOption[] = [{}];
  roleSelection: SelectOption[] = [{}];
  searchTerm = '';
  isFiltering = false;
  initRoles: Role[];
  hasBeenChanged = false;
  form1: FormGroup = this.formBuilder.group({
    rosterName: [null],
    shift: [null, Validators.required],
    roleGroup: [null, Validators.required],
    role: [null, Validators.required],
    assignment: [null, Validators.required],
    startTime: [null, Validators.required],
    endTime: [null, Validators.required],
    postDate: [null, Validators.required],
    stopDate: [null, Validators.required],
    active: [null, Validators.required],
    comments: [null, Validators.required],
  });

  validationMessages = {
    rosterName: [{ type: "optional" }],
    role: [{ type: "required", message: "A role name is required." }],
    name: [{ type: "required", message: "A shift name is required." }],
    shift: [{ type: "required", message: "Please select a shift time." }],
    roleGroup: [{ type: "required", message: "Please select a shift to view associated role groups" }],
    startTime: [{ type: "required", message: "Start Time is required." }],
    assignment: [{ type: "required", message: "Start Time is required." }],
    comments: [{ type: "optional" }],
    active: [{ type: "optional" }],
  };
  // Submission State
  submitAttempted = false;

  // View only State
  viewOnly = true;


  constructor(
    private shiftService: ShiftsService,
    private roleGroupService: RoleGroupsService,
    private roleService: RoleService,
    private rosterService: RosterService,
    public notificationsService: NotificationsService,
    private menu: MenuController,
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    console.log('rosSeq', this.rosSeq);
    //this.loadTableData();
  }

  ionViewDidEnter() {
    this.loadTableData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
      sub = null;
    });
  }

  async loadTableData() {
    await this.getAllShifts(this.rosSeq);
    //await this.getRoleGroups(this.shfSeq);
    //await this.getRole(this.rgpSeq);
    //this.form1.controls.shift.setValue();
    //this.form1.controls.roleGroup.setValue(this.roleGroup.rgpSeq);
    if (this.subscriptions.length === 0) {
      this.subscriptions = [
        this.form1.controls.shift.valueChanges.subscribe((val) => {
          if (val) {
            console.log('shift form', val);
            this.shfSeq = val;
            this.getRoleGroups(this.shfSeq);
          }
          this.roleGroups = [];
          this.roleGroupSelection = [];
          this.roles = [];
          this.roleSelection = [];
        }),
        this.form1.controls.roleGroup.valueChanges.subscribe((val) => {
          if (val) {
            console.log("rg value", val);
            this.rgpSeq = val;
            this.getRoles(this.rgpSeq);
          }
          this.roles = [];
          this.roleSelection = [];
        })
      ];
    }
  }

  async getAllShifts(rosSeq): Promise<boolean> {
    try {
      let res = await this.shiftService.getAllShifts(rosSeq, 0).toPromise();
      if (res) {
        this.shifts = res.shifts;
        console.log("shifts", this.shifts);
        this.shifts.forEach((shift) => {
          const startTime = moment(shift.startTime, "hh:mm a").format(
            "hh:mm a"
          );
          const endTime = moment(shift.endTime, "hh:mm a").format("hh:mm a");
          const shiftStatus = this.getShiftStatus(shift);
          const shiftTime = startTime + " - " + endTime + shiftStatus;
          this.shiftSelection.push({ label: shiftTime, value: shift.shfSeq });
          this.shiftSelection = this.shiftSelection.filter(
            (ss) => Object.keys(ss).length !== 0
          );
        });
        return Promise.resolve(true);
      }
    } catch (err) {
      this.notificationsService.handleError(err, "getAllShifts()");
    }
  }

  async getRoleGroups(shfSeq: number): Promise<boolean> {
    try {
      let res = await this.roleGroupService
        .getAllRoleGroups(this.rosSeq, shfSeq)
        .toPromise();
      if (res) {
        this.roleGroups = res.roleGroups;
        this.roleGroups.forEach((rg) => {
          this.roleGroupSelection.push({ label: rg.name, value: rg.rgpSeq });
          this.roleGroupSelection = this.roleGroupSelection.filter(
            (rgs) => Object.keys(rgs).length !== 0
          );
        });
      }
      console.log("roleGroups", this.roleGroups);
      return Promise.resolve(true);
    } catch (err) {
      this.notificationsService.handleError(err, "getRoleGroups()");
      throw err;
    }
  }

  async getRoles(rgpSeq: number): Promise<boolean> {
    try {
      let res = await this.roleService
        .getAllRoles(this.rosSeq, this.shfSeq, rgpSeq)
        .toPromise();
      if (res) {
        this.roles = res.roles;
        this.initRoles = this.roles;
      }
      console.log("roles", this.roles);
      return Promise.resolve(true);
    } catch (err) {
      this.notificationsService.handleError(err, "getRoles()");
    }
  }

  getShiftStatus(shift: Shift) {
    let currentMoment = moment();
    const postDateMoment = moment(shift.postDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss');
    const stopDateMoment = (shift.stopDate) ? moment(shift.stopDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss') : null;
    const isLive = postDateMoment <= currentMoment && (!stopDateMoment || stopDateMoment >= currentMoment);
    if(!shift.active) {
        return ' (inactive)';
    } else if(!isLive) {
        return ' (expired)';
    } else {
      return '';
    }
  }

  async addRole() {
    this.analytics.clickEvent("test-open-assignment", "clicked");
    const modal = await this.modalCtrl.create({
      component: AddRolePage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        rosSeq: this.rosSeq,
        shift: this.selectedShift,
        roleGroup: this.roleGroup,
        schSeq: this.schSeq,
        schedDate: this.schedDate,
        componentLabel: 'Roles',
        roster: this.roster
      }
    });
    modal.present();
    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.isSaved) {
        if(this.shfSeq !== undefined && this.rgpSeq !== undefined) {
          this.getRoles(this.rgpSeq);
          this.hasBeenChanged = true;
        }
      }
    });
  }

  getRole(role) {
    console.log('role', role);
    this.selectedRole = role;
    this.editRole();
  }

  async editRole() {
    this.analytics.clickEvent("manage-role-groups", "clicked");
    const modal = await this.modalCtrl.create({
      component: EditRolePage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        shfSeq: this.shfSeq,
        rgpSeq: this.rgpSeq,
        role: this.selectedRole,
        rosSeq: this.rosSeq,
        schSeq: this.schSeq,
        schedDate: this.schedDate,
        componentLabel: "Role",
        roster: this.roster
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.isUpdated) {
        //this.rgpSeq = data.data.rgpSeq;
        //this.shfSeq = data.data.shfSeq;
        if(this.shfSeq !== undefined && this.rgpSeq !== undefined) {
          this.getRoles(this.rgpSeq);
          this.hasBeenChanged = true;
        }
      }
    });
    modal.present();
  }


  dismiss() {
    this.analytics.clickEvent('feedback: dismiss', '');
    if(this.hasBeenChanged !== false) {
      this.modalCtrl.dismiss({hasBeenChanged: this.hasBeenChanged});
    } else {
        this.modalCtrl.dismiss();
    }
  }

  filter(ev) {
    if (ev) {
      this.isFiltering = true;
      const term = ev.toLowerCase().trim();
      this.roles = this.roles.filter(r =>
        r.name.toLowerCase().indexOf(term) > -1 ||
        r.rolSeq.toString().indexOf(term) > -1
      );
    } else {
      this.searchTerm = '';
      this.isFiltering = false;
      this.roles = this.initRoles;
    }
  }

}
