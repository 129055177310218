import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PopoverUserPermissionPageRoutingModule } from './popover-user-permission-routing.module';

import { PopoverUserPermissionPage } from './popover-user-permission.page';
import { BhContentSearchComponentModule } from 'src/app/components/_core/bh-content-search/bh-content-search.component.module';
import { BhUserBadgeComponentModule } from 'src/app/components/_core/bh-user-badge/bh-user-badge.component.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PopoverUserPermissionPageRoutingModule,
    BhContentSearchComponentModule,
    BhUserBadgeComponentModule,
    PipesModule
  ],
  declarations: [PopoverUserPermissionPage]
})
export class PopoverUserPermissionPageModule {}
