import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Roster } from 'src/app/models/roster';
import { Shift } from 'src/app/models/shift';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftsService {
  env = environment;
  roster: Roster;
  shift: Shift;
  shiftSubject = new BehaviorSubject<Shift>(null);
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) {
    this.shift = {};
    this.roster = {}
  }

  getAllShifts(rosSeq, activeOnly: 0 | 1) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts?activeOnly=${activeOnly}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        this.shift = data;
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "shift-service.getShift(): " + url
        );
        return of(err);
      })
    );
  }

  getSingleShift(rosSeq: number, shfSeq: number) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts/${shfSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "shift-service.getSingleShift(): " + url
        );
        return of(err);
      })
    );
  }

  createShift(rosSeq, shifts: Shift) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts`;
    console.log("shift service: shift created", shifts);
    const body = shifts;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "shift-service.createShift(): " + url
        );
        return of(err);
      })
    );
  }

  updateShift(rosSeq, shfSeq, shifts: Shift) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts/${shfSeq}`;
    const body = shifts;
    return this.http.put(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "shift-service.updateShift(): " + url
        );
        return of(err);
      })
    );
  }

  copyShift(rosSeq, shfSeq, shfSeqFrom, shfSeqTo, dateFrom, dateTo, schSeqFrom, schSeqTo) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts/${shfSeq}/copy`;
    const body = { shfSeqFrom, shfSeqTo, dateFrom, dateTo, schSeqFrom, schSeqTo };
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "shift-service.copyShift(): " + url
        );
        return of(err);
      })
    );
  }

  setShiftSubject(shiftState: Shift) {
    this.shiftSubject.next(shiftState);
  }

  getShiftSubject(): Shift {
    console.log('shifts from subject', this.shiftSubject.getValue());

    return this.shiftSubject.getValue();
  }


}
