import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ManageRoomsPageRoutingModule } from './manage-rooms-routing.module';

import { ManageRoomsPage } from './manage-rooms.page';
import { BhGridComponentModule } from 'src/app/components/_core/bh-grid/bh-grid.component.module';
import { BhContentSearchComponentModule } from 'src/app/components/_core/bh-content-search/bh-content-search.component.module';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ManageRoomsPageRoutingModule,
    BhContentSearchComponentModule,
    BhGridComponentModule,
    BhInputComponentModule,
    ReactiveFormsModule
  ],
  declarations: [ManageRoomsPage]
})
export class ManageRoomsPageModule {}
