import { EmployeeBoardShiftResponse } from './../../models/employee-board-shift-response';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { Schedule } from 'src/app/models/schedule';
import { Shift } from 'src/app/models/shift';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  env = environment;
  roster: Roster;
  shift: Shift;
  schedule: Schedule;
  desiredScheduleDate: string;
  rosSeq: number;
  dateChosen = new BehaviorSubject<string>(null);
  scheduleSubject = new BehaviorSubject<Schedule>(null);

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) {
    this.shift = {};
    this.roster = {};
   }

  private jsonURL = 'assets/data/response.json';

  // getScheduleData(): Observable<Schedule> {
  //   const url = this.jsonURL;
  //   return this.http.get<{schedules: Schedule[], shifts: Shift[]}>(url)
  //   .pipe(
  //     tap((res: any) => {
  //       if (res.x_status === 'E') {
  //         this.notifications.handleError(res, 'Error');
  //       }
  //       return res;
  //     })
  //   );
  // }

  getEmployeeBoardShifts(rosSeq, schDate): Observable<EmployeeBoardShiftResponse> {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/shift?schDate=${schDate}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "schedule-service.getEmployeeBoardShifts(): " + url
        );
        return of(err);
      })
    );
  }


  getScheduleByDate(rosSeq: number, schedDate: string, activeOnly: number, status: string) {
    // Note: Send PUBLISHED,DRAFT as Status Query to pull both together
    const statusQuery =  status ? `&status=${status}` : ``;
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules?schedDate=${schedDate}&activeOnly=${activeOnly}${statusQuery}`;
    return this.http.get(url).pipe(
      map((data: any) => {
          return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "schedule-service.getScheduleByDate(): " + url
        );
        return of(err);
      })
    );
  }

  getSingleSchedule(rosSeq: number, schSeq: number) {
    const url = environment.apiUrl + '/rosters/' + rosSeq + '/schedules/' + schSeq;
    const body = {};
    return this.http.get(url).pipe(
      map((data: any) => {
        this.schedule = data;
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "schedule-service.getSchedule(): " + url
        );
        return of(err);
      })
    );
  }

  getLatestScheduleSeq(rosSeq: number, schedDate: string) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/latest?schedDate=${schedDate}`;
    return this.http.get(url).pipe(
      map((data: any) => {
          return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "schedule-service.getLatestScheduleSeq(): " + url
        );
        return of(err);
      })
    );
  }


  createSchedule(rosSeq, schedule: Schedule) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/`;
    console.log("schedule service: create", schedule);
    const body = schedule;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        console.log("data returned", data);
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "schedule-service.createSchedule(): " + url
        );
        return of(err);
      })
    );
  }

  saveSchedule(rosSeq, schSeq, schedule: Schedule) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/${schSeq}`;
    const body = schedule;
    return this.http.put(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "schedule-service.saveSchedule(): " + url
        );
        return of(err);
      })
    );
  }

  updateSchedule(rosSeq, schSeq, schedule: Schedule) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/${schSeq}`;
    const body = schedule;
    return this.http.put(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "schedule-service.saveSchedule(): " + url
        );
        return of(err);
      })
    );
  }

  copySchedule(rosSeq: number, schSeq: number, newSchedDate: string) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/${schSeq}/copy?newSchedDate=${newSchedDate}`;
    //const body = schedule;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "schedule-service.copySchedule(): " + url
        );
        return of(err);
      })
    );
  }

  setSelectedDate(schDate: string) {
      const date = moment(schDate).format("MM/DD/YYYY");
      this.dateChosen.next(date);
  }

  getSelectedDate() {
    return this.dateChosen.getValue();
  }

  // getShiftBySchedDate(schDate: string, schedule: Schedule[]): Shift[] {
  //   const matches = schedule.filter(s => s.scheduleDate === schDate);
  //   return matches;
  // }

  getParamValue(): number {
      return this.rosSeq;
  }

  setParamValue(rosSeq:number) {
      this.rosSeq = rosSeq;
  }


  setScheduleSubject(scheduleState: Schedule) {
    this.scheduleSubject.next(scheduleState);
  }

  getScheduleSubject(): Schedule {
    console.log("schedule from subject", this.scheduleSubject.getValue());
    return this.scheduleSubject.getValue();
  }

  setDesiredScheduleDate(date: string) {
    this.desiredScheduleDate = date;
  }



  

}
