import { FormatStatePipe } from './format-state.pipe';
import { FormatSsnPipe } from './format-ssn.pipe';
import { FormatPhonePipe } from './format-phone.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { SafePipe } from './safe.pipe';
import { NgModule } from '@angular/core';
import { FormatTimePipe } from './format-time.pipe';
import { FilterPipe } from './filter.pipe';
import { TruncatePipe } from './truncate.pipe';
import { FilterUniquePipe } from './filter-unique';
import { SecondsToMinutesPipe } from './seconds-to-minutes.pipe';
import { FormatDecimalPipe } from './format-decimal.pipe';
import { InputTypeLabelPipe } from './input-type-label.pipe';

/**
 * ID: bh-pipes-module
 * Name: BH Pipes Module
 * Type: Module
 * Description: Modules that contains all project pipes for formatting data in the HTML template.
 * Implementation:
 *    1.) Import pipes.module.ts (PipesModule) into page or component's module.ts
 *    2.) In HTML template, display desired variable with pipe reference. All pipes are included in this module.
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-13 - MW - v1: Initial dev
 */
@NgModule({
    declarations: [
        SafePipe,
        FormatDatePipe,
        FormatPhonePipe,
        FormatSsnPipe,
        FormatStatePipe,
        FormatTimePipe,
        FilterPipe,
        TruncatePipe,
        SecondsToMinutesPipe,
        FormatDecimalPipe,
        FilterUniquePipe,
        InputTypeLabelPipe
    ],
    imports: [],
    exports: [
        SafePipe,
        FormatDatePipe,
        FormatPhonePipe,
        FormatSsnPipe,
        FormatStatePipe,
        FormatTimePipe,
        FilterPipe,
        TruncatePipe,
        SecondsToMinutesPipe,
        FormatDecimalPipe,
        FilterUniquePipe,
        InputTypeLabelPipe
    ]
})
export class PipesModule { }

