import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { Shift } from 'src/app/models/shift';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})

export class RoleGroupsService {
  env = environment;
  roster: Roster;
  shift: Shift;
  roleGroups: RoleGroup;
  rgSubject = new BehaviorSubject<RoleGroup[]>(null);
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { 
    
  }
  getAllRoleGroups(rosSeq, shfSeq) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts/${shfSeq}/role-groups`;
    return this.http.get(url).pipe(
      map((data: any) => {
        this.roleGroups = data;
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "role-group-service.getAllRoleGroups(): " + url
        );
        return of(err);
      })
    );
  }

  getSingleRoleGroup(rosSeq, shfSeq, rgpSeq) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts/${shfSeq}/role-groups/${rgpSeq}`
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "roleGroup-service.getSingleRoleGroup(): " + url
        );
        return of(err);
      })
    );
  }

  createRoleGroup(rosSeq, shfSeq, roleGroup: RoleGroup) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts/${shfSeq}/role-groups`;
    console.log("role group service: role group created", roleGroup);
    const body = roleGroup;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "role-groups-service.createRoleGroup(): " + url
        );
        return of(err);
      })
    );
  }

  updateRoleGroup(rosSeq, shfSeq, rgpSeq, roleGroup: RoleGroup) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts/${shfSeq}/role-groups/${rgpSeq}`;
    const body = roleGroup;
    return this.http.put(url,body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "role-group-service.updateRoleGroup(): " + url
        );
        return of(err);
      })
    );
  }

  setRGSubject(rgState: RoleGroup[]) {
    this.rgSubject.next(rgState);
  }

  getRGSubject(): RoleGroup[] {
    console.log("role group from subject", this.rgSubject.getValue());

    return this.rgSubject.getValue();
  }
}
