import { Component, Input, OnInit } from "@angular/core";
import { MenuController, ModalController, NavController, PopoverController } from "@ionic/angular";
import { BhGridColumnDefinition } from "src/app/models/_core/bh-grid-column-definition";
import { BhAnalyticsService } from "src/app/services/_core/bhanalytics/bhanalytics.service";
import { RosterService } from "src/app/services/roster/roster.service";
import { ShiftsService } from "src/app/services/shifts/shifts.service";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";
import { StorageService } from "src/app/services/_core/storage/storage.service";
import { Subscription } from "rxjs";
import { Role } from "src/app/models/role";
import { RoleGroup } from "src/app/models/role-group";
import { Roster } from "src/app/models/roster";
import { SchRoleAssignmentUser } from "src/app/models/sched-role-assign-user";
import { SchRoleAssignment } from "src/app/models/sched-role-assignment";
import { Shift } from "src/app/models/shift";
import { AuthService } from "src/app/services/_core/auth/auth.service";
import { UsersService } from "src/app/services/users/users.service";
import { User } from "src/app/models/user";
import { SelectOption } from "src/app/models/_core/select-option";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopoverUserPermissionPage } from "../popover-user-permission/popover-user-permission.page";
import { AdUser } from "src/app/models/ad-user";
import { UserAccess } from "src/app/models/user-access";
import { EditIndividualPermissionPage } from "../edit-individual-permission/edit-individual-permission.page";
import { HelperUtilitiesService } from "src/app/services/_core/helper-utilities/helper-utilities.service";

@Component({
  selector: "app-edit-roster-permissions",
  templateUrl: "./edit-roster-permissions.page.html",
  styleUrls: ["./edit-roster-permissions.page.scss"],
})
export class EditRosterPermissionsPage implements OnInit {

  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  @Input() roster: Roster;
  allUsers: User[] = [];
  users: UserAccess[] = [];
  selectedUser: UserAccess;
  employee: AdUser;
  employees: AdUser[] = [];

  
  columnDefinitions: BhGridColumnDefinition[] = [
    {
      fieldName: "fullName",
      columnLabel: "Name",
      ionIcon: "person-outline",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: true,
      showColumn: true,
    },
    {
      fieldName: "userId",
      columnLabel: "User ID",
      ionIcon: "",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: false,
      showColumn: true,
    },
    {
      fieldName: "access",
      columnLabel: "Access Level",
      ionIcon: "",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: false,
      showColumn: true,
    },
    {
      fieldName: "uacSeq",
      columnLabel: "UAC",
      ionIcon: "",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: false,
      showColumn: false,
    },
    {
      fieldName: "active",
      columnLabel: "Active",
      ionIcon: "",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: false,
      showColumn: false,
    },
    // {
    //   fieldName: "title",
    //   columnLabel: "Title",
    //   ionIcon: "",
    //   ionIconColor: null,
    //   showIconArg: "",
    //   showIconOnly: false,
    //   alwaysShowIcon: false,
    //   showColumn: false,
    // }
  ];


// Submission State
submitAttempted = false;
// Define validation messages

// roster: Roster = {
//   name: 'BMC Emergency Department'
// };

shiftObj: Shift = {
  name: '',
  startTime: '',
  endTime: '',
  postDate: '',
  stopDate: '',
  active: 1
};

roleGroupObj: RoleGroup = {
active: 1
}

roleObj: Role = {
name: '',
rgpSeq: 1,
active: 1
}

  subscriptions: Subscription[] = [];
  selectedShiftSubject: Shift;
  selectRosterSubject: Roster;
  selectedRoleGroup: RoleGroup;
  schRoleAssignments: SchRoleAssignment[];
  schRoleAssignUsers: SchRoleAssignmentUser[];
  roleGroups: RoleGroup[] = [];
  roles: Role[] = [];
  shifts: Shift[] = [];
  shiftTimes: string[] = [];
  uniqueUsers: User[] = [];
  shfSeq: number;

  shiftSelection: SelectOption[] = [{}];
  roleGroupSelection: SelectOption[] = [{}];
  accessSelection: SelectOption[] = [
    {
     label: 'Read-Only',
     value: 'READONLY'
    },
    {
      label: 'Editor',
      value: 'EDITOR'
    },
    {
      label: 'Owner',
      value: 'OWNER'
    }
];

  form1: FormGroup = this.formBuilder.group({
    access: [null, Validators.required],
  });

  validationMessages = {
    access: [{ type: "required", message: "An access level is required." }],
  };

  constructor(
    private shiftService: ShiftsService,
    private rosterService: RosterService,
    public notificationService: NotificationsService,
    private formBuilder: FormBuilder,
    private menu: MenuController,
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private storageService: StorageService,
    private authService: AuthService,
    private userService: UsersService,
    private helpers: HelperUtilitiesService,
    private popoverController: PopoverController
  ) {}

  ngOnInit() {
    
  }


  ionViewDidEnter() {
    this.loadTableData();
  }

  async loadTableData() {
    await this.getAllUsersWithAccess();
    // await this.getAllUsers();
  }

  // async getAllUsers(): Promise<boolean> {
  //   try {
  //     const res = await this.userService.getAllUsers().toPromise();
  //     if (res) {
  //       this.allUsers = res.users;
  //       this.allUsers.sort((a, b) => {
  //         return a.fullName > b.fullName ? 1 : a.fullName === b.fullName ? 0 : -1;
  //       });
  //       console.log("all users", this.allUsers);
  //     }
  //     return Promise.resolve(true);
  //   } catch (err) {
  //     this.notificationService.handleError(err, "getAllUsersWithAccess()");
  //   }
  // }

  async getAllUsersWithAccess(): Promise<boolean> {
    try {
      
      const res = await this.userService.getAllUsersByAccess(this.rosSeq).toPromise();
      if (res) {
        this.users = res.userAccess;
        this.users = this.users.filter(u => u.active === 1);
        this.users.forEach( user => {
          const firstName = this.helpers.getFirstName(user.fullName);
          const lastName = this.helpers.getLastName(user.fullName);
          return user.fullName = lastName + ', ' + firstName;
        })
        this.users.sort((a, b) => {
          return a.fullName > b.fullName ? 1 : a.fullName === b.fullName ? 0 : -1;
        });
        const uniqueUsers = this.filterUniqueUsers();
        this.users = uniqueUsers;
        console.log("current users", this.users);
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, "getAllUsersWithAccess()");
    }
  }

  filterUniqueUsers() {
    const seen = new Set();
    const uniqueUsers = this.users.filter(user => {
      const duplicate = seen.has(user.userId.toLowerCase());
      seen.add(user.userId.toLowerCase());
      return !duplicate;
    });
    return uniqueUsers;
  }

async getUser(ev) {
    const popover = await this.popoverController.create({
      component: PopoverUserPermissionPage,
      event: ev,
      cssClass: "custom-permission-popover",
      componentProps: {
      },
    });
    popover.onDidDismiss().then((data) => {
      if (data.data && data.data.isSelected && data.data.employee) {
        console.log("get employee rom popover", data.data.employee);
        this.employee = data.data.employee;
      }
    });
    await popover.present();
  }

  async addPermissions(employee: AdUser) {
    if (this.form1.valid) {
    const userAccess = this.form1.controls.access.value;
    console.log('user access', userAccess);
    console.log('employee', employee);
    try {
      const newUser: User = {
        userId: employee.userId,
        fullName: employee.fullName,
        role: 'USER',
        active: 1
      }
      const userExists = this.checkIfUserExists(newUser);
      console.log('does user exist?', userExists);
      
      if(!userExists) {
        console.log('new user', newUser);
        this.notificationService.startLoading();
        const res1 = await this.userService.createUser(newUser).toPromise();
        const newUserAccess: UserAccess = {
          userId: newUser.userId,
          access: userAccess,
          active: 1
        }
        console.log('user access', newUserAccess);
        const res2 = await this.userService.createUserAccess(this.rosSeq, newUserAccess).toPromise();
          this.employee = null;
          this.form1.reset();
          newUserAccess.title = employee.title;
          this.notificationService.stopLoading();
          this.notificationService.showAlert(
            "New User Added",
            "A new user has been successfully added.",
            "success"
          );
          await this.getAllUsersWithAccess();
        console.log("created user", res1);
        console.log("created user access", res2);
      } else {
        this.notificationService.showAlert('Error: Duplicate User', 'This user cannot be added since they already exist on table.', 'danger');
        this.employee = null;
        this.form1.reset();
        this.notificationService.stopLoading();
      }
  } catch (err) {
    this.notificationService.handleError(err, "addPermissions()");
  }
} else {
  console.log('Form invalid', this.form1);
  }
  }

  checkIfUserExists(newUser: User) {
    return this.allUsers.some(users => users.userId.toLowerCase() === newUser.userId.toLowerCase());
  }

  getUserFromTable(user) {
    console.log('user', user);
    this.selectedUser = user;
    this.editUser();
  }

  async editUser() {
    this.analytics.clickEvent("manage-role-groups", "clicked");
    const modal = await this.modalCtrl.create({
      component: EditIndividualPermissionPage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        user: this.selectedUser,
        rosSeq: this.rosSeq,
        schSeq: this.schSeq,
        schedDate: this.schedDate,
        componentLabel: "Permission",
      },
    });
    modal.onDidDismiss().then( async (data) => {
      if (data.data && data.data.isUpdated) {
        await this.getAllUsersWithAccess();
      }
    });
    modal.present();
  }

  dismiss() {
    this.analytics.clickEvent("feedback: dismiss", "");
    this.modalCtrl.dismiss();
  }
}
