import { Subscription } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SelectOption } from 'src/app/models/_core/select-option';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import * as moment from 'moment';

@Component({
  selector: 'app-copy-day-modal',
  templateUrl: './copy-day-modal.page.html',
  styleUrls: ['./copy-day-modal.page.scss'],
})
export class CopyDayModalPage implements OnInit {
  @Input() mode: 'new-day' | 'copy' = 'new-day';

  // Define form
  form1: FormGroup = this.formBuilder.group({
    copyOption: [null, Validators.required],
    datePicker: [null],
  });

  // Define validation messages
  validationMessages = {
    copyOption: [{ type: "required", message: "Choose an option to continue." }],
    datePicker: [{ type: "required", message: "Choose a day assignments to copy from." }],
  };
  // Submission State
  submitAttempted = false;

  subs: Subscription[] = [];

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.subscribeToControls();
    if (this.mode === 'copy') {
      this.form1.controls.copyOption.setValue('C');
    }
  }

  ionViewDidLeave() {
    this.subs.forEach(s => {
      s.unsubscribe();
      s = null;
    });
  }

  subscribeToControls() {
    this.subs.push(
      this.form1.controls.copyOption.valueChanges.subscribe(val => {
        if (val === 'C') {
          this.form1.controls.datePicker.setValidators([Validators.required]);
          this.form1.controls.datePicker.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        } else {
          this.form1.controls.datePicker.clearValidators;
          this.form1.controls.datePicker.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
        console.log('subscribeToControls: copyOption changed', val, this.form1);
      })
    )
  }

  continue() {
    this.submitAttempted = true;
    if (this.form1.valid) {
      const data = {
        action: this.form1.controls.copyOption.value,
        date: (this.form1.controls.copyOption.value === 'C') ? this.form1.controls.datePicker.value.format('MM/DD/YYYY') : null
      };
      this.modalCtrl.dismiss(data);
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

}
