import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-employee-board-update-role-groups',
  templateUrl: './employee-board-update-role-groups.page.html',
  styleUrls: ['./employee-board-update-role-groups.page.scss'],
})
export class EmployeeBoardUpdateRoleGroupsPage implements OnInit {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  saveAndExit() {
    this.modalCtrl.dismiss();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
