import { RosterMorePopoverPageModule } from './pages/roster-more-popover/roster-more-popover.module';
import { CopyDayModalPageModule } from './pages/copy-day-modal/copy-day-modal.module';
import { AES256 } from '@ionic-native/aes-256/ngx';
import { TouchID } from '@ionic-native/touch-id/ngx';
import { FormsModule, FormBuilder } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { VerlockerPageModule } from './pages/_core/verlocker/verlocker.module';
import { Device } from '@ionic-native/device/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Network } from '@ionic-native/network/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { PinCheck } from '@ionic-native/pin-check/ngx';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';
import { InterceptorService } from './services/_core/interceptor/interceptor.service';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AddEditRoleGroupPageModule } from './pages/add-edit-role-group/add-edit-role-group.module';
import { AddRolePageModule } from './pages/add-role/add-role.module';
import { AddRoomPageModule } from './pages/add-room/add-room.module';
import { AddRosterPageModule } from './pages/add-roster/add-roster.module';
import { AssignTeamMemberEditPageModule } from './pages/assign-team-member-edit/assign-team-member-edit.module';
import { EditRoleAssignmentPageModule } from './pages/edit-role-assignment/edit-role-assignment.module';
import { EditRoleGroupPageModule } from './pages/edit-role-group/edit-role-group.module';
import { EditRolePageModule } from './pages/edit-role/edit-role.module';
import { EditRoomPageModule } from './pages/edit-room/edit-room.module';
import { EditRosterPermissionsPageModule } from './pages/edit-roster-permissions/edit-roster-permissions.module';
import { EditRosterPageModule } from './pages/edit-roster-properties/edit-roster.module';
import { ManageShiftsEditPageModule } from './pages/manage-shifts-edit/manage-shifts-edit.module';
import { EmployeeBoardUpdateRoleGroupsPageModule } from './pages/employee-board-update-role-groups/employee-board-update-role-groups.module';
import { EmployeeBoardUpdateShiftPageModule } from './pages/employee-board-update-shift/employee-board-update-shift.module';
import { ManageRoleGroupsPageModule } from './pages/manage-role-groups/manage-role-groups.module';
import { ManageRolesPageModule } from './pages/manage-roles/manage-roles.module';
import { ManageRoomsPageModule } from './pages/manage-rooms/manage-rooms.module';
import { ManageShiftsPageModule } from './pages/manage-shifts/manage-shifts.module';
import { PopoverAssignmentsPageModule } from './pages/popover-assignments/popover-assignments.module';
import { PopoverDeleteSchedulePageModule } from './pages/popover-delete-schedule/popover-delete-schedule.module';
import { AssignTeamMemberNewPageModule } from './pages/assign-team-member-new/assign-team-member-new.module';
import { PopoverUserPermissionPageModule } from './pages/popover-user-permission/popover-user-permission.module';
import { PopoverViewEmployeePageModule } from './pages/popover-view-employee/popover-view-employee.module';
import { PublishPageModule } from './pages/publish/publish.module';
import { SavePageModule } from './pages/save/save.module';
import { SideMenuPageModule } from './pages/side-menu/side-menu.module';
import { ManageUsersEditPageModule } from './pages/manage-users-edit/manage-users-edit.module';
import { RosterReportsPageModule } from './pages/roster-reports/roster-reports.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    VerlockerPageModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatDialogModule,
    MatMomentDateModule,
    NgxMaskIonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    BrowserAnimationsModule,
    PopoverUserPermissionPageModule,
    PopoverDeleteSchedulePageModule,
    PublishPageModule,
    AssignTeamMemberNewPageModule,
    AddRoomPageModule,
    AddEditRoleGroupPageModule,
    AssignTeamMemberEditPageModule,
    PopoverViewEmployeePageModule,
    PopoverAssignmentsPageModule,
    SavePageModule,
    EmployeeBoardUpdateRoleGroupsPageModule,
    EmployeeBoardUpdateShiftPageModule,
    EditRoomPageModule,
    ManageRoomsPageModule,
    EditRoleAssignmentPageModule,
    EditRolePageModule,
    ManageRolesPageModule,
    EditRoleGroupPageModule,
    ManageRoleGroupsPageModule,
    ManageShiftsEditPageModule,
    ManageShiftsPageModule,
    EditRosterPermissionsPageModule,
    EditRosterPageModule,
    AddRosterPageModule,
    RosterMorePopoverPageModule,
    SideMenuPageModule,
    AddRolePageModule,
    ManageUsersEditPageModule,
    CopyDayModalPageModule,
    RosterReportsPageModule,
    NgbModule
  ],  
  providers: [
    AES256,
    StatusBar,
    SplashScreen,
    Device,
    InAppBrowser,
    Keyboard,
    Network,
    OneSignal,
    PinCheck,
    FormBuilder,
    TouchID,
    LoginRouteGuardService,
    BhAnalyticsRouteGuardService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
