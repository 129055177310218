import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { Report, ReportCriteria } from 'src/app/models/report';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  env = environment;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  getReportsAll(rosSeq, activeOnly: 0 | 1) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/reports?activeOnly=${activeOnly}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "reports-service.getReportsAll(): " + url
        );
        return of(err);
      })
    );
  }

  getReportBySeq(rosSeq: number, repSeq: number) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/reports/${repSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "reports-service.getReportBySeq(): " + url
        );
        return of(err);
      })
    );
  }


  runReport(report: Report) {
    const url = `${environment.apiUrl}/rosters/${report.rosSeq}/reports/${report.repSeq}/run`;

    const body: ReportCriteria = {
      criteria: {
        rosSeq: report.rosSeq,
        schDateFrom: report.qryFromDate,
        schDateTo: report.qryThruDate,
        shifts: [],
        roleGroups: [],
        roles: [],
        assignments: [],
        users: [],
        dutyComments: [],
        comments: report.qryComment ? report.qryComment : ''
      }
    };

    this.prepareAssignmentCriteria(report, body);
    this.prepareUserCriteria(report, body);
    this.prepareDutyCommentCriteria(report, body);

    console.log('reports-service.runReport', body);;

    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "reports-service.getReportBySeq(): " + url
        );
        return of(err);
      })
    );
  }

  createReport(report: Report) {
    const url = `${environment.apiUrl}/rosters/${report.rosSeq}/reports`;
    const body = report;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "reports-service.createReport(): " + url
        );
        return of(err);
      })
    );
  }

  updateReport(report: Report) {
    const url = `${environment.apiUrl}/rosters/${report.rosSeq}/reports/${report.repSeq}`;
    const body = report;
    return this.http.put(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "reports-service.updateReport(): " + url
        );
        return of(err);
      })
    );
  }

  prepareAssignmentCriteria(report: Report, body: ReportCriteria) {
    const shiftParms = report.reportParms.filter(p => p.field === 'SRU_SHF_SEQ');
    for (const sh of shiftParms) {
      if (sh.active) {
        body.criteria.shifts.push(Number(sh.value));
      }
    }

    const roleGroupParms = report.reportParms.filter(p => p.field === 'SRU_RGP_SEQ');
    for (const rg of roleGroupParms) {
      if (rg.active) {
        body.criteria.roleGroups.push(Number(rg.value));
      }
    }

    const roleParms = report.reportParms.filter(p => p.field === 'SRU_ROL_SEQ');
    for (const r of roleParms) {
      if (r.active) {
        body.criteria.roles.push(Number(r.value));
      }
    }

    const assignmentParms = report.reportParms.filter(p => p.field === 'SRU_SRA_SEQ');
    for (const asi of assignmentParms) {
      if (asi.active) {
        body.criteria.assignments.push(Number(asi.value));
      }
    }
  }

  prepareUserCriteria(report: Report, body: ReportCriteria) {
    const userParms = report.reportParms.filter(p => p.field === 'SRU_USR_ID');
    for (const usr of userParms) {
      if (usr.active) {
        body.criteria.users.push(usr.value);
      }
    }
  }

  prepareDutyCommentCriteria(report: Report, body: ReportCriteria) {
    const dutyParms = report.reportParms.filter(p => p.field === 'SRU_COMMENT');
    for (const d of dutyParms) {
      if (d.active) {
        body.criteria.dutyComments.push(d.value);
      }
    }

    if (body.criteria.comments) {
      body.criteria.dutyComments.push(body.criteria.comments);
    }
  }


}
