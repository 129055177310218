import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-bh-form-question-text',
  templateUrl: './bh-form-question-text.component.html',
  styleUrls: ['./bh-form-question-text.component.scss'],
})
export class BhFormQuestionTextComponent implements OnChanges {

  @Input() helperText: string;

  constructor() { }

  ngOnChanges(): void {
      console.log('helper Text', this.helperText);
  }



}
