import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CopyDayModalPageRoutingModule } from './copy-day-modal-routing.module';

import { CopyDayModalPage } from './copy-day-modal.page';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    CopyDayModalPageRoutingModule,
    BhInputComponentModule
  ],
  declarations: [CopyDayModalPage]
})
export class CopyDayModalPageModule {}
