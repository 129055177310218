import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';

@Component({
  selector: 'app-popover-delete-schedule',
  templateUrl: './popover-delete-schedule.page.html',
  styleUrls: ['./popover-delete-schedule.page.scss'],
})
export class PopoverDeleteSchedulePage implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private popoverController: PopoverController,
    private analytics: BhAnalyticsService,
    private notificationService: NotificationsService
  ) { }

  ngOnInit() {
  }


  deleteDraft() {
    this.popoverController.dismiss({hasDeleted: true});
  }

  cancel() {
    this.analytics.clickEvent('feedback: cancel', '');
    this.popoverController.dismiss();
  }

}
