import { UserState } from './../../../models/_core/user-state';
import { environment } from '../../../../environments/environment';
import { StorageService } from '../storage/storage.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError, from } from 'rxjs';
import { catchError, mergeMap, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { NotificationsService } from '../notifications/notifications.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';

/**
 * ID: bh-interceptor-service
 * Name: BH Interceptor Service
 * Description: Service used to manage http requests and auth tokens
 * Version: 4
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented userState
 * 2021-07-27 - MW - v3: Added BH-DONT-BUMP-INACTIVITY-TIMER header handling; will ignore timer bump
 * 2021-10-11 - MW - v4: Fixed issue with stored token session management
 */
@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
    env = environment;

    constructor(
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private router: Router,
        private storageService: StorageService
    ) { }

    // Intercepts all HTTP requests!
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // for a bad un/pw in the login modal, we don't want to navigate from a hybrid guest/authenticated page
        // include this header to continue all login failure actions except redirection to login page
        const stayOnPageWithError = request.headers.get('BH-STAY-ON-PAGE-WITH-ERROR') === 'true';
        request.headers.delete('BH-STAY-ON-PAGE-WITH-ERROR');

        // Don't overwrite existing headers
        const dontAssignHeaders = request.headers.get('BH-DONT-ASSIGN-HEADERS') === 'true';
        request.headers.delete('BH-DONT-ASSIGN-HEADERS');

        // Don't bump activity timer; for interval-based checks
        const dontBumpActivityTimer = request.headers.get('BH-DONT-BUMP-INACTIVITY-TIMER') === 'true';
        request.headers.delete('BH-DONT-BUMP-INACTIVITY-TIMER');

        // Bump inactivity timer
        if (this.env.requireTimeout && !dontBumpActivityTimer) {
            this.authService.bumpInactivityTimer();
        }

        return from(this.getAuthToken()).pipe(
            switchMap(token => {
                //console.log('will use token:', token);
                const clonedReq = this.addToken(request, token, dontAssignHeaders);
                return next.handle(clonedReq).pipe(
                    map((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            // console.log('event--->>>', event);
                        }
                        return event;
                    }),
                    catchError(error => {
                        // Perhaps display an error for specific status codes here already?
                        // console.log(error);
                        if (error instanceof HttpErrorResponse) {
                            const msg = error.message;
                            if (error.status === 401) {
                                if (!stayOnPageWithError) {
                                    this.authService.logout(false);
                                }
                            } else {
                                // this.notificationsService.showAlert(msg);
                                // this.notificationsService.handleError(error, 'interceptor-service.intercept()');
                                console.log('interceptor: error: ', error)
                            }
                        }
                        // Pass the error to the caller of the function
                        return throwError(error);
                    })
                );
            })
        );
    }

    // Adds the token to your headers if it exists
    private addToken(request: HttpRequest<any>, token: any, dontAssignHeaders = false) {
        if (token) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                setHeaders: {
                    // Accept: `application/json`,
                    Authorization: `Bearer ${token}`
                }
            });
            return clone;
        }

        return request;
    }

    async getAuthToken() {
        let user: User;
        const authUser = this.authService.getAuthUser();
        //console.log('authUser', authUser);
        if ((!authUser || !authUser.userId) && this.env.storeToken) {
            //console.log('empty user detected');
            
            const userState = await this.storageService.getData('userState') as UserState;
            if (userState) {
                user = userState.authUser;
                //console.log('Received user from storage', user);
                // if ((!user || !user.token) && this.authService.authUser && authUser.token) {
                //     return authUser.token;
                // }
            } else {
                console.log('no valid user state');
            }
        } else {
            user = authUser;
        }
        //console.log('Interceptor: authUser', user);
        return (user) ? user.token : ((authUser) ? authUser.token : null);
    }

    redirectToLogin() {
        this.router.navigate(['login']);
    }

}
