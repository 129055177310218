import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AssignTeamMemberNewPage } from './assign-team-member-new.page';

const routes: Routes = [
  {
    path: '',
    component: AssignTeamMemberNewPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AssignTeamMemberNewPageRoutingModule {}
