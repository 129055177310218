import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EmployeeBoardUpdateShiftPageRoutingModule } from './employee-board-update-shift-routing.module';

import { EmployeeBoardUpdateShiftPage } from './employee-board-update-shift.page';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { BhSpinnerComponentModule } from 'src/app/components/_core/bh-spinner/bh-spinner.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    BhInputComponentModule,
    BhSpinnerComponentModule,
    EmployeeBoardUpdateShiftPageRoutingModule
  ],
  declarations: [EmployeeBoardUpdateShiftPage]
})
export class EmployeeBoardUpdateShiftPageModule {}
