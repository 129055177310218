import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { RoleGroup } from "src/app/models/role-group";
import { Room } from "src/app/models/room";
import { Roster } from "src/app/models/roster";
import { Shift } from "src/app/models/shift";
import { SelectOption } from "src/app/models/_core/select-option";
import { RoomService } from "src/app/services/room/room.service";
import { ShiftsService } from "src/app/services/shifts/shifts.service";
import { AuthService } from "src/app/services/_core/auth/auth.service";
import { BhAnalyticsService } from "src/app/services/_core/bhanalytics/bhanalytics.service";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";

@Component({
  selector: 'app-add-room',
  templateUrl: './add-room.page.html',
  styleUrls: ['./add-room.page.scss'],
})
export class AddRoomPage implements OnInit {
  @Input() rolSeq: number;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  @Input() roleGroup: RoleGroup;
  @Input() componentLabel: string;
  @Input() shifts: Shift[] = [];
  date: string;
  roleGroups: RoleGroup[] = [];
  shfSeq: number;
  subscriptions: Subscription[] = [];
  rooms: Room[] = [];
  rosters: Roster;
  postDate: string;
  stopDate: string;
  roster: Roster = {
    name: "BMC Emergency Department",
  };

  shift: Shift = {
    name: "",
    startTime: "",
    endTime: "",
    postDate: "",
    stopDate: "",
    active: 1,
  };

  // Define form
  form1: FormGroup = this.formBuilder.group({
    rosterName: [null],
    roomName: [null, Validators.required],
    name: [null, Validators.required],
    startTime: [null, Validators.required],
    endTime: [null, Validators.required],
    postDate: [null, Validators.required],
    stopDate: [null, Validators.required],
    active: [null, Validators.required],
    shiftSelection: [null, Validators.required],
    datePickerEffective: [null, Validators.required],
    datePickerDiscontinue: [null, Validators.required],
  });

  // Define validation messages
  validationMessages = {
    rosterName: [{ type: "optional" }],
    name: [{ type: "required", message: "A shift name is required." }],
    startTime: [{ type: "required", message: "Start Time is required." }],
    setActive: [{ type: "optional" }],
  };
  // Submission State
  submitAttempted = false;

  // View only State
  viewOnly = true;

  setActive: SelectOption[] = [
    {
      label: 'Yes',
      value: 1
    },
    {
      label: 'No',
      value: 0
    }
  ];

  nowTimestamp = moment().format("M/D/YYYY");
  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private notifications: NotificationsService,
    private authService: AuthService,
    private shiftService: ShiftsService,
    private roomService: RoomService
  ) {}

  ngOnInit() {}

  ionViewDidEnter() {
  }

  loadForm() {
  }

  async createNewRoom() {
    const roomNameValue = this.form1.controls.roomName.value;
    const activeFormValue = this.form1.controls.active.value;
    console.log("added room", roomNameValue);
    const authUser = this.authService.getAuthUser();
    const newRoom: Room = {
      rosSeq: this.rosSeq,
      active: activeFormValue,
      name: roomNameValue
    };
    const res = await this.roomService.createRoom(this.rosSeq, newRoom).toPromise();
    console.log('new room', res);
    newRoom.romSeq = res.romSeq;
    alert("New room created: " + newRoom.name);
    this.modalCtrl.dismiss({isSaved: true});
  }

  cancel() {
    this.analytics.clickEvent("feedback: cancel", "");
    this.modalCtrl.dismiss();
  }

  dismiss() {
    this.analytics.clickEvent("feedback: dismiss", "");
    this.modalCtrl.dismiss();
  }
}
