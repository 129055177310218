import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EditRosterPermissionsPage } from './edit-roster-permissions.page';

const routes: Routes = [
  {
    path: '',
    component: EditRosterPermissionsPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EditRosterPermissionsPageRoutingModule {}
