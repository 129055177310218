import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Role } from 'src/app/models/role';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { SchRoleAssignment } from 'src/app/models/sched-role-assignment';
import { Shift } from 'src/app/models/shift';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';
@Injectable({
  providedIn: 'root'
})
export class SchedRoleAssignmentsService {

  env = environment;
  roster: Roster;
  shift: Shift;
  roleGroups: RoleGroup;
  roles: Role;
  schedRoleAssignment: SchRoleAssignment;
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  getAllSchedAssignments(rosSeq, schSeq) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/${schSeq}/sch-role-assignments`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.getAllSchedAssignedUsers(): " + url
        );
        return of(err);
      })
    );
  }

  getSingleAssignment(rosSeq, schSeq, sraSeq) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/${schSeq}/sch-role-assignments/${sraSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.getAllSchedAssignedUsers(): " + url
        );
        return of(err);
      })
    );
  }

  createSchedRoleAssignment(rosSeq, schSeq, schedRoleAssignment: SchRoleAssignment) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/${schSeq}/sch-role-assignments`;
    console.log("sched-role-assignments: assignment created", schedRoleAssignment);
    const body = schedRoleAssignment;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.CreateAssignedUsers(): " + url
        );
        return of(err);
      })
    );
  }

  updateSchedRoleAssignment(rosSeq, schSeq, sraSeq, schedRoleAssignment: SchRoleAssignment) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/${schSeq}/sch-role-assignments/${sraSeq}`;
    console.log("sched-role-assignments: assignment updated", schedRoleAssignment);
    const body = schedRoleAssignment;
    return this.http.put(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.updateSchedRoleAssignment(): " + url
        );
        return of(err);
      })
    );
  }
}
