import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { Role } from "src/app/models/role";
import { RoleGroup } from "src/app/models/role-group";
import { SchRoleAssignmentUser } from "src/app/models/sched-role-assign-user";
import { SchRoleAssignment } from "src/app/models/sched-role-assignment";
import { Schedule } from "src/app/models/schedule";
import { Shift } from "src/app/models/shift";
import { BhAnalyticsService } from "src/app/services/_core/bhanalytics/bhanalytics.service";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";

@Component({
  selector: "app-publish",
  templateUrl: "./publish.page.html",
  styleUrls: ["./publish.page.scss"],
})
export class PublishPage implements OnInit {
  @Input() hasMissingAssignments = false;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  @Input() draftSchedule: Schedule;


  initSchedule: Schedule
  shiftOfNotAssigned: Shift;
  shiftsOfNotAssigned: Shift[] = [];
  rgOfNotAssigned: RoleGroup;
  rgsOfNotAssigned: RoleGroup[] = [];
  roleNotAssigned: Role;
  rolesNotAssigned: Role[] = [];
  roleAssignmentNotAssigned: SchRoleAssignment;
  roleAssignmentsNotAssigned: SchRoleAssignment[] = [];
  roleAssignmentNotAssignedUsers: SchRoleAssignmentUser;
  roleAssignmentsNotAssignedUsers: SchRoleAssignmentUser[] = [];
  assignmentName: string[] = [];
  NoUserRootAssignmentsAssigned: number;
  NoUserSubAssignmentsAssigned: number;
  shouldDisplay: boolean = false;
  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private notificationService: NotificationsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    console.log("draft schedule", this.draftSchedule);
  }


  ionViewWillEnter() {
    if (this.hasMissingAssignments) {
      this.initSchedule = Object.assign({}, this.draftSchedule);
      this.calcScheduleProgress();
      this.cdr.detectChanges();
    }
  }

  publish() {
    this.modalCtrl.dismiss({ isPublishing: true });
  }

  cancel() {
    this.analytics.clickEvent("feedback: cancel", "");
    this.modalCtrl.dismiss();
  }

  toggleRoleGroupView(shift: Shift) {
       shift.isOpen = !shift.isOpen;
  }

  getCurrentShift(): Shift[] {
    const currentMoment = moment();
    const shifts: Shift[] = this.draftSchedule.shifts.filter((shift) => {
      let shiftStartDateMoment = moment();
      let shiftEndDateMoment = moment();
      // Check for crossover shift
      if (shift.endTime < shift.startTime) {
        // Determine if currently first day or second day
        const currentTime = currentMoment.format('HH:mm');
        if (currentTime >= shift.startTime && currentTime <= '23:59') {
          // First day
          shiftStartDateMoment = moment();
          shiftEndDateMoment = moment().add(1, 'days');  
        } else if (currentTime >= '00:00' && currentTime <= shift.endTime) {
          // Second day
          shiftStartDateMoment = moment().add(-1, 'days');
          shiftEndDateMoment = moment();  
        } else {
          // Missed condition
          console.warn('crossover shift calculation error detected; didn\'t meet criteria for first or second day');
        }
      }
      const startTimeMoment =
        moment(shiftStartDateMoment.format("MM/DD/YYYY") + " " + shift.startTime + ":00", "MM/DD/YYYY HH:mm:ss");

      const endTimeMoment =
        moment(shiftEndDateMoment.format("MM/DD/YYYY") + " " + shift.endTime + ":59", "MM/DD/YYYY HH:mm:ss");

      const isCurrentShift = startTimeMoment <= currentMoment && endTimeMoment >= currentMoment;
    
      return isCurrentShift;
    });
    return shifts;
  }

  async calcScheduleProgress() {
    const shift = this.getCurrentShift();
    const nextShift = this.initSchedule?.shifts[this.initSchedule?.shifts.findIndex(shft => shift.find(s => s.shfSeq === shft.shfSeq) !== undefined) + 1];
    //const prevShift = this.draftSchedule.shifts[this.draftSchedule.shifts.findIndex(shft => shift.find(s => s.shfSeq === shft.shfSeq) !== undefined) - 1];
    const allShifts: Shift[] = [];
    allShifts.push(shift[0], nextShift);
    console.log('current all shift', allShifts);
    if (this.initSchedule && this.initSchedule?.shifts) {
      this.initSchedule.shifts = this.initSchedule?.shifts.filter(shft => allShifts.find(s => s.shfSeq === shft.shfSeq) !== undefined);    
      for (const sh of this.initSchedule?.shifts) {
        let numeratorShift = 0;
        let denominatorShift = 0;
        console.log('sh', sh);
        //console.log("check shifts in numerator", sh);
        for (const rg of sh.roleGroups) {
          let numerator = 0;
          let denominator = 0;
          //console.log("check rg in numerator", rg);
          for (const r of rg.roles) {
            let numeratorRole = 0;
            let denominatorRole = 0;
            if (
              Array.isArray(r.schRoleAssignments) &&
              r.schRoleAssignments.length > 0
            ) {
              for (const sra of r.schRoleAssignments) {
                denominator += 1;
                denominatorRole += 1;
                denominatorShift += 1;
                if (
                  Array.isArray(sra.schRoleAssignUsers) &&
                  sra.schRoleAssignUsers.filter((u) => u.active === 1).length >
                    0
                ) {
                  numerator += 1;
                  numeratorRole += 1;
                  numeratorShift += 1;
                }
                // numerator +=
                //   Array.isArray(sra.schRoleAssignUsers) &&
                //     sra.schRoleAssignUsers.filter((u) => u.active === 1).length >
                //     0
                //     ? 1
                //     : 0;
                console.log(
                  "numerator",
                  numerator,
                  "name",
                  sra.name,
                  "sub users",
                  sra.schRoleAssignUsers
                );
              }
            } else {
              denominator += 1;
              denominatorRole += 1;
              denominatorShift += 1;

              if (
                Array.isArray(r.schRoleAssignUsers) &&
                r.schRoleAssignUsers.filter((u) => u.active === 1).length > 0
              ) {
                numerator += 1;
                numeratorRole += 1;
                numeratorShift += 1;
              }

              // numerator +=
              //   Array.isArray(r.schRoleAssignUsers) &&
              //   r.schRoleAssignUsers.filter((u) => u.active === 1).length > 0
              //     ? 1
              //     : 0;
              console.log(
                "numerator",
                numerator,
                "root name",
                r.name,
                "root users",
                r.schRoleAssignUsers
              );
            }
            r.numerator = numeratorRole;
            r.denominator = denominatorRole;
          }
          rg.numerator = numerator;
          rg.denominator = denominator;
        }
        const totalNum = sh.roleGroups.filter(
          (rg) => rg.denominator === rg.numerator
        ).length;
        //let totalDen = sh.roleGroups.length;
        let totalDen = 0;
        for (const rg of sh.roleGroups) {
          totalDen += rg.denominator;
        }

        sh.isCompleted = totalDen > 0 && totalNum === totalDen;
        console.log(
          "calc ShiftProgress",
          sh.startTime,
          sh.isCompleted,
          totalNum,
          totalDen
        );
        sh.numerator = numeratorShift;
        sh.denominator = denominatorShift;
      }
    }
  }

  async IdentifyEmptyRoles(): Promise<boolean> {
    this.shiftOfNotAssigned = null;
    this.rgOfNotAssigned = null;
    this.roleNotAssigned = null;
    this.roleAssignmentNotAssigned = null;
    this.assignmentName = [];
    const assignmentName = [];
    this.shiftsOfNotAssigned = [];
    this.rgsOfNotAssigned = [];
    this.rolesNotAssigned = [];
    this.roleAssignmentsNotAssigned = [];
    this.roleAssignmentsNotAssignedUsers = [];
    this.NoUserRootAssignmentsAssigned;
    this.NoUserSubAssignmentsAssigned;

    if (this.draftSchedule && this.draftSchedule?.shifts) {
      for (const sh of this.draftSchedule?.shifts) {
        // console.log("check shifts in set", sh);
        for (const rg of sh.roleGroups) {
          //console.log("check rg in set", rg);

          for (const r of rg.roles) {
            //console.log("check roles in set", r);
            if (
              Array.isArray(r?.schRoleAssignments) &&
              r?.schRoleAssignments.length > 0
            ) {
              for (const sra of r?.schRoleAssignments) {
                this.NoUserSubAssignmentsAssigned =
                  Array.isArray(sra?.schRoleAssignUsers) &&
                  sra?.schRoleAssignUsers.length < 1
                    ? 1
                    : 0;
                if (this.NoUserSubAssignmentsAssigned == 1) {
                  //this.shouldDisplay = true;
                  //  console.log('NoUserSubAssignmentsAssigned Inside block', this.NoUserSubAssignmentsAssigned);
                  this.shiftsOfNotAssigned = this.draftSchedule.shifts.filter(
                    (shft) => shft.shfSeq === sh.shfSeq
                  );
                  //console.log('shifts of none assigned', this.shiftsOfNotAssigned);
                  this.rgsOfNotAssigned = sh.roleGroups.filter(
                    (rg) => rg.rgpSeq === r.rgpSeq
                  );
                  //console.log('role groups of none assigned', this.rgsOfNotAssigned);
                  this.rolesNotAssigned = rg.roles.filter(
                    (rol) => rol.rolSeq === r.rolSeq
                  );
                  //console.log('roles of none assigned', this.rolesNotAssigned);
                  this.roleAssignmentsNotAssigned = r.schRoleAssignments.filter(
                    (s) => s.sraSeq === sra.sraSeq
                  );
                  //console.log('assignment not assigned', this.roleAssignmentsNotAssigned);
                  this.roleAssignmentsNotAssignedUsers =
                    r.schRoleAssignUsers.filter(
                      (sru) => sru.sraSeq === sra.sraSeq
                    );
                  //console.log('sru sub not assigned', this.roleAssignmentsNotAssignedUsers);
                  assignmentName.push(
                    this.getAssignedName(
                      sh.shfSeq,
                      rg.rgpSeq,
                      r.rolSeq,
                      sra.sraSeq
                    )
                  );
                  this.assignmentName = assignmentName.filter(
                    (assign) => assign !== ""
                  );
                  //console.log('assignment Name', this.assignmentName);
                }
              }
            } else {
              this.NoUserRootAssignmentsAssigned =
                r.schRoleAssignUsers.filter((u) => u.active === 1).length > 0 ||
                r.schRoleAssignUsers === undefined ||
                r.schRoleAssignUsers.length === 0
                  ? 1
                  : 0;
            }
            r.NoUserRootAssignmentsAssigned =
              this.NoUserRootAssignmentsAssigned;
            //console.log('number of unassigned root roles', r.NoUserRootAssignmentsAssigned);
            //console.log('shifts', sh);
            if (this.NoUserRootAssignmentsAssigned === 1) {
              //this.shouldDisplay = true;
              this.shiftsOfNotAssigned = this.draftSchedule.shifts.filter(
                (shft) => shft.shfSeq === sh.shfSeq
              );
              //console.log('shifts of none assigned', this.shiftsOfNotAssigned);
              this.rgsOfNotAssigned = sh.roleGroups.filter(
                (rg) => rg.rgpSeq === r.rgpSeq
              );
              //console.log('role groups of none assigned', this.rgsOfNotAssigned);
              this.rolesNotAssigned = rg.roles.filter(
                (rol) => rol.rolSeq === r.rolSeq
              );
              //console.log('roles of none assigned', this.rolesNotAssigned);
              this.roleAssignmentsNotAssignedUsers =
                r.schRoleAssignUsers.filter((sru) => sru.rolSeq === r.rolSeq);
              //console.log('sru root not assigned', this.roleAssignmentsNotAssignedUsers);
              assignmentName.push(
                this.getAssignedName(sh.shfSeq, rg.rgpSeq, r.rolSeq)
              );
              this.assignmentName = assignmentName.filter(
                (assign) => assign !== ""
              );
              //console.log('assignment Name', this.assignmentName);
            }
            // NoUserAssignmentsAssigned =
            //   r?.schRoleAssignUsers.length === 0
            //     ? 1
            //     : 0;

            // if(NoUserAssignmentsAssigned === 1) {
            //   console.log('usrs', r.schRoleAssignUsers, 'usrs assign', r.schRoleAssignments);
            // }

            //     console.log("check rols in set", r);
            //     if (
            //       Array.isArray(r.schRoleAssignments) &&
            //       r.schRoleAssignments.length == 0 || r.schRoleAssignments === undefined
            //     ) {
            //       for (const sra of r.schRoleAssignments) {
            //         NoUserSubAssignmentsAssigned =
            //             Array.isArray(sra.schRoleAssignUsers) &&
            //             sra.schRoleAssignUsers.filter((u) => u.active === 1).length ===
            //             0
            //             ? 1
            //             : 0;
            //             if(NoUserAssignmentsAssigned === 1) {
            //             r.hasUserSubAssignments = NoUserAssignmentsAssigned;
            //             this.shiftsOfNotAssigned = this.draftSchedule.shifts.filter(shft => shft.shfSeq === sh.shfSeq);
            //             console.log('shifts of none assigned', this.shiftsOfNotAssigned);
            //             this.rgsOfNotAssigned = sh.roleGroups.filter(rg => rg.rgpSeq === r.rgpSeq);
            //             console.log('role groups of none assigned', this.rgsOfNotAssigned);
            //             this.rolesNotAssigned = rg.roles.filter(rol => rol.rolSeq === r.rolSeq);
            //             console.log('roles of none assigned', this.rolesNotAssigned);
            //             this.roleAssignmentsNotAssigned = r.schRoleAssignments.filter(s => s.sraSeq === sra.sraSeq);
            //             console.log('sub role already assigned', this.roleAssignmentsNotAssigned);
            //             this.roleAssignmentsNotAssignedUsers = r.schRoleAssignUsers.filter(sru => sru.rolSeq === r.rolSeq);
            //             console.log('sru root not assigned', this.roleAssignmentsNotAssignedUsers);
            //             assignmentName.push(this.getAssignedName(sh.shfSeq, rg.rgpSeq, r.rolSeq));
            //             this.assignmentName = assignmentName.filter(assign => assign !== '');
            //             console.log('assignment Name', this.assignmentName);
            //             }
            //           }
            //         }
            // else {
            //       NoUserAssignmentsAssigned =
            //           r.schRoleAssignUsers.filter((u) => u.active === 1).length > 0 || r.schRoleAssignUsers === undefined || r.schRoleAssignUsers.length === 0
            //           ? 1
            //           : 0;
            //         }
            //         r.NoUserAssignmentsAssigned = NoUserAssignmentsAssigned;
            //         console.log('number of unassigned root roles', r.NoUserAssignmentsAssigned);
            //         //console.log('shifts', sh);
            //       if (NoUserAssignmentsAssigned === 1) {
            //       this.shiftsOfNotAssigned = this.draftSchedule.shifts.filter(shft => shft.shfSeq === sh.shfSeq);
            //       console.log('shifts of none assigned', this.shiftsOfNotAssigned);
            //       this.rgsOfNotAssigned = sh.roleGroups.filter(rg => rg.rgpSeq === r.rgpSeq);
            //       console.log('role groups of none assigned', this.rgsOfNotAssigned);
            //       this.rolesNotAssigned = rg.roles.filter(rol => rol.rolSeq === r.rolSeq);
            //       console.log('roles of none assigned', this.rolesNotAssigned);
            //       this.roleAssignmentsNotAssignedUsers = r.schRoleAssignUsers.filter(sru => sru.rolSeq === r.rolSeq);
            //       console.log('sru root not assigned', this.roleAssignmentsNotAssignedUsers);
            //       assignmentName.push(this.getAssignedName(sh.shfSeq, rg.rgpSeq, r.rolSeq));
            //       this.assignmentName = assignmentName.filter(assign => assign !== '');
            //       console.log('assignment Name', this.assignmentName);
            //     }
          }
        }
      }
    }
    return Promise.resolve(true);
  }

  getAssignedName(shfSeq, rgpSeq, rolSeq, sraSeq?) {
    const shift = this.shiftsOfNotAssigned.filter(
      (shf) => shf.shfSeq === shfSeq
    )[0];
    const role = this.rolesNotAssigned.filter((r) => r.rolSeq === rolSeq)[0];
    const roleGroup = this.rgsOfNotAssigned.filter(
      (rg) => rg.rgpSeq === rgpSeq
    )[0];
    const schedAssignment = this.roleAssignmentsNotAssigned.filter(
      (sra) => sra.sraSeq === sraSeq
    )[0];
    const schedAssignedUser = this.roleAssignmentsNotAssignedUsers.filter(
      (sru) =>
        sru.sraSeq !== 0 ? sru.sraSeq === sraSeq : sru.rolSeq === rolSeq
    )[0];

    // return (
    //   (shift
    //     ? moment(shift.startTime, "hh:mm a").format("hh:mm a") +
    //       "-" +
    //       moment(shift.endTime, "hh:mm a").format("hh:mm a") +
    //       ""
    //     : "") +
    //   "<br/>" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   (roleGroup ? '• ' + roleGroup.name + "" : "") +
    //   "<br/>" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   (role ?  '• ' + role.name + "" : "") +
    //   "<br/>" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   "&nbsp" +
    //   (schedAssignment ? '• ' + schedAssignment.name + '<br/>' + '<br/>' : '• ' +  role.name + " (root)" + '<br/>' + '<br/>')
    // );
  }

  shouldDisplayLabel() {
    this.shouldDisplay = true;
  }

  
}
