import { User } from 'src/app/models/user';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AuthState } from 'src/app/models/_core/auth-state';
import { Employee } from 'src/app/models/employee';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { UserAccess } from 'src/app/models/user-access';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  env = environment;
  employee: Employee;
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  // getAll(activeOnly: boolean): Observable<User[]> {
  //   const activeOnlyParam = activeOnly ? '?activeOnly=1' : '';
  //   const url = `${this.env.apiUrl}/getUsersAll${activeOnlyParam}`;
  //   return this.http.get(url).pipe(
  //     map((data: any) => {
  //       return data;
  //     })
  //   );
  // }
  getAllUsers() {
    const url = `${this.env.apiUrl}/users`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "user-service.getAllUsers(): " + url
        );
        return of(err);
      })
    );
  }

  getById(userId): Observable<any> {
    const url = `${this.env.apiUrl}/users/${userId}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getUserAuthType(recordId): Observable<User> {
    const url = `${this.env.apiUrl}/getUserAuthType`;
    const body = {
      id: recordId
    };

    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // create(user: User) {
  //   if (user.fullName.length > 0) {
  //     const url = `${this.env.apiUrl}/addUser`;
  //    // user.groupId = 0;
  //     //user.isLoggingOn = 0;

  //     return this.http.post(url, user).pipe(
  //       map((data: any) => {
  //         return data;
  //       })
  //     );
  //   }
  // }

  createUser(user: User) {
    if (user.fullName.length > 0) {
      user.userName = user.fullName;
      const url = `${this.env.apiUrl}/users`;
     // user.groupId = 0;
      //user.isLoggingOn = 0;

      return this.http.post(url, user).pipe(
        map((data: any) => {
          return data;
        })
      );
    }
  }

  updateUser(user: User) {
    if (user.fullName.length > 0) {
      user.userName = user.fullName;
      const url = `${this.env.apiUrl}/users/${user.userId}`;
      return this.http.put(url, user).pipe(
        map((data: any) => {
          return data;
        })
      );
    }
  }

  getADUserByName(name: string) {
    const url = `${this.env.apiUrl}/users/lookup?query=${name}`
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }


  getADUserById(userId: string) {
    const url = `${this.env.apiUrl}/users/lookup?query=${userId}`
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }


  getAllUsersByAccess(rosSeq) {
    const url = `${this.env.apiUrl}/rosters/${rosSeq}/users`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "user-service.getAllUsersByAccess(): " + url
        );
        return of(err);
      })
    );
  }

  getSingleUserByAccess(rosSeq, userAccess: UserAccess) {
    const url = `${this.env.apiUrl}/rosters/${rosSeq}/users/${userAccess.userId}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "user-service.getSingleUserByAccess(): " + url
        );
        return of(err);
      })
    );
  }

  createUserAccess(rosSeq, userAccess: UserAccess) {
      const url = `${this.env.apiUrl}/rosters/${rosSeq}/users`;
      const body = userAccess;
      return this.http.post(url, body).pipe(
        map((data: any) => {
          return data;
        }),
        catchError((err) => {
          this.notifications.handleError(
            err,
            "user-service.createUserAccess(): " + url
          );
          return of(err);
        })
      );
  }

  updateUserAccess(rosSeq, userAccess: UserAccess) {
    const url = `${this.env.apiUrl}/rosters/${rosSeq}/users/${userAccess.userId}`;
    const body = userAccess;
    return this.http.put(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "user-service.updateUserAccess(): " + url
        );
        return of(err);
      })
    );
}
}
