import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Roster } from "src/app/models/roster";
import { environment } from "src/environments/environment";
import { NotificationsService } from "../_core/notifications/notifications.service";

@Injectable({
  providedIn: "root",
})
export class RosterService {
  env = environment;
  roster: Roster;
  rosterSubject = new BehaviorSubject<Roster>(null);
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) {
    this.roster = {};
  }

  getRoster() {
    const url = environment.apiUrl + "/rosters/";
    const body = {};
    return this.http.get(url).pipe(
      map((data: any) => {
        this.roster = data;
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "roster-service.getRoster(): " + url
        );
        return of(err);
      })
    );
  }

  getRosterBySeq(rosSeq) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}`;
    const body = {};
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "roster-service.getRosterBySeq(): " + url
        );
        return of(err);
      })
    );
  }

  getRosterByUserId(userId) {
    const url = `${environment.apiUrl}/rosters/?userId=${userId}`;
    const body = {};
    return this.http.get(url).pipe(
      map((data: any) => {
        this.roster = data;
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "roster-service.getRosterBySeq(): " + url
        );
        return of(err);
      })
    );
  }

  createRoster(roster: Roster) {
    const url = `${environment.apiUrl}/rosters/`
    console.log("roster service: create", roster);
    const body = roster;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "roster-service.createRoster(): " + url
        );
        return of(err);
      })
    );
  }

  updateRoster(rosSeq, roster: Roster) {
    const url = `${environment.apiUrl}/rosters/${roster.rosSeq}`;
    const body = roster;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "roster-service.updateRoster(): " + url
        );
        return of(err);
      })
    );
  }

  setRosterSubject(rosterState: Roster) {
    this.rosterSubject.next(rosterState);
  }

  getRosterSubject(): Roster {
    console.log("Roster from subject", this.rosterSubject.getValue());
    return this.rosterSubject.getValue();
  }
}
