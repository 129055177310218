import { BhMessageBannerComponentModule } from '../../components/_core/bh-message-banner/bh-message-banner.component.module';
import { BhContentEmptyComponentModule } from '../../components/_core/bh-content-empty/bh-content-empty.component.module';
import { BhContentEmptyComponent } from '../../components/_core/bh-content-empty/bh-content-empty.component';
import { BhSpinnerComponentModule } from '../../components/_core/bh-spinner/bh-spinner.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AssignTeamMemberEditPageRoutingModule } from './assign-team-member-edit-routing.module';

import { AssignTeamMemberEditPage } from './assign-team-member-edit.page';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { BhContentSearchComponentModule } from 'src/app/components/_core/bh-content-search/bh-content-search.component.module';
import { BhUserBadgeComponentModule } from 'src/app/components/_core/bh-user-badge/bh-user-badge.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    AssignTeamMemberEditPageRoutingModule,
    BhMessageBannerComponentModule,
    BhInputComponentModule,
    BhSpinnerComponentModule,
    BhContentEmptyComponentModule,
    PipesModule,
    BhContentSearchComponentModule,
    BhUserBadgeComponentModule,
  ],
  declarations: [AssignTeamMemberEditPage]
})
export class AssignTeamMemberEditPageModule {}
