import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/models/role';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { Shift } from 'src/app/models/shift';
import { SelectOption } from 'src/app/models/_core/select-option';
import { RoleGroupsService } from 'src/app/services/role-groups/role-groups.service';
import { RoleService } from 'src/app/services/role/role.service';
import { ShiftsService } from 'src/app/services/shifts/shifts.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
@Component({
  selector: 'app-add-edit-role-group',
  templateUrl: './add-edit-role-group.page.html',
  styleUrls: ['./add-edit-role-group.page.scss'],
})
export class AddEditRoleGroupPage implements OnInit {

  @Input() rolSeq: number;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  //@Input() shfSeq: number;
  //@Input() shift: Shift;
  @Input() roleGroup: RoleGroup;
  @Input() componentLabel: string;
  @Input() roster: Roster;
  date: string;
  shifts: Shift[] = [];
  shift: Shift;
  roleGroups: RoleGroup[] = [];
  shfSeq: number;
  subscriptions: Subscription[] = [];

  // roster: Roster = {
  //   name: 'BMC Emergency Department'
  // };

  shiftObj: Shift = {
    name: '',
    startTime: '',
    endTime: '',
    postDate: '',
    stopDate: '',
    active: 1
  };

  roleGroupObj: RoleGroup = {
    active: 1
  }

  roleObj: Role = {
    name: '',
    rgpSeq: 1,
    active: 1
  }

  // Define form
  form1: FormGroup = this.formBuilder.group({
    rosterName: [null],
    shift: [null, Validators.required],
    roleGroup: [null, Validators.required],
    roleName: [null, Validators.required],
    startTime: [null, Validators.required],
    endTime: [null, Validators.required],
    postDate: [null, Validators.required],
    stopDate: [null, Validators.required],
    active: [null, Validators.required],
    datePicker: [null, Validators.required],
  });

  // Define validation messages
  validationMessages = {
    rosterName: [{ type: 'optional' }],
    roleName: [{ type: "required", message: "A role name is required." }],
    name: [{ type: "required", message: "A shift name is required." }],
    shift: [{ type: "required", message: "A shift name is required." }],
    roleGroup: [{ type: "required", message: "A role group name is required." }],
    startTime: [{ type: "required", message: "Start Time is required." }],
    active: [{ type: 'optional' }]
  };
  // Submission State
  submitAttempted = false;

  // View only State
  viewOnly = true;

  // Options for Active Select
  RoleGroupSelection: SelectOption[] = [

  ];
  shiftSelection: SelectOption[] = [{}];
  roleGroupSelection: SelectOption[] = [{}];

  setActive: SelectOption[] = [
    {
      label: 'Yes',
      value: 1
    },
    {
      label: 'No',
      value: 0
    }
  ];

  nowTimestamp = moment().format('M/D/YYYY');
  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private roleService: RoleService,
    private roleGroupService: RoleGroupsService,
    private shiftService: ShiftsService
  ) { }

  ngOnInit() {

  }

  ionViewDidEnter() {
    this.loadForm();
  }

  loadForm() {
    this.form1.controls.rosterName.setValue(this.roster.name);
    // Parse Date as Moment Object
    if (this.subscriptions.length === 0) {
      this.subscriptions = [
        this.form1.controls.shift.valueChanges.subscribe(val => {
          if (val) {
            console.log('shift selection', val);
            this.shfSeq = val;
            //this.getSpecificShift(this.shfSeq);
          } else {
            this.shifts = [];
          }
        }),
        this.form1.controls.datePicker.valueChanges.subscribe(dateVal => {
          if (dateVal) {
            console.log('date', dateVal.toDate());
          }
        })
      ];
    }
    this.loadCurrentShifts();
  }

  async loadCurrentShifts() {
    let currentMoment = moment();
    try {
      const res = await this.shiftService.getAllShifts(this.rosSeq, 1).toPromise();
      if (res) {
        this.shifts = res.shifts.filter(shift => {
          const postDateMoment = moment(shift.postDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss');
          const stopDateMoment = (shift.stopDate) ? moment(shift.stopDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss') : null;
          const startTimeMoment = moment(currentMoment.format('MM/DD/YYYY') + ' ' + shift.startTime + ':00', 'MM/DD/YYYY HH:mm:ss');
          const endTimeMoment = moment(currentMoment.format('MM/DD/YYYY') + ' ' + shift.endTime + ':59', 'MM/DD/YYYY HH:mm:ss');
          const isLive = postDateMoment <= currentMoment && (!stopDateMoment || stopDateMoment >= currentMoment);
          return isLive;
        });
        this.shifts.forEach(shift => {
          const startTime = moment(shift.startTime, 'hh:mm a').format('hh:mm a');
          const endTime = moment(shift.endTime, 'hh:mm a').format('hh:mm a');
          const shiftStatus = this.getShiftStatus(shift);
          const shiftTime = startTime + ' - ' + endTime + shiftStatus;
          this.shiftSelection.push({ label: shiftTime, value: shift.shfSeq });
          this.shiftSelection = this.shiftSelection.filter(ss => Object.keys(ss).length !== 0);
        });

        console.log('current shifts', this.shifts);
      }
    } catch (err) {
      this.notificationService.handleError(err, 'loadShifts()')
    }
  }

  getShiftStatus(shift: Shift) {
    let currentMoment = moment();
    const postDateMoment = moment(shift.postDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss');
    const stopDateMoment = (shift.stopDate) ? moment(shift.stopDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss') : null;
    const isLive = postDateMoment <= currentMoment && (!stopDateMoment || stopDateMoment >= currentMoment);
    if (!shift.active) {
      return ' (inactive)';
    } else if (!isLive) {
      return ' (expired)';
    } else {
      return ' (active)';
    }
  }

  dismiss() {
    this.analytics.clickEvent('feedback: dismiss', '');
    this.modalCtrl.dismiss();
  }

  async createNewRoleGroup() {
    const roleGroupName = this.form1.controls.roleGroup.value;
    const activeFormValue = this.form1.controls.active.value;
    console.log('role Group name', roleGroupName);
    try {
      this.notificationService.startLoading();
      const getCurrentRoleGroups = await this.roleGroupService.getAllRoleGroups(this.rosSeq, this.shfSeq).toPromise();
      console.log('Current role Group', getCurrentRoleGroups);
      const newRoleGroup: RoleGroup = {
        shfSeq: this.shfSeq,
        name: roleGroupName,
        active: activeFormValue,
      }
      console.log("created new role group", newRoleGroup);
      const res = await this.roleGroupService
        .createRoleGroup(this.rosSeq, this.shfSeq, newRoleGroup)
        .toPromise();
      console.log("created new role group", res);
      newRoleGroup.rgpSeq = res.rgpSeq;
      this.notificationService.stopLoading();
      this.notificationService.showAlert(
        "New Role Group Added",
        "A new role group has been successfully added.",
        "success"
      );
      this.modalCtrl.dismiss({ isSaved: true });
    } catch (err) {
      this.notificationService.handleError(err, "addEmployeeToAssignment()");
    }
  }

  saveAndExit() {
    this.modalCtrl.dismiss();
  }

  cancel() {
    this.analytics.clickEvent('feedback: cancel', '');
    this.modalCtrl.dismiss();
  }

}
