import { Component, Input, OnInit } from '@angular/core';

/**
 * ID: bh-message-banner
 * Name: BH Message Banner
 * Description: A standardized message block used for displaying important user information
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-06-23 - MW - v1: Initial dev
 * 2021-07-12 - MW - v2: Adjust margin and icon size
 */
@Component({
  selector: 'bh-message-banner',
  templateUrl: './bh-message-banner.component.html',
  styleUrls: ['./bh-message-banner.component.scss'],
})
export class BhMessageBannerComponent implements OnInit {
  @Input() ionIconName;
  @Input() ionIconColor;
  @Input() type: 'information' | 'warning' | 'success' | 'danger';

  constructor() { }

  ngOnInit() {}

}
