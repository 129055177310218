import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RosterReportsPageRoutingModule } from './roster-reports-routing.module';

import { RosterReportsPage } from './roster-reports.page';
import { BhContentSearchComponentModule } from 'src/app/components/_core/bh-content-search/bh-content-search.component.module';
import { BhGridComponentModule } from 'src/app/components/_core/bh-grid/bh-grid.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RosterReportsPageRoutingModule,
    BhContentSearchComponentModule,
    BhGridComponentModule
  ],
  declarations: [RosterReportsPage]
})
export class RosterReportsPageModule {}
