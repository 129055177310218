import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AdUser } from 'src/app/models/ad-user';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-popover-user-permission',
  templateUrl: './popover-user-permission.page.html',
  styleUrls: ['./popover-user-permission.page.scss'],
})
export class PopoverUserPermissionPage implements OnInit {

  constructor(
    private userService: UsersService,
    private popoverController: PopoverController
  ) { }
  ngOnInit() {
  }

  employees: AdUser[] = [];

  async getEmployees(ev) {
    try {
      const res = await this.userService.getADUserByName(ev).toPromise();
      if (res) {
        this.employees = res.results;
        console.log("employees", this.employees);
      }
    } catch {}
  }

  addEmployeeToSelection(employee: AdUser) {
    this.popoverController.dismiss({isSelected: true, employee: employee});
  }
}
