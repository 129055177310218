import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Room } from 'src/app/models/room';
import { Roster } from 'src/app/models/roster';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  env = environment;
  roster: Roster;
  room: Room
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) {
    this.room = {};
    this.roster = {}
   }

  getAllRooms(rosSeq: number) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/rooms`
    const body = {};
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "room-service.getAllRooms(): " + url
        );
        return of(err);
      })
    );
  }

  getSingleRoom(rosSeq: number,romSeq: number) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/rooms/${romSeq}`
    const body = {};
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "room-service.getSingleRoom(): " + url
        );
        return of(err);
      })
    );
  }

  createRoom(rosSeq: number, room: Room) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/rooms`;
    console.log("room service: created room", room);
    const body = room;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "room-service.createRoom(): " + url
        );
        return of(err);
      })
    );
  }

  updateRoom(rosSeq: number,romSeq: number, room: Room) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/rooms/${romSeq}`
    const body = room;
    return this.http.put(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "room-service.updateRoom(): " + url
        );
        return of(err);
      })
    );
  }
}
