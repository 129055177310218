import { Diagnosis } from './../../models/diagnosis';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class DiagnosesService {

  constructor(
    private http: HttpClient
  ) { }

  getDiagnosesByTerm(term: string) {
    const url = `https://clinicaltables.nlm.nih.gov/api/icd9cm_dx/v3/search?terms=${term}&maxList=50`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getDiagnosesByCode(code: string) {
    const url = `https://clinicaltables.nlm.nih.gov/api/icd9cm_dx/v3/search?code=${code}&maxList=50`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  parseDiagnoses(res) {
    const diagnoses: Diagnosis[] = [];
    if (res && res[3]) {
      for (const dx of res[3]) {
        const diagnosis = {
          code: dx[0],
          description: dx[1]
        };
        diagnoses.push(diagnosis);
      }
    }

    return diagnoses;
  }

}
