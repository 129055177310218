import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { Role } from "src/app/models/role";
import { RoleGroup } from "src/app/models/role-group";
import { Roster } from "src/app/models/roster";
import { Shift } from "src/app/models/shift";
import { SelectOption } from "src/app/models/_core/select-option";
import { RoleGroupsService } from "src/app/services/role-groups/role-groups.service";
import { RoleService } from "src/app/services/role/role.service";
import { ShiftsService } from "src/app/services/shifts/shifts.service";
import { BhAnalyticsService } from "src/app/services/_core/bhanalytics/bhanalytics.service";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";
@Component({
  selector: "app-edit-role",
  templateUrl: "./edit-role.page.html",
  styleUrls: ["./edit-role.page.scss"],
})
export class EditRolePage implements OnInit {
  @Input() rolSeq: number;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  @Input() shfSeq: number;
  @Input() rgpSeq: number;
  @Input() shift: Shift;
  @Input() roleGroup: RoleGroup;
  @Input() role: Role;
  @Input() componentLabel: string;
  @Input() roster: Roster;

  date: string;
  shifts: Shift[] = [];
  roleGroups: RoleGroup[] = [];
  subscriptions: Subscription[] = [];

  // roster: Roster = {
  //   name: "BMC Emergency Department",
  // };
  
  shiftObj: Shift = {
    name: "",
    startTime: "",
    endTime: "",
    postDate: "",
    stopDate: "",
    active: 1,
  };

  roleGroupObj: RoleGroup = {
    active: 1,
  };

  roleObj: Role = {
    name: "",
    rgpSeq: 1,
    active: 1,
  };
  // Define form
  form1: FormGroup = this.formBuilder.group({
    roleName: [null, Validators.required],
    active: [null, Validators.required],
    staffing: [null, Validators.required],
  });

  setStaffing: SelectOption[] = [
    {
      label: "Horizontal",
      value: "H",
    },
    {
      label: "Vertical",
      value: "V",
    },
    {
      label: "None",
      value: "N",
    },
  ];

  // Define validation messages
  validationMessages = {
    rosterName: [{ type: "optional" }],
    roleName: [{ type: "required", message: "A role name is required." }],
    name: [{ type: "required", message: "A shift name is required." }],
    shift: [{ type: "required", message: "A shift name is required." }],
    staffing: [{ type: "required", message: "A choice must be made." }],
    roleGroup: [
      { type: "required", message: "A role group name is required." },
    ],
    startTime: [{ type: "required", message: "Start Time is required." }],
    active: [{ type: "optional" }],
  };
  // Submission State
  submitAttempted = false;

  // View only State
  viewOnly = true;

  // Options for Active Select
  RoleGroupSelection: SelectOption[] = [];

  shiftSelection: SelectOption[] = [{}];

  setActive: SelectOption[] = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  nowTimestamp = moment().format("M/D/YYYY");
  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private roleService: RoleService,
    private roleGroupService: RoleGroupsService,
    private shiftService: ShiftsService
  ) {}

  ngOnInit() {}

  ionViewDidEnter() {
    this.loadForm();
  }

  async loadForm() {
    console.log('current role', this.role);
    await this.getCurrentShift();
    console.log('current shift', this.shift);
    console.log('rosSeq', this.shift.rosSeq);
    console.log('rgpSeq', this.rgpSeq);
    await this.getCurrentRoleGroup();
    //await this.getCurrentRole();
    this.form1.controls.roleName.setValue(this.role.name);
    this.form1.controls.active.setValue(this.role.active);
    this.form1.controls.staffing.setValue(this.role.staffingType);
  }

  async getCurrentShift(): Promise<boolean> {
    try {
      const res = await this.shiftService
        .getSingleShift(this.rosSeq, this.shfSeq)
        .toPromise();
      if (res) {
        this.shift = res.shifts[0];
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, "getCurrentShift()");
    }
  }

  async getCurrentRoleGroup(): Promise<boolean> {
    try {
      const res = await this.roleGroupService.getSingleRoleGroup(this.shift.rosSeq, this.shfSeq, this.rgpSeq)
        .toPromise();
      if (res) {
        this.roleGroup = res.roleGroups[0];
        console.log("current roleGroup", this.roleGroup);
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, "getCurrentRoleGroup()");
    }
  }

  // async getCurrentRole(): Promise<boolean> {
  //   try {
  //     const res = await this.roleService.getSingleRole(this.shift.rosSeq, this.shfSeq, this.rgpSeq, this.role.rolSeq)
  //       .toPromise();
  //     if (res) {
  //       this.role = res.roles[0];
  //       console.log("current role", this.role);
  //     }
  //     return Promise.resolve(true);
  //   } catch (err) {
  //     this.notificationService.handleError(err, "getCurrentRole()");
  //   }
  // }

  dismiss() {
    this.analytics.clickEvent("feedback: dismiss", "");
    this.modalCtrl.dismiss();
  }

  async updateExistingRole() {
    if (this.form1.valid) {
    try {
      this.role.name = this.form1.controls.roleName.value;
      this.role.active = this.form1.controls.active.value;
      this.role.staffingType = this.form1.controls.staffing.value;
      const res = await this.roleService
        .updateRole(
          this.shift.rosSeq,
          this.shift.shfSeq,
          this.rgpSeq,
          this.role.rolSeq,
          this.role
        )
        .toPromise();
      console.log("updated new role", res);
      this.modalCtrl.dismiss({ isUpdated: true});
    } catch (err) {
      this.notificationService.handleError(err, "updateExistingRole()");
    }
  } else {
    console.log('Form invalid', this.form1);
    }
  }

  cancel() {
    this.analytics.clickEvent("feedback: cancel", "");
    this.modalCtrl.dismiss();
  }
}