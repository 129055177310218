import { PopoverController } from '@ionic/angular';
import { BhAnalyticsService } from './../../services/_core/bhanalytics/bhanalytics.service';
import { Schedule } from './../../models/schedule';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-roster-more-popover',
  templateUrl: './roster-more-popover.page.html',
  styleUrls: ['./roster-more-popover.page.scss'],
})
export class RosterMorePopoverPage implements OnInit {
  @Input() schedule: Schedule;
  @Input() authUserPermission: 'READONLY' | 'EDITOR' | 'OWNER';

  constructor(
    private analytics: BhAnalyticsService,
    private popCtrl: PopoverController
  ) { }

  ngOnInit() {
  }

  setAction(action) {
    this.analytics.clickEvent('roster-more-popover: action', action);
    this.popCtrl.dismiss(action);
  }

}
