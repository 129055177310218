import { AdUser } from './../../models/ad-user';
import { SchedRoleAssignmentsService } from 'src/app/services/sched-role-assignments/sched-role-assignments.service';
import { RoleAssignment } from 'src/app/models/role-assignment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Role } from 'src/app/models/role';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { DutyComment, SchRoleAssignmentUser } from 'src/app/models/sched-role-assign-user';
import { SchRoleAssignment } from 'src/app/models/sched-role-assignment';
import { Shift } from 'src/app/models/shift';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class SchedRoleAssignUserService {

  env = environment;
  roster: Roster;
  // shift: Shift;
  roleGroups: RoleGroup;
  roles: Role;
  schedRoleAssignUser: SchRoleAssignmentUser;
  result: any;
  constructor(
    private http: HttpClient,
    private schedRoleAssignmentsService: SchedRoleAssignmentsService,
    private notifications: NotificationsService
  ) { }


  getAllSchedAssignedUsers(rosSeq, schSeq) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/${schSeq}/sch-user-assignments`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.getAllSchedAssignedUsers(): " + url
        );
        return of(err);
      })
    );
  }

  getSingleAssignedUsers(rosSeq, schSeq, sraSeq) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/${schSeq}/sch-user-assignments/${sraSeq}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.getAllSchedAssignedUsers(): " + url
        );
        return of(err);
      })
    );
  }

  createSchAssignmentUser(rosSeq, schSeq, assignmentUser: SchRoleAssignmentUser) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/${schSeq}/sch-user-assignments`;
    console.log("sched Assign User: schedAssignUser created", assignmentUser);
    const body = assignmentUser;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.CreateAssignedUsers(): " + url
        );
        return of(err);
      })
    );
  }

  updateSchAssignmentUser(rosSeq, schSeq, sruSeq, assignmentUser: SchRoleAssignmentUser) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/schedules/${schSeq}/sch-user-assignments/${sruSeq}`;
    console.log("sched Assign User: schedAssignUser updated", assignmentUser);
    const body = assignmentUser;
    return this.http.put(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.updateSchedAssignedUser(): " + url
        );
        return of(err);
      })
    );
  }

  getRecentRoleUserHistory(rosSeq: number, role: Role) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/roles/${role.rolSeq}/recent`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.getRecentRoleUserHistory(): " + url
        );
        return of(err);
      })
    );
  }

  getAllDutyComments(rosSeq: number) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/duty/comments`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.getRecentRoleUserHistory(): " + url
        );
        return of(err);
      })
    );
  }

  getSingleDutyComments(rosSeq: number, ) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/duty/comments`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.getRecentRoleUserHistory(): " + url
        );
        return of(err);
      })
    );
  }

  createDutyComment(rosSeq, dutyComment: DutyComment) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/duty/comments`;
    console.log("Duty comment created", dutyComment);
    const body = dutyComment;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.CreateAssignedUsers(): " + url
        );
        return of(err);
      })
    );
  }


  updateDutyComment(rosSeq, dcSeq, dutyComment: DutyComment) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/duty/comments/${dcSeq}`;
    console.log("Duty comment updated", dutyComment);
    const body = dutyComment;
    return this.http.put(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "sched-role-assign-user-service.updateDutyComment(): " + url
        );
        return of(err);
      })
    );
  }

  async saveUserAssignments(
    rosSeq: number, // Roster SEQ
    schSeq: number, // Schedule SEQ
    schDate: string, // Schedule Date
    shift: Shift, // Shift object
    roleGroup: RoleGroup, // Role Group object
    role: Role, // Role object
    adUser: AdUser, // AD User
    roleAssignments: RoleAssignment[], // List of role assignments
    initAssignments: RoleAssignment[], // initial list of role assignments (for rollback)
    schAssignments: SchRoleAssignment[], // List of schedule assignments
    initSchAssignments: SchRoleAssignment[], // Initial list of schedule assignments (for rollback)
    currentView: string, // Sets Current View state
    //dutyComments: DutyComments[],
    //initDutyComments: DutyComments[],
    shiftEndDate: string,
    comment: string, // Comment to save with this assignment
    orientee: number // save with schedule assignment
  ): Promise<any> {

    try {
      // Set return payload
      const result = {
        dismiss: false,
        isSaved: false,
        sru: null,
        schedAssignment: null,
        schedAssignments: null,
        role: null
      };

      console.log('saveUserAssignments: What is AD user?', adUser);
      console.log('saveUserAssignments: *** Received schAssignments', Object.assign([], role.schRoleAssignments));
      console.log('saveUserAssignments: *** Received comment', comment);
      console.log('saveUserAssignments: *** Received orientee', orientee);

      let schAssignment: SchRoleAssignment = null;

      // Get selected assignments
      const selectedAssignments = roleAssignments ? roleAssignments.filter((ra) => ra.isChecked) : [];
      console.log("saveUserAssignments: save: ", roleAssignments, 'selected', selectedAssignments, 'initAssignments', initAssignments);

      // Get selected duty comment
      //const selectedDutyComments = dutyComments ? dutyComments.filter((dc) => dc.isChecked) : [];
      //console.log("saveDutyComments: save: ", dutyComments, 'selected', selectedDutyComments, 'initAssignments', initAssignments);

      // File assignments
      for (const ra of roleAssignments) {
        const startDateTime = ra.sruStartDateTime ? ra.sruStartDateTime :
          moment(schDate + " " + shift.startTime, "MM/DD/YYYY HH:mm").format("MM/DD/YYYY hh:mm A");
        const endDateTime = ra.sruEndDateTime ? ra.sruEndDateTime :
          moment(schDate + " " + shift.endTime, "MM/DD/YYYY HH:mm").format("MM/DD/YYYY hh:mm A");

        if (ra.rasSeq !== 0) {
          if (ra !== undefined && ra.isChecked && ra.active === 1) {
            // Check for active role assignments
            let schRoleAssignment: SchRoleAssignment = initSchAssignments.find((a) => a.rasSeq === ra.rasSeq && a.active === 1);
            const activeSchedRoleAssignment: SchRoleAssignment = roleAssignments.find((a) => a.rasSeq === ra.rasSeq && a.active === 1);
            console.log('saveUserAssignments: sched Role Assignment', schRoleAssignment);
            console.log('saveUserAssignments: active sched Role Assignment', activeSchedRoleAssignment);

            // Check if assignment is not on schedule; if not, add it
            if (schRoleAssignment === undefined) {
              schRoleAssignment = {
                rasSeq: activeSchedRoleAssignment.rasSeq,
                rosSeq: rosSeq,
                schSeq: schSeq,
                rgpSeq: role.rgpSeq,
                rolSeq: activeSchedRoleAssignment.rolSeq,
                shfSeq: shift.shfSeq,
                schedDate: schDate,
                name: activeSchedRoleAssignment.name,
                active: 1
              };
              console.log('saveUserAssignments: creating new schedRoleAssignment', schRoleAssignment);

              const res = await this.schedRoleAssignmentsService
                .createSchedRoleAssignment(
                  rosSeq,
                  schSeq,
                  schRoleAssignment
                )
                .toPromise();
              console.log("saveUserAssignments: create new schedRoleAssignment", res);
              schRoleAssignment.sraSeq = res.sraSeq;
              schAssignment = schRoleAssignment;
              role.schRoleAssignments.push(schRoleAssignment);
            } else {
              schAssignment = schRoleAssignment;
            }
            // Check if overwriting argument value creates issues
            schAssignments = role.schRoleAssignments;
            //this.getAssignment(ra.rasSeq);
            currentView = "searching";
            console.log('saveUserAssignments: sched Role Assigned User', schRoleAssignment.schRoleAssignUsers);

            if (
              !schRoleAssignment.schRoleAssignUsers ||
              schRoleAssignment.schRoleAssignUsers.length == 0
            ) {
              schRoleAssignment.schRoleAssignUsers = [];
              const newSchedAssignedUser: SchRoleAssignmentUser = {
                rosSeq,
                schSeq: schSeq,
                rgpSeq: roleGroup.rgpSeq,
                rolSeq: role.rolSeq,
                rasSeq: schRoleAssignment.rasSeq,
                sraSeq: schRoleAssignment.sraSeq,
                shfSeq: roleGroup.shfSeq,
                startDateTime,
                endDateTime,
                scheduledStart: startDateTime,
                scheduledEnd: endDateTime,
                name: adUser.displayName,
                active: 1,
                userId: adUser.userId,
                //dutyComments: selectedDutyComments,
                shiftEndDate,
                comment: ra.sruComment ? ra.sruComment : comment,
                orientation: orientee
              };
              const res = await this.createSchAssignmentUser(
                rosSeq,
                schSeq,
                newSchedAssignedUser
              )
                .toPromise();
              console.log("saveUserAssignments: created new sched Assign User", res);
              newSchedAssignedUser.sruSeq = res.sruSeq;
              console.log("saveUserAssignments: new adUser", newSchedAssignedUser.adUser);
              schRoleAssignment.schRoleAssignUsers.push(newSchedAssignedUser);
              console.log("saveUserAssignments: schedAssignUser", schRoleAssignment.schRoleAssignUsers);
              result.schedAssignment = schRoleAssignment;
              result.sru = newSchedAssignedUser;
              result.role = role;
              console.log("schedAssignUser", result.sru);
              // return Promise.resolve(result);
              // this.modalCtrl.dismiss({
              //   isSaved: true,
              //   schedAssignment: schedRoleAssignment,
              // });
            } else {
              console.log('saveUserAssignments: input schedAssignments =>', schAssignments);
              console.log('saveUserAssignments: single schedAssignment =>', schAssignment);

              const existingSru = schAssignment.schRoleAssignUsers
                .find((sru) => sru.userId.toLowerCase() === adUser.userId.toLowerCase() && sru.active === 1);

              console.log("saveUserAssignments: assignment: exists", existingSru);
              if (!existingSru) {
                // Add new root assignment
                console.log('save => root => new: ', schAssignment);
                const newSchedAssignedUser: SchRoleAssignmentUser = {
                  rosSeq,
                  schSeq: schSeq,
                  rgpSeq: roleGroup.rgpSeq,
                  rolSeq: role.rolSeq,
                  rasSeq: schAssignment.rasSeq,
                  sraSeq: schAssignment.sraSeq,
                  shfSeq: roleGroup.shfSeq,
                  startDateTime,
                  endDateTime,
                  scheduledStart: startDateTime,
                  scheduledEnd: endDateTime,
                  name: adUser.displayName,
                  active: 1,
                  userId: adUser.userId,
                  shiftEndDate,
                  comment: ra.sruComment ? ra.sruComment : comment,
                  orientation: orientee
                  //dutyComments: selectedDutyComments,
                };
                const res = await this.createSchAssignmentUser(
                  rosSeq,
                  schSeq,
                  newSchedAssignedUser
                )
                  .toPromise();
                console.log("saveUserAssignments: created new sched Assign User", res);
                newSchedAssignedUser.sruSeq = res.sruSeq;
                newSchedAssignedUser.adUser = adUser;
                console.log("saveUserAssignments: new adUser", newSchedAssignedUser.adUser);
                const index = role.schRoleAssignments.findIndex(sra => sra.sraSeq === schAssignment.sraSeq);
                console.log('saveUserAssignments: index of sra', index);
                role.schRoleAssignments[index].schRoleAssignUsers.push(
                  newSchedAssignedUser
                );
                result.schedAssignments = role.schRoleAssignments
                result.sru = newSchedAssignedUser;
                // return Promise.resolve(result);
                // this.modalCtrl.dismiss({
                //   isSaved: true,
                //   schedAssignments: this.role.schRoleAssignments,
                // });
              } else {
                // Update existing assignment
                existingSru.name = adUser.displayName;
                existingSru.startDateTime = startDateTime;
                existingSru.endDateTime = endDateTime;
                existingSru.shiftEndDate = shiftEndDate;
                existingSru.comment = ra.sruComment ? ra.sruComment : comment;
                existingSru.orientation = orientee;
                console.log('saveUserAssignments: updating assignment', ra, existingSru);
                const res = await this.updateSchAssignmentUser(rosSeq, schSeq, existingSru.sruSeq, existingSru).toPromise();
                result.sru = role.schRoleAssignUsers;
                result.role = role;
                // return Promise.resolve(result);
                // this.modalCtrl.dismiss({
                //   isSaved: true,
                //   sru: this.role.schRoleAssignUsers,
                // });
              }
            }
          } else {
            const unchecked = roleAssignments ? roleAssignments.filter((ra) => ra.isChecked === false) : [];
            console.log('saveUserAssignments: unchecked =>', unchecked);

            for (let uc of unchecked) {
              const schedRoleAssignment: SchRoleAssignment = role.schRoleAssignments ?
                role.schRoleAssignments.find((a) => a.rasSeq === uc.rasSeq && a.active === 1) :
                undefined;
              console.log('saveUserAssignments: schedRoleAssignment =>', schedRoleAssignment);

              if (schedRoleAssignment !== undefined) {
                const findEmployeeToRemove: SchRoleAssignmentUser = schedRoleAssignment.schRoleAssignUsers ? schedRoleAssignment.schRoleAssignUsers.find(sru => sru.userId.toLowerCase() === adUser.userId.toLowerCase()) : undefined;
                console.log('saveUserAssignments: find Employee to Remove =>', findEmployeeToRemove);

                if (findEmployeeToRemove !== undefined) {
                  const index = role.schRoleAssignments.findIndex(sra => sra.sraSeq === schedRoleAssignment.sraSeq);
                  findEmployeeToRemove.active = 0;
                  await this.updateSchAssignmentUser(rosSeq, schSeq, findEmployeeToRemove.sruSeq, findEmployeeToRemove).toPromise();
                  role.schRoleAssignments[index].schRoleAssignUsers.splice(role.schRoleAssignments[index].schRoleAssignUsers.findIndex(sra => sra.sruSeq === findEmployeeToRemove.sruSeq), 1);
                  await this.schedRoleAssignmentsService.updateSchedRoleAssignment(rosSeq, schSeq, schedRoleAssignment.sraSeq, role.schRoleAssignments[index]).toPromise();
                }

              }
            }
            // return Promise.resolve(result);
            // this.modalCtrl.dismiss({
            //   isSaved: true
            // });
          }
        } else {
          // File Root Assignment
          const existingRoot = role.schRoleAssignUsers.find(sru => sru.userId.toLowerCase() === adUser.userId.toLowerCase() && sru.active === 1)
          if (ra.isChecked) {
            console.log('saveUserAssignments: isChecked: Add user', ra);
            await this.saveRootAssignment(
              rosSeq,
              schSeq,
              roleGroup,
              role,
              adUser,
              role.schRoleAssignments,
              currentView,
              shiftEndDate,
              //selectedDutyComments,
              ra.sruComment ? ra.sruComment : comment,
              startDateTime,
              endDateTime,
              false,
              orientee
            );
          } else {
            // Remove root assignment
            console.log('saveUserAssignments: !NOT checked: Remove user', ra);
            await this.saveRootAssignment(
              rosSeq,
              schSeq,
              roleGroup,
              role,
              adUser,
              role.schRoleAssignments,
              currentView,
              shiftEndDate,
              //selectedDutyComments,
              ra.sruComment ? ra.sruComment : comment,
              startDateTime,
              endDateTime,
              true,
              orientee
            );
          }
        }
      } // End FOR

      // }

      // Remove empty assignments
      console.log('*** saveUserAssignments: Remove empty assignments => saveUserAssignments: after saving: schAssignments', role.schRoleAssignments);
      const emptyAssignments = role.schRoleAssignments.filter(a => a.active === 1 && (!a.schRoleAssignUsers || a.schRoleAssignUsers.length === 0));
      for (const ea of emptyAssignments) {
        ea.active = 0;
        await this.schedRoleAssignmentsService.updateSchedRoleAssignment(rosSeq, schSeq, ea.sraSeq, ea).toPromise();
      }
      
      console.log('*** look for sru', result.sru);
      console.log('*** look for role1', role);
      console.log('*** look for role2', result.role);
      console.log('this.result', this.result);
      if(result.role === (undefined || null)) {
          result.role = role;
      }

      if(this.result.sru !== (undefined || null)) {
        result.sru = this.result.sru;
        result.role = this.result.role;
      }

      result.dismiss = true;
      result.isSaved = true;
      return Promise.resolve(result);

    } catch (err) {
      throw err;
    }
  }

  // This should not be called independently of the saveUserAssignments()
  private async saveRootAssignment(
    rosSeq: number, // Roster SEQ
    schSeq: number, // Schedule SEQ
    roleGroup: RoleGroup, // Role Group object
    role: Role, // Role object
    adUser: AdUser, // AD User
    roleAssignments: RoleAssignment[], // List of role assignments
    currentView: string, // Sets Current View state
    shiftEndDate: string,
    //dutyComments: DutyComments[],
    comment: string, // Comment to save with this assignment
    startDateTime: string, // Calculated Start DateTime
    endDateTime: string, // Calculated End DateTime,
    inactiveSru: boolean,
    orientation: number
  ): Promise<boolean> {
    try {

       this.result = {
        dismiss: false,
        isSaved: false,
        sru: null,
        schedAssignment: null,
        schedAssignments: null,
        role: null
      };

      console.log('comment in service', comment);
      
      console.log('saveRootAssignment: ====== Saving root role', adUser);
      // Add as root role

      // Remove user's previous assignments
      console.log('saveRootAssignment: create root assignment: roleAssignments', role.schRoleAssignments);
      // TESTING 123
      // for (const asi of role.schRoleAssignments) {
        for (const usr of role.schRoleAssignUsers) {
          if (usr.userId.toLowerCase() === adUser.userId.toLowerCase()) {
            usr.active = 0;
            await this.updateSchAssignmentUser(rosSeq, schSeq, usr.sruSeq, usr).toPromise();
          }
        }
      // }

      console.log('saveRootAssignment: **** role.schRoleAssignUser root =>', role.schRoleAssignUsers);
      currentView = "searching";
      // const startDateTime = ra.sruStartDateTime ? ra.sruStartDateTime :
      //   moment(schDate + " " + shift.startTime, "MM/DD/YYYY HH:mm")
      //     .format("MM/DD/YYYY hh:mm A");
      // const endDateTime = ra.sruEndDateTime ? ra.sruEndDateTime :
      //   moment(schDate + " " + shift.endTime, "MM/DD/YYYY HH:mm")
      //     .format("MM/DD/YYYY hh:mm A");

      console.log("saveRootAssignment: **** selected Employee", adUser);
      const existingSru = role.schRoleAssignUsers.find((sru) => sru.userId.toLowerCase() === adUser.userId.toLowerCase() && sru.active === 1);
      console.log("saveRootAssignment: **** root: existing sru", existingSru, adUser);
      // Active and existing
      if (!inactiveSru && !existingSru) {
        const newSchedAssignedUser: SchRoleAssignmentUser = {
          schSeq: schSeq,
          rgpSeq: roleGroup.rgpSeq,
          rolSeq: role.rolSeq,
          rosSeq,
          rasSeq: 0,
          sraSeq: 0,
          shfSeq: roleGroup.shfSeq,
          startDateTime,
          endDateTime,
          scheduledStart: startDateTime,
          scheduledEnd: endDateTime,
          name: adUser.displayName,
          active: 1,
          userId: adUser.userId,
          shiftEndDate,
          comment,
          orientation
          //dutyComments,
        };
        const res = await this.createSchAssignmentUser(
          rosSeq,
          schSeq,
          newSchedAssignedUser
        ).toPromise();
        console.log("saveRootAssignment: created new sched Assign User", res);
        newSchedAssignedUser.sruSeq = res.sruSeq;
        newSchedAssignedUser.adUser = adUser;
        console.log("saveRootAssignment: new adUser", newSchedAssignedUser.adUser);
        role.schRoleAssignUsers.push(newSchedAssignedUser);
        this.result.sru = newSchedAssignedUser;
        this.result.role = role;
        console.log('this.result.sru', this.result.sru);
        console.log('this.result.role', this.result.role);
      } 
      // Update existing root assignment
      else if (inactiveSru && existingSru) {
        existingSru.name = adUser.displayName;
        existingSru.startDateTime = startDateTime;
        existingSru.endDateTime = endDateTime;
        existingSru.comment = comment;
        existingSru.orientation = orientation;
        existingSru.shiftEndDate = shiftEndDate;
        //existingSru.dutyComments = dutyComments;
        existingSru.active = inactiveSru ? 0 : 1;
        console.log('saveRootAssignment: Updating root assignment', existingSru);
        await this.updateSchAssignmentUser(rosSeq, schSeq, existingSru.sruSeq, existingSru).toPromise();
        this.result.sru = existingSru;
        this.result.role = role;
        console.log('this.result.sru', this.result.sru);
        console.log('this.result.role', this.result.role);
      } else {
        console.log('saveRootAssignment: Do nothing');
      }
      return Promise.resolve(true);
    } catch (err) {
      throw err;
    }

  }
}
