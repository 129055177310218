import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { Roster } from "src/app/models/roster";
import { SelectOption } from "src/app/models/_core/select-option";
import { RosterService } from "src/app/services/roster/roster.service";
import { AuthService } from "src/app/services/_core/auth/auth.service";
import { BhAnalyticsService } from "src/app/services/_core/bhanalytics/bhanalytics.service";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";

@Component({
  selector: "app-add-roster",
  templateUrl: "./add-roster.page.html",
  styleUrls: ["./add-roster.page.scss"],
})
export class AddRosterPage implements OnInit {
  roster: Roster = {
    name: "BMC ED Roster",
  };
  // Define form
  form1: FormGroup = this.formBuilder.group({
    nameText: [null, Validators.required],
  });

  // Define validation messages
  validationMessages = {
    nameText: [{ type: "required", message: "Name is required." }],
  };
  // Submission State
  submitAttempted = false;

  rosterObject: Roster = {};
  nowTimestamp = moment().format("M/D/YYYY");
  currentRosterSubject = new BehaviorSubject<Roster>(null);
  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private notifications: NotificationsService,
    private rosterService: RosterService,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  ionViewDidEnter() {
    this.loadForm();
  }

  loadForm() {
    if (this.roster) {
    } else {
      this.notifications.handleError(
        "No person object found.",
        "form: loadForm"
      );
    }
  }

  dismiss() {
    this.analytics.clickEvent("feedback: dismiss", "");
    this.modalCtrl.dismiss();
  }

  async saveandAdd() {
    if (this.form1.valid) {
      this.roster.name = this.form1.controls.nameText.value;
      console.log("added roster", this.roster.name);
      const authUser = this.authService.getAuthUser();
      this.notifications.startLoading();
      const newRoster: Roster = {
        active: 1,
        name: this.roster.name,
        //addBy: authUser.userId,
        //updateBy: authUser.userId,
      };
      const res = await this.rosterService.createRoster(newRoster).toPromise();
      console.log("created new roster", res);
      newRoster.rosSeq = res.rosSeq;
      this.notifications.stopLoading();
      this.notifications.showAlert(
        "Roster Added",
        "A new roster has been successfully added.",
        "success"
      );
      this.form1.reset();
    } else {
      console.log("Form invalid", this.form1);
    }
  }

  async saveAndExit() {
    if (this.form1.valid) {
      this.roster.name = this.form1.controls.nameText.value;
      console.log("added roster", this.roster.name);
      const authUser = this.authService.getAuthUser();
      this.notifications.startLoading();
      const newRoster: Roster = {
        active: 1,
        name: this.roster.name,
        //addBy: authUser.userId,
        //updateBy: authUser.userId,
      };
      const res = await this.rosterService.createRoster(newRoster).toPromise();
      console.log("created new roster", res);
      newRoster.rosSeq = res.rosSeq;
      this.notifications.stopLoading();
      this.notifications.showAlert(
        "Roster Added",
        "A new roster has been successfully added.",
        "success"
      );
      this.modalCtrl.dismiss({ isSaved: true });
    } else {
      console.log("Form invalid", this.form1);
    }
  }

  cancel() {
    this.analytics.clickEvent("feedback: cancel", "");
    this.modalCtrl.dismiss();
  }

  setRosterSubject(roster: Roster) {
    this.currentRosterSubject.next(roster);
  }

  getRosterSubject(): Roster {
    return this.currentRosterSubject.getValue();
  }
}
