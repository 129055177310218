import { BhAnalyticsService } from "src/app/services/_core/bhanalytics/bhanalytics.service";
import { ModalController, NavController } from "@ionic/angular";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { User } from "src/app/models/user";
import { SelectOption } from "src/app/models/_core/select-option";
import { UsersService } from "src/app/services/users/users.service";
import { AuthService } from "src/app/services/_core/auth/auth.service";
import { HelperUtilitiesService } from "src/app/services/_core/helper-utilities/helper-utilities.service";
import { NotificationsService } from "src/app/services/_core/notifications/notifications.service";

@Component({
  selector: "app-manage-users-edit",
  templateUrl: "./manage-users-edit.page.html",
  styleUrls: ["./manage-users-edit.page.scss"],
})
export class ManageUsersEditPage implements OnInit, OnDestroy {
  //@Input() initUser: User;
  @Input() editMode: "new" | "edit" = "new";
  @Input() user: User;
  @Input() users: User[] = [];

  form1: FormGroup = this.formBuilder.group({
    userId: [, Validators.required],
    firstName: [, Validators.required],
    lastName: [, Validators.required],
    active: [1, Validators.required],
    role: ["USER", Validators.required],
  });
  validationMessages = {
    userId: [{ type: "required", message: "Field is required." }],
    firstName: [{ type: "required", message: "Field is required." }],
    lastName: [{ type: "required", message: "Field is required." }],
    active: [{ type: "required", message: "Field is required." }],
    role: [{ type: "required", message: "Field is required." }],
  };
  submitAttempted = false;
  isLoaded = false;
  isSaved = false;
  //user: User = null;
  lastLogon = null;
  authUser: User = null;
  permitOptions: SelectOption[] = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];
  roleOptions: SelectOption[] = [
    {
      label: "System Admin",
      value: "SYS_ADMIN",
    },
    {
      label: "Admin",
      value: "ADMIN",
    },
    {
      label: "User",
      value: "USER",
    },
  ];
  subscriptions: Subscription[] = [];
  userIdValid: "not-checked" | "invalid" | "valid" = "not-checked";
  userIdValidTimer = null;
  checkTimer = null;
  checkStatus: "not-checked" | "checking" | "new" | "exists" = "not-checked";
  formReady = false;

  constructor(
    private notifications: NotificationsService,
    private navCtrl: NavController,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private usersService: UsersService,
    private helpers: HelperUtilitiesService,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    console.log("mode type", this.editMode);

    this.authUser = this.authService.getAuthUser();
    if (this.editMode === "edit" && this.authUser.userId) {
      this.loadUser();
    } else {
      //this.user = this.authUser;
      this.form1.controls.userId.setValue("");
      this.form1.controls.firstName.setValue("");
      this.form1.controls.lastName.setValue("");
      this.form1.controls.active.setValue(1);
      this.form1.controls.role.setValue("USER");
      this.subscribeToFormFields();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
      sub = null;
    });
  }

  subscribeToFormFields() {
    if (this.subscriptions.length === 0) {
      // Subscribe to check name
      if (this.editMode === "new") {
        this.subscriptions.push(
          this.form1.controls.userId.valueChanges.subscribe((val) => {
            if (this.userIdValidTimer) {
              clearTimeout(this.userIdValidTimer);
              this.userIdValidTimer = null;
            }

            this.userIdValidTimer = setTimeout(() => {
              this.checkForExistingUser(val);
            }, 300);
          })
        );
      }
    }
  }

  async loadUser() {
    this.notifications.startLoading();
    try {
      //const res = await this.usersService.getById(this.authUser.userId).toPromise();
      //this.user = Array.isArray(res.users) ? res.users[0] : null;
      if (this.user && this.user.userId) {
        const firstName = this.helpers.getFirstName(this.user.fullName);
        const lastName = this.helpers.getLastName(this.user.fullName);
        this.form1.controls.userId.setValue(this.user.userId);
        this.form1.controls.firstName.setValue(firstName);
        this.form1.controls.lastName.setValue(lastName);
        //this.form1.controls.permit.setValue(this.user.permit);
        this.form1.controls.role.setValue(this.user.role);
        //this.lastLogon = this.user.lastLogonDate ? this.user.lastLogonDate : '(Never)';
        this.userIdValid = "valid";
      } else {
        this.notifications.showError("User did not load.");
        this.dismiss();
      }
      this.notifications.stopLoading();
    } catch (err) {
      this.notifications.stopLoading();
      this.notifications.handleError(err, "manage-users: loadUsers");
    }
  }

  async checkForExistingUser(userId: string) {
    if (this.checkTimer) {
      clearTimeout(this.checkTimer);
      this.checkTimer = null;
    }
    if (userId && !this.checkTimer) {
      this.checkTimer = setTimeout(async () => {
        this.formReady = false;
        this.checkStatus = "checking";
        try {
          const foundUser = this.users.find(user => user.userId.toLowerCase() === userId.toLowerCase());
          //const res = await firstValueFrom(this.userService.getById(userId));
          console.log('Loading user', foundUser);
          if (foundUser && foundUser.userId !== '' && this.checkString(foundUser.userId)) {
            this.checkStatus = 'exists';
            this.formReady = false;
          }
          else if (this.checkString(userId)){
            this.checkStatus = 'new';
            this.formReady = true;
          }
        } catch (err) {
          // this.modalCtrl.dismiss();
          this.notifications.handleError(
            err,
            "manage-user-editor.checkForExistingUser"
          );
        }
      }, 500);
    } else {
      this.checkStatus = "not-checked";
    }
  }

  checkString(input: string): boolean {
    const regexPattern = /^[A-Za-z]{2}\d{2}.{3,}$/;
    return regexPattern.test(input);
  }

  async checkUser(userId) {
    this.userIdValid = "not-checked";
    if (this.editMode === "new" && userId) {
      try {
        const res = await this.usersService.getById(userId).toPromise();
        const user = Array.isArray(res.users) ? res.users[0] : null;
        if (user && user.userId) {
          this.userIdValid = "invalid";
        } else {
          this.userIdValid = "valid";
        }
      } catch (err) {
        this.notifications.handleError(err, "manage-users: loadUsers");
      }
    }
  }

  async save() {
    const reload = true;
    this.analytics.clickEvent("manage-users-edit: save", this.user.userId);
    this.submitAttempted = true;
    // if (!this.formReady) {
    //   this.notifications.showAlert(
    //     "Unable to Save",
    //     "Please enter a valid unqiue User ID."
    //   );
    //   return;
    // }
    if (this.form1.valid) {
      this.user.userId = this.form1.controls.userId.value;
      this.user.fullName =
        this.form1.controls.lastName.value +
        ", " +
        this.form1.controls.firstName.value;
      this.user.active = this.form1.controls.active.value;
      this.user.role = this.form1.controls.role.value;
      this.user.userId = this.user.userId.toLowerCase();
      this.user.fullName = this.convertTitleCase(this.user.fullName.toLowerCase());

      
      //this.user.authType = 'C';

      try {
        const res =
          this.editMode === "new"
            ? await this.usersService.createUser(this.user).toPromise()
            : await this.usersService.updateUser(this.user).toPromise();
        this.isSaved = true;
        this.notifications.showAlert(
          "User Saved",
          "Your changes have been successfully saved."
        );
        this.modalCtrl.dismiss({ reload });
      } catch (err) {
        this.notifications.handleError(err, "manage-users-edit: save");
      }
    } else {
      console.log("Form not valid", this.form1, this.userIdValid);
    }
  }


  convertTitleCase(value: string): string {
    if (!value) return value;
    
    let words = value.split(' ');

    // Capitalize the first letter of each word
    words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together
    return words.join(' ');
  }

  dismiss(reload = false) {
    this.analytics.clickEvent("manage-users-edit: dismiss", "");
    this.modalCtrl.dismiss({ reload });
  }

  // async impersonate() {
  //   if (this.authUser.userId !== this.user.userId) {
  //     this.notifications.startLoading();
  //     try {
  //       const userImpersonation = await this.authService.impersonateUser(this.user.userId).toPromise();
  //       this.navCtrl.navigateRoot('/tabs/home');
  //       this.notifications.showToast('You are now impersonating: ' + this.user.fullName);
  //       this.dismiss();
  //       this.notifications.stopLoading();
  //     } catch (err) {
  //       this.notifications.stopLoading();
  //       this.notifications.handleError(err, 'manage-users-edit: impersonate');
  //     }
  //   } else {
  //     this.notifications.showAlert('Impersonation Error', 'You cannot impersonate yourself.', 'warning');
  //   }
  // }
}
