import { Pipe, PipeTransform } from '@angular/core';
import { HelperUtilitiesService } from '../services/_core/helper-utilities/helper-utilities.service';
import * as moment from 'moment';

/**
 * ID: bh-format-pipe
 * Name: Format Date Pipe
 * Type: Pipe
 * Description: Formats date string using helper utilities' formatDate
 * Implementation:
 *    1.) Import pipes.module.ts (PipesModule) into page or component's module.ts
 *    2.) In HTML template, display desired variable with pipe reference:  {{ eventDateIso | formatDate: 'YYYY-MM-DD HH:mm:ss' }}
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2022-12-15 - JK - v1: Initial dev
 */

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  constructor(
    // public helpers: HelperUtilitiesService
) { }

transform(value: string): string {
    return value.length > 0 ? value.slice(0, value.indexOf(',')) : value;
   }

}