import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EmployeeBoardGuardService } from './services/employee-board-guard.service';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    pathMatch: 'full',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'help-forgot-pwd',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () =>
      import('./pages/_core/help-forgot-pwd/help-forgot-pwd.module').then(m => m.HelpForgotPwdPageModule)
  },
  {
    path: 'share',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/share/share.module').then(m => m.SharePageModule)
  },
  {
    path: 'help',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'help-no-pin',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help-no-pin/help-no-pin.module').then(m => m.HelpNoPinPageModule)
  },
  {
    path: 'errors',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/errors/errors.module').then(m => m.ErrorsPageModule)
  },
  {
    path: 'errors-detail',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/errors-detail/errors-detail.module').then(m => m.ErrorsDetailPageModule)
  },
  {
    path: 'check-for-updates',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'Check for Updates', roles: [] },
    loadChildren: () => import('./pages/_core/check-for-updates/check-for-updates.module').then(m => m.CheckForUpdatesPageModule)
  },
  {
    path: 'about',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'my-account',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'My Account', roles: ['USER', 'ADMIN', 'SYS_ADMIN'] },
    loadChildren: () => import('./pages/_core/my-account/my-account.module').then(m => m.MyAccountPageModule)
  },
  {
    path: 'bh-input-text-editor',
    loadChildren: () => import('./components/_core/bh-input-text-editor/bh-input-text-editor.module')
      .then(m => m.BhInputTextEditorPageModule)
  },
  {
    path: 'feedback',
    data: { pageName: 'Feedback', roles: [] },
    loadChildren: () => import('./pages/_core/feedback/feedback.module').then(m => m.FeedbackPageModule)
  },
  {
    path: 'employee-board/:rosSeq',
    canActivate: [BhAnalyticsRouteGuardService, EmployeeBoardGuardService],
    data: { pageName: 'Employee Board', roles: [] },
    loadChildren: () => import('./pages/employee-board/employee-board.module').then( m => m.EmployeeBoardPageModule),
  },
  {
    path: 'employee-board/:rosSeq/:hide',
    canActivate: [BhAnalyticsRouteGuardService, EmployeeBoardGuardService],
    data: { pageName: 'Employee Board', roles: [] },
    loadChildren: () => import('./pages/employee-board/employee-board.module').then( m => m.EmployeeBoardPageModule),
  },
  // {
  //   path: 'print',
  //   loadChildren: () => import('./pages/print/print.module').then( m => m.PrintPageModule)
  // },
  {
    path: 'print/:rosSeq',
    canActivate: [BhAnalyticsRouteGuardService, EmployeeBoardGuardService],
    data: { pageName: 'Print', roles: [] },
    loadChildren: () => import('./pages/print/print.module').then( m => m.PrintPageModule)
  },
  {
    path: 'edit-individual-permission',
    loadChildren: () => import('./pages/edit-individual-permission/edit-individual-permission.module').then( m => m.EditIndividualPermissionPageModule)
  },
  {
    path: 'copy-day-modal',
    loadChildren: () => import('./pages/copy-day-modal/copy-day-modal.module').then( m => m.CopyDayModalPageModule)
  },
  {
    path: 'add-role-rosterpage',
    loadChildren: () => import('./pages/add-role-rosterpage/add-role-rosterpage.module').then( m => m.AddRoleRosterpagePageModule)
  },
  {
    path: 'add-role-group-rosterpage',
    loadChildren: () => import('./pages/add-role-group-rosterpage/add-role-group-rosterpage.module').then( m => m.AddRoleGroupRosterpagePageModule)
  },
  {
    path: 'edit-role-group-rosterpage',
    loadChildren: () => import('./pages/edit-role-group-rosterpage/edit-role-group-rosterpage.module').then( m => m.EditRoleGroupRosterpagePageModule)
  },
  {
    path: 'edit-role-rosterpage',
    loadChildren: () => import('./pages/edit-role-rosterpage/edit-role-rosterpage.module').then( m => m.EditRoleRosterpagePageModule)
  },
  {
    path: 'copy-shift',
    loadChildren: () => import('./pages/copy-shift/copy-shift.module').then( m => m.CopyShiftPageModule)
  },
  {
    path: 'role-group-roster/:publicToken',
    loadChildren: () => import('./pages/role-group-roster/role-group-roster.module').then( m => m.RoleGroupRosterPageModule)
  },
  {
    path: 'roster-sched-stale-modal',
    loadChildren: () => import('./pages/roster-sched-stale-modal/roster-sched-stale-modal.module').then( m => m.RosterSchedStaleModalPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'manage-duty',
    loadChildren: () => import('./pages/manage-duty/manage-duty.module').then( m => m.ManageDutyPageModule)
  },
  {
    path: 'add-edit-duty-comment',
    loadChildren: () => import('./pages/add-edit-duty-comment/add-edit-duty-comment.module').then( m => m.AddEditDutyCommentPageModule)
  },
  {
    path: 'roster-reports',
    loadChildren: () => import('./pages/roster-reports/roster-reports.module').then( m => m.RosterReportsPageModule)
  },
  {
    path: 'roster-reports-edit',
    loadChildren: () => import('./pages/roster-reports-edit/roster-reports-edit.module').then( m => m.RosterReportsEditPageModule)
  },
  {
    path: 'roster-reports-run',
    loadChildren: () => import('./pages/roster-reports-run/roster-reports-run.module').then( m => m.RosterReportsRunPageModule)
  },
  {
    path: 'call-out',
    loadChildren: () => import('./pages/call-out/call-out.module').then( m => m.CallOutPageModule)
  },
  {
    path: 'call-out-add-edit',
    loadChildren: () => import('./pages/call-out-add-edit/call-out-add-edit.module').then( m => m.CallOutAddEditPageModule)
  },
  {
    path: 'roster-call-out-conflict-modal',
    loadChildren: () => import('./pages/roster-call-out-conflict-modal/roster-call-out-conflict-modal.module').then( m => m.RosterCallOutConflictModalPageModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import('./pages/analytics/analytics.module').then( m => m.AnalyticsPageModule)
  },
  {
    path: 'analytics-details',
    loadChildren: () => import('./pages/analytics-details/analytics-details.module').then( m => m.AnalyticsDetailsPageModule)
  },
  {
    path: 'ed-surge-dashboard',
    canActivate: [BhAnalyticsRouteGuardService, EmployeeBoardGuardService],
    data: { pageName: 'ED Surge DashBoard', roles: [] },
    loadChildren: () => import('./pages/ed-surge-dashboard/ed-surge-dashboard.module').then( m => m.EdSurgeDashboardPageModule)

  },
  {
    path: 'ed-surge-dashboard/:rosSeq',
    canActivate: [BhAnalyticsRouteGuardService, EmployeeBoardGuardService],
    data: { pageName: 'ED Surge DashBoard', roles: [] },
    loadChildren: () => import('./pages/ed-surge-dashboard/ed-surge-dashboard.module').then( m => m.EdSurgeDashboardPageModule)
  },
  // {
  //   path: 'ed-surge-dashboard/:rosSeq/:sk',
  //   canActivate: [BhAnalyticsRouteGuardService, EmployeeBoardGuardService],
  //   data: { pageName: 'ED Surge DashBoard', roles: [] },
  //   loadChildren: () => import('./pages/ed-surge-dashboard/ed-surge-dashboard.module').then( m => m.EdSurgeDashboardPageModule)
  // },
  {
    path: 'ed-surge-dashboard/:publicToken',
    canActivate: [BhAnalyticsRouteGuardService, EmployeeBoardGuardService],
    data: { pageName: 'ED Surge DashBoard', roles: [] },
    loadChildren: () => import('./pages/ed-surge-dashboard/ed-surge-dashboard.module').then( m => m.EdSurgeDashboardPageModule)
  },
  // {
  //   path: 'ed-surge-dashboard/:publicToken/:showDetails',
  //   canActivate: [BhAnalyticsRouteGuardService, EmployeeBoardGuardService],
  //   data: { pageName: 'ED Surge DashBoard', roles: [] },
  //   loadChildren: () => import('./pages/ed-surge-dashboard/ed-surge-dashboard.module').then( m => m.EdSurgeDashboardPageModule)
  // },
  {
    path: 'ed-surge-metrics-focus-modal',
    loadChildren: () => import('./pages/ed-surge-metrics-focus-modal/ed-surge-metrics-focus-modal.module').then( m => m.EdSurgeMetricsFocusModalPageModule)
  },
  {
    path: 'manage-code-capacity',
    loadChildren: () => import('./pages/manage-code-capacity/manage-code-capacity.module').then( m => m.ManageCodeCapacityPageModule)
  },
  {
    path: 'manage-charge-reports',
    loadChildren: () => import('./pages/manage-form/manage-form.module').then( m => m.ManageFormPageModule)
  },
  {
    path: 'form-entry',
    loadChildren: () => import('./pages/form-entry/form-entry.module').then( m => m.FormEntryPageModule)
  },
  {
    path: 'manage-form-question-answers',
    loadChildren: () => import('./pages/manage-form-question-answers/manage-form-question-answers.module').then( m => m.ManageFormQuestionAnswersPageModule)
  },
  {
    path: 'manage-charge-report-question-answers-add',
    loadChildren: () => import('./pages/manage-form-question-answers-add/manage-form-question-answers-add.module').then( m => m.ManageFormQuestionAnswersAddPageModule)
  },
  {
    path: 'department-rosters/:department/:publicToken',
    loadChildren: () => import('./pages/department-rosters/department-rosters.module').then( m => m.DepartmentRostersPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
