import { BhSpinnerComponentModule } from './../../components/_core/bh-spinner/bh-spinner.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PopoverViewEmployeePageRoutingModule } from './popover-view-employee-routing.module';

import { PopoverViewEmployeePage } from './popover-view-employee.page';
import { BhUserBadgeComponentModule } from 'src/app/components/_core/bh-user-badge/bh-user-badge.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PopoverViewEmployeePageRoutingModule,
    BhUserBadgeComponentModule,
    BhSpinnerComponentModule
  ],
  declarations: [PopoverViewEmployeePage]
})
export class PopoverViewEmployeePageModule {}
