import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/models/role';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { SchRoleAssignmentUser } from 'src/app/models/sched-role-assign-user';
import { SchRoleAssignment } from 'src/app/models/sched-role-assignment';
import { Schedule } from 'src/app/models/schedule';
import { Shift } from 'src/app/models/shift';
import { BhGridColumnDefinition } from 'src/app/models/_core/bh-grid-column-definition';
import { RosterService } from 'src/app/services/roster/roster.service';
import { ScheduleService } from 'src/app/services/schedule/schedule.service';
import { ShiftsService } from 'src/app/services/shifts/shifts.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { StorageService } from 'src/app/services/_core/storage/storage.service';
import { ManageShiftsEditPage } from '../manage-shifts-edit/manage-shifts-edit.page';

@Component({
  selector: 'app-manage-shifts',
  templateUrl: './manage-shifts.page.html',
  styleUrls: ['./manage-shifts.page.scss'],
})
export class ManageShiftsPage implements OnInit, OnDestroy {
  @Input() roster: Roster;
  @Input() rosterDate: string;

  columnDefinitions: BhGridColumnDefinition[] = [
    {
      fieldName: 'name', columnLabel: 'Shift', ionIcon: 'time-outline', ionIconColor: 'primary', showIconArg: '',
      showIconOnly: false, alwaysShowIcon: true, showColumn: true
    },
    {
      fieldName: 'startTime', columnLabel: 'Shift Start', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'endTime', columnLabel: 'Shift End', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'postDate', columnLabel: 'Effective Period', ionIcon: '', ionIconColor: null, showIconArg: '',
      showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'active', columnLabel: 'Active', ionIcon: '', ionIconColor: null, showIconArg: '',
      showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
    {
      fieldName: 'shfSeq', columnLabel: 'ID', ionIcon: '', ionIconColor: null,
      showIconArg: '', showIconOnly: false, alwaysShowIcon: false, showColumn: true
    },
  ];

  subscriptions: Subscription[] = [];
  selectedShiftSubject: Shift;
  selectRoster: Roster;
  selectedRoleGroup: RoleGroup;
  shifts: Shift[];
  schedule: Schedule;
  roleGroups: RoleGroup[];
  roles: Role[];
  schRoleAssignments: SchRoleAssignment[];
  schRoleAssignUsers: SchRoleAssignmentUser[];
  searchTerm = '';
  isFiltering = false;
  initShifts: Shift[];
  hasBeenChanged = false;
  constructor(
    private shiftService: ShiftsService,
    private rosterService: RosterService,
    private scheduleService: ScheduleService,
    public notificationsService: NotificationsService,
    private menu: MenuController,
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private storageService: StorageService,
  ) { }

  ngOnInit() {
    this.loadTableData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
      sub = null;
    });
  }

  async loadTableData() {
    await this.getAllShifts(this.roster.rosSeq);
    //this.getScheduleByDate();
    // if (this.subscriptions.length === 0) {
    //   this.subscriptions = [
    //     this.rosterService.rosterSubject.subscribe(val => {
    //       this.selectRoster = val;
    //       console.log('rosSeq', this.selectRoster.rosSeq);
    //     })
    //   ];
    // }

  }

  // async getScheduleByDate() {
  //   this.rosterDate = moment(this.rosterDate).format('MM/DD/yyyy');
  //   try {
  //     const res = await this.scheduleService.getScheduleByDate(this.rosSeq, this.rosterDate, 1).toPromise();

  //     if (res) {
  //           this.schedule = res.schedules[res.schedules.length - 1];
  //           this.shifts = this.schedule.shifts;
  //     }
  //     console.log('response schedule', this.schedule);

  //     console.log('response shifts', this.shifts);
  //   } catch (err) {
  //       this.notificationService.handleError(err, 'getScheduleByDate()')
  //   }
  // }

  async getAllShifts(rosSeq): Promise<boolean> {
    try {
      let res = await this.shiftService.getAllShifts(rosSeq, 0).toPromise();
      console.log('response', res);
      if (res) {
        this.shifts = Object.values(res.shifts);
        this.shifts.sort((a, b) => {
          if (a.shfSeq === b.shfSeq) {
            return moment(a.startTime, "MM/DD/YYYY HH:mm:ss").valueOf() - moment(b.startTime, "MM/DD/YYYY HH:mm:ss").valueOf();
          }
          return a.shfSeq > b.shfSeq ? -1 : a.shfSeq === b.shfSeq ? 0 : 1;
        });
        this.initShifts = this.shifts;
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notificationsService.handleError(err, 'getAllShifts()');
    }
  }

  async addShift() {
    this.analytics.clickEvent("open-add-shift", "clicked");
    const modal = await this.modalCtrl.create({
      component: ManageShiftsEditPage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        roster: this.roster,
        shifts: this.shifts,
        componentLabel: 'Shifts',
        editMode: 'new'
      }
    });
    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.isSaved) {
        this.getAllShifts(this.roster.rosSeq);
        this.hasBeenChanged = true;
      }
    });
    modal.present();
  }

  async editShift(shift: Shift) {
    this.analytics.clickEvent("edit-shift", "clicked");
    const modal = await this.modalCtrl.create({
      component: ManageShiftsEditPage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        roster: this.roster,
        shift,
        componentLabel: "Shifts",
        editMode: 'edit'
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.isSaved) {
        this.getAllShifts(this.roster.rosSeq);
        this.hasBeenChanged = true;
      }
    });
    modal.present();
  }

  dismiss() {
    this.analytics.clickEvent('feedback: dismiss', '');
    if (this.hasBeenChanged !== false) {
      this.modalCtrl.dismiss({ hasBeenChanged: this.hasBeenChanged });
    } else {
      this.modalCtrl.dismiss();
    }
  }

  filter(ev) {
    if (ev) {
      this.isFiltering = true;
      const term = ev.toLowerCase().trim();
      this.shifts = this.shifts.filter(shf =>
        shf.name.toLowerCase().indexOf(term) > -1 ||
        shf.shfSeq.toString().indexOf(term) > -1
      );
    } else {
      this.searchTerm = '';
      this.isFiltering = false;
      this.shifts = this.initShifts;
    }

  }

}
