import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Role } from 'src/app/models/role';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { Shift } from 'src/app/models/shift';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  env = environment;
  roster: Roster;
  shift: Shift;
  roleGroups: RoleGroup;
  roles: Role;
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  getAllRoles(rosSeq, shfSeq, rgpSeq) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts/${shfSeq}/role-groups/${rgpSeq}/roles`;
    return this.http.get(url).pipe(
      map((data: any) => {
        //this.roles = data;
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "role-service.getAllRoles(): " + url
        );
        return of(err);
      })
    );
  }

  getSingleRole(rosSeq, shfSeq, rgpSeq, rolSeq) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts/${shfSeq}/role-groups/${rgpSeq}/roles/${rolSeq}`
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          'role-service.getSingleRole(): ' + url
        );
        return of(err);
      })
    );
  }

  createRole(rosSeq, shfSeq, rgpSeq, role: Role) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts/${shfSeq}/role-groups/${rgpSeq}/roles`
    console.log("role service: role created", role);
    const body = role;
    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "role-service.createRole(): " + url
        );
        return of(err);
      })
    );
  }

  updateRole(rosSeq, shfSeq, rgpSeq, rolSeq, role: Role) {
    const url = `${environment.apiUrl}/rosters/${rosSeq}/shifts/${shfSeq}/role-groups/${rgpSeq}/roles/${rolSeq}`
    const body = role;
    return this.http.put(url,role).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          "role-service.updateRole(): " + url
        );
        return of(err);
      })
    );
  }


}
