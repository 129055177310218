import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from '@ionic/angular';
import { Room } from 'src/app/models/room';
import { Roster } from 'src/app/models/roster';
import { SelectOption } from 'src/app/models/_core/select-option';
import { RoomService } from 'src/app/services/room/room.service';
import { RosterService } from 'src/app/services/roster/roster.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';

@Component({
  selector: 'app-edit-room',
  templateUrl: './edit-room.page.html',
  styleUrls: ['./edit-room.page.scss'],
})
export class EditRoomPage implements OnInit {

  @Input() rolSeq: number;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  @Input() shfSeq: number;
  @Input() rgpSeq: number;
  @Input() room: Room;
  @Input() componentLabel: string;

  roster: Roster;

  setActive: SelectOption[] = [
    {
      label: 'Yes',
      value: 1
    },
    {
      label: 'No',
      value: 0
    }
  ];
  
 // Define form
 form1: FormGroup = this.formBuilder.group({
  roomName: [null, Validators.required],
  active: [null, Validators.required],
});

// Submission State
submitAttempted = false;
// Define validation messages

validationMessages = {
  roomName: [{ type: "required", message: "A room name is required." }],
  active: [{ type: "required", message: "A active condition is required." }],
};

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private roomService: RoomService,
    private rosterService: RosterService,
    private notificationService: NotificationsService,
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.loadForm();
  }

  async loadForm() {
    console.log('room.active', this.room);
    await this.getCurrentRoster();
    this.form1.controls.roomName.setValue(this.room.name);
    this.form1.controls.active.setValue(this.room.active);
  }

  async getCurrentRoster(): Promise<boolean> {
    try {
     const res = await this.rosterService.getRosterBySeq(this.rosSeq).toPromise();
   if(res) {   
      this.roster = res;
      console.log('current roster', this.roster);
   }
   return Promise.resolve(true);
 } catch (err) {
  this.notificationService.handleError(err, 'getCurrentRoster()')
}
}

  async updateExistingRoom() {
    if (this.form1.valid) {
    try {
      this.room.name = this.form1.controls.roomName.value;
      this.room.active = this.form1.controls.active.value;
      const res = await this.roomService.updateRoom(this.rosSeq,this.room.romSeq,this.room).toPromise();
      console.log("updated room", res);
      this.modalCtrl.dismiss({ isUpdated: true });
    } catch (err) {
      this.notificationService.handleError(err, "updateExistingRoom()");
    }
  } else {
    console.log('Form invalid', this.form1);
    }
  }

  dismiss() {
    this.analytics.clickEvent('feedback: dismiss', '');
    this.modalCtrl.dismiss();
  }

  cancel() {
    this.analytics.clickEvent('feedback: cancel', '');
    this.modalCtrl.dismiss();
  }

}
