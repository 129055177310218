import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/models/role';
import { RoleGroup } from 'src/app/models/role-group';
import { Roster } from 'src/app/models/roster';
import { Shift } from 'src/app/models/shift';
import { SelectOption } from 'src/app/models/_core/select-option';
import { RoleGroupsService } from 'src/app/services/role-groups/role-groups.service';
import { RoleService } from 'src/app/services/role/role.service';
import { ShiftsService } from 'src/app/services/shifts/shifts.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.page.html',
  styleUrls: ['./add-role.page.scss'],
})
export class AddRolePage implements OnInit {

  @Input() rolSeq: number;
  @Input() rosSeq: number;
  @Input() schSeq: number;
  @Input() schedDate: string;
  @Input() componentLabel: string;
  @Input() roster: Roster;
  date: string;
  shifts: Shift[] = [];
  shift: Shift;
  roleGroups: RoleGroup[] = [];
  roleGroup: RoleGroup;
  shfSeq: number;
  rgpSeq: number;
  subscriptions: Subscription[] = [];

// roster: Roster = {
//     name: 'BMC Emergency Department'
// };

shiftObj: Shift = {
    name: '',
    startTime: '',
    endTime: '',
    postDate: '',
    stopDate: '',
    active: 1
};

roleGroupObj: RoleGroup = {
  active: 1
}

roleObj: Role = {
  name: '',
  rgpSeq: 1,
  active: 1
}

setStaffing: SelectOption[] = [
  {
    label: "Horizontal",
    value: "H",
  },
  {
    label: "Vertical",
    value: "V",
  },
  {
    label: "None",
    value: "N",
  },
];
  // Define form
  form1: FormGroup = this.formBuilder.group({
    shift: [null, Validators.required],
    roleGroup: [null, Validators.required],
    roleName: [null, Validators.required],
    active: [null, Validators.required],
    staffing: [null, Validators.required],
  });

  // Define validation messages
  validationMessages = {
    rosterName: [{ type: 'optional'}],
    roleName: [{ type: "required", message: "A role name is required." }],
    name: [{ type: "required", message: "A shift name is required." }],
    shift: [{ type: "required", message: "A shift name is required." }],
    staffing: [{ type: "required", message: "A choice must be made." }],
    roleGroup: [{ type: "required", message: "A Role Group selection is required." }],
    startTime: [{ type: "required", message: "Start Time is required." }],
    active: [{ type: 'optional'}]
  };
 // Submission State
 submitAttempted = false;

 // View only State
 viewOnly = true;

 // Options for Active Select
 roleGroupSelection: SelectOption[] = [];

shiftSelection: SelectOption[] = [
  
];

setActive: SelectOption[] = [
  {
    label: 'Yes',
    value: 1
  },
  {
    label: 'No',
    value: 0
  }
];

 nowTimestamp = moment().format('M/D/YYYY');
  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private roleService: RoleService,
    private roleGroupService: RoleGroupsService,
    private shiftService: ShiftsService
  ) { }

  ngOnInit() {
    
  }

  ionViewDidEnter() {
    this.loadForm();
  }

  loadForm() {
    this.form1.controls.active.setValue(1);
    //this.form1.controls.order.setValue(0);
    this.form1.controls.staffing.setValue('N');
      // Parse Date as Moment Object
      if (this.subscriptions.length === 0) {
        this.subscriptions = [
        this.form1.controls.shift.valueChanges.subscribe((val) => {
          if (val) {
            this.shfSeq = val;
            this.getRoleGroups(this.shfSeq);
          }
          this.roleGroups = [];
          this.roleGroupSelection = [];
        }),
        this.form1.controls.roleGroup.valueChanges.subscribe((val) => {
          if (val) {
            this.rgpSeq = val;
            console.log("rg value", this.rgpSeq);
          }
        })
        ]
      }
      this.loadCurrentShifts();
  }

  async loadCurrentShifts() {
    let currentMoment = moment();
    try {
      const res = await this.shiftService.getAllShifts(this.rosSeq, 1).toPromise();
       if(res) {   
           this.shifts = res.shifts.filter( shift => 
            {
            const postDateMoment = moment(shift.postDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss');
            const stopDateMoment = (shift.stopDate) ? moment(shift.stopDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss') : null;
            const startTimeMoment = moment( currentMoment.format('MM/DD/YYYY') + ' ' + shift.startTime + ':00', 'MM/DD/YYYY HH:mm:ss');
            const endTimeMoment = moment( currentMoment.format('MM/DD/YYYY') + ' ' + shift.endTime + ':59', 'MM/DD/YYYY HH:mm:ss');
            const isLive = postDateMoment <= currentMoment && (!stopDateMoment || stopDateMoment >= currentMoment);
            return isLive;
          });
          this.shifts.forEach( shift => {
            const startTime = moment(shift.startTime, 'hh:mm a').format('hh:mm a');
            const endTime = moment(shift.endTime,'hh:mm a').format('hh:mm a');
            const shiftStatus = this.getShiftStatus(shift);
            const shiftTime = startTime + ' - ' + endTime + shiftStatus;
            this.shiftSelection.push({label: shiftTime, value: shift.shfSeq});
            this.shiftSelection = this.shiftSelection.filter( ss => Object.keys(ss).length !== 0);
          });  
          console.log('current shifts', this.shifts);
       }
     } catch (err) {
      this.notificationService.handleError(err, 'loadShifts()')
  }
  }

  getShiftStatus(shift: Shift) {
    let currentMoment = moment();
    const postDateMoment = moment(shift.postDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss');
    const stopDateMoment = (shift.stopDate) ? moment(shift.stopDate + ' 00:00:00', 'MM/DD/YYYY HH:mm:ss') : null;
    const isLive = postDateMoment <= currentMoment && (!stopDateMoment || stopDateMoment >= currentMoment);
    if(!shift.active) {
        return ' (inactive)';
    } else if(!isLive) {
        return ' (expired)';
    } else {
      return ' (active)';
    }
  }

  async getRoleGroups(shfSeq: number): Promise<boolean> {
    try {
      let res = await this.roleGroupService
        .getAllRoleGroups(this.rosSeq, shfSeq)
        .toPromise();
      if (res) {
        this.roleGroups = res.roleGroups;
        this.roleGroups.forEach((rg) => {
          this.roleGroupSelection.push({ label: rg.name, value: rg.rgpSeq });
          this.roleGroupSelection = this.roleGroupSelection.filter(
            (rgs) => Object.keys(rgs).length !== 0
          );
        });
      }
      console.log("roleGroups", this.roleGroups);
      return Promise.resolve(true);
    } catch (err) {
      this.notificationService.handleError(err, "getRoleGroups()");
      throw err;
    }
  }

  dismiss() {
    this.analytics.clickEvent('feedback: dismiss', '');
    this.modalCtrl.dismiss();
  }

  async createNewRole() {
    if (this.form1.valid) {
    const roleNameFormValue = this.form1.controls.roleName.value;
    const activeFormValue = this.form1.controls.active.value;
    const staffingFormValue = this.form1.controls.staffing.value;
    console.log('role name', roleNameFormValue);
    try {
        this.notificationService.startLoading();
        const newRole: Role = {
          rgpSeq: this.rgpSeq,
          name: roleNameFormValue,
          active: activeFormValue,
          staffingType: staffingFormValue
        }
        const res = await this.roleService
          .createRole(this.rosSeq, this.shfSeq, this.rgpSeq, newRole)
          .toPromise();
        console.log("created new role", res);
        newRole.rolSeq = res.role;
        this.notificationService.stopLoading();
        this.notificationService.showAlert(
          "Role Added",
          "A new role has been successfully added.",
          "success"
        );
       this.modalCtrl.dismiss({isSaved: true});
    } catch (err) {
      this.notificationService.handleError(err, "createNewRole()");
    } 
  } else {
    console.log('Form invalid', this.form1);
  }
}

  cancel() {
    this.analytics.clickEvent('feedback: cancel', '');
    this.modalCtrl.dismiss();
  }


}