import { Pipe, PipeTransform } from "@angular/core";
import { HelperUtilitiesService } from "../services/_core/helper-utilities/helper-utilities.service";
import * as moment from "moment";
/**
 * ID: bh-format-pipe
 * Name: Format Date Pipe
 * Type: Pipe
 * Description: Formats date string using helper utilities' formatDate
 * Implementation:
 *    1.) Import pipes.module.ts (PipesModule) into page or component's module.ts
 *    2.) In HTML template, display desired variable with pipe reference:  {{ eventDateIso | formatDate: 'YYYY-MM-DD HH:mm:ss' }}
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-13 - MW - v1: Initial dev
 */

@Pipe({
  name: "formatDecimal",
})
export class FormatDecimalPipe implements PipeTransform {
  constructor() // public helpers: HelperUtilitiesService
  {}

  transform(value: number, precision: number = 0): number {
    const multiplier = Math.pow(10, precision);
    return Math.trunc(value * multiplier) / multiplier;
  }
}
