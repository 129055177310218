import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { UserDeviceService } from './services/_core/user-device/user-device.service';
import { Subscription } from 'rxjs';
import { VerlockerService } from 'src/app/services/_core/verlocker/verlocker.service';
import { BhAnalyticsService } from './services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Keyboard } from '@capacitor/keyboard';
import { PushService } from './services/_core/push/push.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  env = environment;
  updateInterval = null;
  displayingVerlocker = false;
  loadingSub: Subscription = null;
  isLoading = false;
  loadingMessage = '';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private verlockerService: VerlockerService,
    private pushService: PushService,
  ) {
    this.initializeApp();
    this.subscribeToLoader();
    this.checkVersion();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('mobile')) {
        this.statusBar.styleDefault();
        Keyboard.setAccessoryBarVisible({ isVisible: true});  
      }
      this.analytics.initAnalytics();
      // this.pushService.init();
    });
    if(this.env.preventSearchIndexing) {
      this.addNoIndexTag();
    }
  }

  subscribeToLoader() {
    this.loadingSub = this.notifications.isLoadingBehaviorSubject.subscribe(val => {
      this.isLoading = val.isLoading;
      this.loadingMessage = val.message;
    });
  }

  addNoIndexTag() {
    const metaTag: HTMLMetaElement = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex, nofollow';
    document.head.appendChild(metaTag);
  }

  checkVersion() {
    if (!this.updateInterval) {
      this.verlockerService.checkVersion().toPromise();
      this.updateInterval = setInterval(() => {
        if (!this.verlockerService.displayingVerlocker) {
          this.verlockerService.checkVersion().toPromise();
        } else {
        }
      }, 120000);
    }
  }

}
